import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  LabelList,
} from "recharts";

const IssueBarChart = ({ data }) => {
  // Function to rescale SubObs to fit between 1 and 10
  const scaleSubObs = (subObs) => {
    const min = Math.min(...data.map((item) => item.SubObs));
    const max = Math.max(...data.map((item) => item.SubObs));
    return ((subObs - min) / (max - min)) * (10 - 1) + 1; // Scale SubObs to fit within 1-10
  };

  // Rescale data to fit within 1-10 for visualization
  const scaledData = data.map((item) => ({
    ...item,
    SubObs: scaleSubObs(item.SubObs),
  }));

  // Calculate the maximum value of scaled SubObs to adjust Y-axis
  const maxSubObs = Math.max(...scaledData.map((item) => item.SubObs));

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={scaledData} // Use scaled data
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 35,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis domain={[0, maxSubObs + 1]} /> {/* Dynamically adjust Y-axis */}
        <Tooltip />
        <Legend />
        <Bar dataKey="SubObs" fill="#8884d8">
          <LabelList dataKey="SubObs" position="top" />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default IssueBarChart;
