import React, { useState, useEffect } from "react";
import "../userHome/userhome.css";
import "../userHome/userhomenew.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { fetchDataV2 } from "../../apiUtils";
import SuperAvatar from "../../SuperAvatar";



const Userhome = ({ tableSize, state }) => {
  const [APIData, setAPIData] = useState([]);
  const [APIAllData, setAPIAllData] = useState([]);
  const [auditNames, setAuditNames] = useState([]);
  const [issueNames, setIssueNames] = useState([]);


  const [errors, setErrors] = useState("");
  const userId = localStorage.getItem("user_id");
  const [isAssigned, setIsAssigned] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [auditPwaPopup, setauditPwaPopup] = useState(false);
  const [issuesPwaPopup, setissuesPwaPopup] = useState(false);
  const [tasksList, setTasksList] = useState(false);
  const [todotask, setTodoTask] = useState([])
  const [donetask, setDoneTask] = useState([])
  const [reviewtask, setReviewTask] = useState([])
  const avaUserName = localStorage.getItem("userName");


  useEffect(() => {
    if (location.state && location.state.data === "reload") {
      const reloadFlag = localStorage.getItem("reloadFlag");

      if (!reloadFlag) {
        localStorage.setItem("reloadFlag", "true");
        window.location.reload();
      } else {
        localStorage.removeItem("reloadFlag");
      }
    }
  }, [location.state]);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/`,
      setterFunction: setAuditNames,
      setErrors: setErrors,
    }
    fetchDataV2(parameter);
  }, []);

  useEffect(() => {
    const parameter = {
      url: `/api/issues/`,
      setterFunction: setIssueNames,
      setErrors: setErrors,
    }
    fetchDataV2(parameter);
  }, []);

  useEffect(() => {
    if (userId) {
      const parameter = {
        url: `/api/system/task/?assigned_to=${userId}&owner=${userId}`,
        setterFunction: setAPIData,
        setErrors: setErrors,
      }
      fetchDataV2(parameter);
    }
  }, [userId]);


  useEffect(() => {
    if (userId) {
      const parameter = {
        url: `/api/system/task/count/status/?status=in progress&user_id=${userId}`,
        setterFunction: setTodoTask,
        setErrors: setErrors,
      }
      fetchDataV2(parameter)
      const parameter1 = {
        url: `/api/system/task/count/status/?status=done&user_id=${userId}`,
        setterFunction: setDoneTask,
        setErrors: setErrors,
      }
      fetchDataV2(parameter1)
      const parameter2 = {
        url: `/api/system/task/count/status/?status=review&user_id=${userId}`,
        setterFunction: setReviewTask,
        setErrors: setErrors,
      }
      fetchDataV2(parameter2)
    }
  }, [userId])



  const storeQuesId = (questionnaire_id) => {
    navigate("/AuditUniverse/riskAssesment/answerQuestionnaire", {
      state: { questionnaire_id },
    });
  };

  const navigateToAuditPage = (auditId) => {
    const id = auditId;
    navigate(`/AuditExecution/${id}/`
    );
  }


  const handleToggle = () => {
    setIsAssigned((prevState) => !prevState);
  };

  const handleOpenAuditPopup = () => {
    setauditPwaPopup(true);
  }

  const handleCloseAuditPopup = () => {
    setauditPwaPopup(false);
  }

  const handleIssueOpen = () => {
    setissuesPwaPopup(true);
  }

  const handleIssueClose = () => {
    setissuesPwaPopup(false);
  }

  const handleTasksListsOpen = () => {
    setTasksList(true);
  }

  const handleTasksListClose = () => {
    setTasksList(false);
  }


  const handleAuditClick = (auditId) => {
    const id = auditId;
    navigate(`/AuditExecution/${id}/`);
  };

  const handleIssueClick = (auditId) => {
    const id = auditId;
    navigate(`/issuetracking/issues/${id}`);
  };


  const afSpinner = () => {
    if (!APIData) {
      return (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      );
    }

    return (
      <>
        <div className="todolistnother">
          <div className="block block1">
            <div className="auditstatuscon" style={{ width: tableSize ? '20vw' : '22vw' }}>
              <h1 className="h5_intersemibold" >Audits</h1>
              <div className="audit_list_con">
                {auditNames.map((audit) => (
                  <h5 className="h6_intersemibold" key={audit.id} onClick={() => handleAuditClick(audit.id)} style={{ cursor: 'pointer' }}>
                    {audit.name ? audit.name : ''}
                  </h5>
                ))}
              </div>
            </div>
          </div>
          <div className="column">
            <div className="row">
              <div className="block block2">
                <div className="issues">
                  <div className="issues_heading">
                    <h6 className="h5_intersemibold" >Issues Assigned</h6>
                  </div>
                  <div className="issues_con">
                    <div className="issue">
                      <h5 className="h6_intersemibold" >Drive-By Download Attack</h5>
                      <button>View</button>
                    </div>
                    <div className="issue">
                      <h5 className="h6_intersemibold" >Ransomware Attack</h5>
                      <button>View</button>
                    </div>
                    <div className="issue">
                      <h5 className="h6_intersemibold" >SQL Injection Attack</h5>
                      <button>View</button>
                    </div>
                    <div className="issue">
                      <h5 className="h6_intersemibold" >Drive-By Download Attack</h5>
                      <button>View</button>
                    </div>
                  </div>
                </div>
              </div>
              {/* Block 3 is now empty */}
              <div className="block block3">
                {/* Empty */}

                <div className="home_issues_by_rating">
                  <div className="home_ir_readings">
                    <div className="home_ir_reading">20</div>
                    <div className="home_ir_reading">10</div>
                    <div className="home_ir_reading">0</div>
                  </div>
                  <div className="home_rating_bars">
                    <div className="home_ir_label_bar">
                      <div className="home_irbar"></div>
                      <div className="home_ir_label">Blank</div>
                    </div>
                    <div className="home_ir_label_bar">
                      <div className="home_irbar"></div>
                      <div className="home_ir_label">High</div>
                    </div>
                    <div className="home_ir_label_bar">
                      <div className="home_irbar"></div>
                      <div className="home_ir_label">Medium</div>
                    </div>
                    <div className="home_ir_label_bar">
                      <div className="home_irbar"></div>
                      <div className="home_ir_label">Low</div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="block block4">
              <div className="mytask">

                <div className="mainTask">
                  <div className="taskList">
                    <div className="taskHead">
                      <h6>Tasks Owned by Me</h6>
                    </div>
                    <div className="scrollable-div">
                      {APIData.filter((item) => item.owner?.id === JSON.parse(userId)).map((item) => (
                        <div className="taskDiv" key={item.id}>
                          <div className="issue" onClick={() => storeQuesId(item?.data?.id)} style={{ cursor: 'pointer' }}>
                            {item.status === "Done" && "✅"}
                            {item.status !== "Done" && "🛑"}&nbsp;{item.title}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="mainTask">
                  <div className="taskList">
                    <div className="taskHead">
                      <h6>Tasks Assigned To Me</h6>
                    </div>
                    <div className="scrollable-div">
                      {APIData.filter((item) => item.assigned_to?.id === JSON.parse(userId)).map((item) => (
                        <div className="taskDiv" key={item.id}>
                          <div className="issue" onClick={() => storeQuesId(item?.data?.id)} style={{ cursor: 'pointer' }}>
                            {item.status === "Done" && "✅"}
                            {item.status !== "Done" && "🛑"}&nbsp;{item.title}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="home_container" style={{ width: tableSize ? "100%" : "100%" }}>

        <div className="bento_grid">
          {/* Block 1 */}
          <div className="bento_block bento_task taskcard">
            <h2 >Pending Tasks</h2>
            <h2 style={{ color: "orange" }}>{todotask.count}</h2>
          </div>

          <div className="bento_block bento_task taskcard">
            <h2 className="">Comments</h2>
            <h2 style={{ color: "#4d4dd0a1" }}>32</h2>
          </div>

          <div className="bento_block bento_task taskcard">
            <h2 className="">Completed Tasks</h2>
            <h2 style={{ color: "#17c247" }}>{donetask.count}</h2>
          </div>

          <div className="bento_block bento_task taskcard">
            <h2 className="">Pending Approvals</h2>
            <h2 style={{ color: "#ff0000b3" }}>{reviewtask.count}</h2>
          </div>



          {/* Block 5 (Wide) */}
          <div className="bento_block custom_heads tall_block">

            <h2>Audits</h2>

            {auditNames.map((audit) => (
              <h5 key={audit.id} onClick={() => handleAuditClick(audit.id)} style={{ cursor: 'pointer' }}>
                {audit.name}
              </h5>
            ))}

          </div>


          {/* Block 6 */}
          <div className="bento_block custom_heads task_block_ownme ">

            <h2>Tasks Owned by Me</h2>

            <div className="task_block_ownme_con">

              {APIData.filter((item) => item.owner?.id === JSON.parse(userId)).map((item) => (
                <div className="taskDiv" key={item.id}>

                  <div className="task_ownme" onClick={() => storeQuesId(item?.data?.id)} style={{ cursor: 'pointer' }}>
                    {item.status === "Done" && "✅"}
                    {item.status !== "Done" && "🛑"}&nbsp; <h6>{item.title}</h6>
                  </div>

                </div>
              ))}

            </div>

          </div>

          {/* Block 7 */}
          <div className="bento_block custom_heads task_block_ownme ">

            <h2>Tasks Assigned to Me</h2>

            <div className="task_block_ownme_con">

              {APIData.filter((item) => item.assigned_to?.id === JSON.parse(userId)).map((item) => (
                <div className="taskDiv" key={item.id}>
                  <div className="task_ownme" onClick={() => storeQuesId(item?.data?.id)} style={{ cursor: 'pointer' }}>
                    {item.status === "Done" && "✅"}
                    {item.status !== "Done" && "🛑"}&nbsp; <h6>{item.title}</h6>
                  </div>
                </div>
              ))}

            </div>

          </div>

          {/* Block 8 */}
          <div className="bento_block custom_heads tall_block block_8">

            <h2>Issues Assigned</h2>

            <div className="issue">
              <h6>Drive-By Download Attack</h6>
              <button>View</button>
            </div>
            <div className="issue">
              <h6>Ransomware Attack</h6>
              <button>View</button>
            </div>
            <div className="issue">
              <h6>SQL Injection Attack</h6>
              <button>View</button>
            </div>
            <div className="issue">
              <h6>Drive-By Download Attack</h6>
              <button>View</button>
            </div>


          </div>

          {/* Block 9 (tall) */}
          <div className="bento_block wide_block graph_block">
            <div className="home_issues_by_rating">
              <div className="home_ir_readings">
                <div className="home_ir_reading">20</div>
                <div className="home_ir_reading">10</div>
                <div className="home_ir_reading">0</div>
              </div>
              <div className="home_rating_bars">
                <div className="home_ir_label_bar">
                  <div className="home_irbar"></div>
                  <div className="home_ir_label">Blank</div>
                </div>
                <div className="home_ir_label_bar">
                  <div className="home_irbar"></div>
                  <div className="home_ir_label">High</div>
                </div>
                <div className="home_ir_label_bar">
                  <div className="home_irbar"></div>
                  <div className="home_ir_label">Medium</div>
                </div>
                <div className="home_ir_label_bar">
                  <div className="home_irbar"></div>
                  <div className="home_ir_label">Low</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className="cardscontainerhome">

        <div className="back_acc_bar span2_home_section">

          <svg onClick={() => { navigate(-1) }} width="44" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 19.9201L8.47997 13.4001C7.70997 12.6301 7.70997 11.3701 8.47997 10.6001L15 4.08008" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

          <SuperAvatar firstName={avaUserName} classOfAvatar={"top_avatar"} />

        </div>

        <div className="taskcard taskcard1">
          <h2 >Pending Tasks</h2>
          <h2 style={{ color: "orange" }}>{todotask.count}</h2>
        </div>

        <div className="taskcard taskcard2">
          <h2 className="">Comments</h2>
          <h2 style={{ color: "#4d4dd0a1" }}>32</h2>
        </div>

        <div className="taskcard taskcard3">
          <h2 className="">Completed Tasks</h2>
          <h2 style={{ color: "#17c247" }}>{donetask.count}</h2>
        </div>

        <div className="taskcard taskcard4">
          <h2 className="">Pending Approvals</h2>
          <h2 style={{ color: "#ff0000b3" }}>{reviewtask.count}</h2>
        </div>

        <div className="task_click_con span2_home_section" onClick={handleTasksListsOpen} >
          <h5>View tasks assigned to me</h5>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.91 19.92L15.43 13.4C16.2 12.63 16.2 11.37 15.43 10.6L8.91 4.08002" stroke="#5771DF" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>

        {tasksList && (

          <div className="tasks_view_transp">

            <svg onClick={handleTasksListClose} width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" width="32" height="32" rx="16" fill="white" />
              <path d="M13.67 18.83L19.33 13.17" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M19.33 18.83L13.67 13.17" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          </div>)}

        <div className="section_cards_pwa" onClick={handleOpenAuditPopup}>

          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3334 7.99996H18.6667C21.3334 7.99996 21.3334 6.66663 21.3334 5.33329C21.3334 2.66663 20 2.66663 18.6667 2.66663H13.3334C12 2.66663 10.6667 2.66663 10.6667 5.33329C10.6667 7.99996 12 7.99996 13.3334 7.99996Z" stroke="#415FE1" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M21.3333 5.35999C25.7733 5.59999 28 7.23999 28 13.3333V21.3333C28 26.6667 26.6667 29.3333 20 29.3333H12C5.33333 29.3333 4 26.6667 4 21.3333V13.3333C4 7.25332 6.22667 5.59999 10.6667 5.35999" stroke="#415FE1" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.33331 13.3334H21.3333" stroke="#415FE1" stroke-width="1.5" stroke-linecap="round" />
            <path d="M9.33331 18.6666H16" stroke="#415FE1" stroke-width="1.5" stroke-linecap="round" />
          </svg>
          <h4>Audits</h4>

        </div>

        {auditPwaPopup && (

          <div className="audit_view_transp">

            <div className="audit_list_con">
              <h5>Audits you’re involved in</h5>

              <div className="audit_name_con">

                {auditNames.map((audit) => (

                  <div className="auditname_status" onClick={() => navigateToAuditPage(audit.id)}>

                    <h4>{audit.name}</h4>
                    <div className="audit_status_pwa">

                      <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="3" cy="3" r="3" fill="#17C247" />
                      </svg>

                      <h6>{audit.status}</h6>

                    </div>

                  </div>

                ))}



              </div>

            </div>

            <svg onClick={handleCloseAuditPopup} width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" width="32" height="32" rx="16" fill="white" />
              <path d="M13.67 18.83L19.33 13.17" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M19.33 18.83L13.67 13.17" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          </div>)}

        <div className="section_cards_pwa" onClick={handleIssueOpen} >

          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 10.3334V17.3334" stroke="#5771DF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M28.1067 11.44V20.56C28.1067 22.0533 27.3067 23.44 26.0134 24.2L18.0934 28.7733C16.8 29.52 15.2 29.52 13.8933 28.7733L5.97332 24.2C4.67998 23.4533 3.87997 22.0666 3.87997 20.56V11.44C3.87997 9.94667 4.67998 8.55995 5.97332 7.79995L13.8933 3.22663C15.1867 2.47996 16.7867 2.47996 18.0934 3.22663L26.0134 7.79995C27.3067 8.55995 28.1067 9.93334 28.1067 11.44Z" stroke="#5771DF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16 21.6V21.7333" stroke="#5771DF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>

          <h4>Issues</h4>

        </div>

        {issuesPwaPopup && (

          <div className="audit_view_transp">

            <div className="audit_list_con">
              <h5>Issues assigned to you</h5>

              <div className="audit_name_con">

                {issueNames.map((audit) => (
                  <>
                    <div className="auditname_status">
                      <h5 key={audit.id} onClick={() => handleIssueClick(audit.id)} style={{ cursor: 'pointer' }}>
                        {audit.objective ? audit.objective : 'Name Not Available'}
                      </h5>
                    </div>
                  </>
                ))}

              </div>

            </div>

            <svg onClick={handleIssueClose} width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" width="32" height="32" rx="16" fill="white" />
              <path d="M13.67 18.83L19.33 13.17" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M19.33 18.83L13.67 13.17" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>

          </div>)}

      </div>

    </>
  );
};

export default Userhome;
