import React from "react";

import "../auditUniverse/riskAssesment/createQuestionnaire/createQuestionnaire.css"

import { NavLink, useLocation } from "react-router-dom";
import Breadcrumb from "../Breadcrumb";
import SidebarMenu from "../SidebarMenu";
import "../../css/viewuser.css";

import { auditBusiness, auditExecutionTop, auditExecutionQA } from "../subroutes";
import { useState } from "react";
import QuestionTemp from "./QuestionTemplate";
import { useEffect, useRef } from "react";
import RenderIcons from "../RenderIcons";
import { templates,templatesQA } from "../subroutes";
import NavRow from "../NavRow";
import "../../components/auditExecution/QASurvey/qasurvey.css"

const CreateQasurvey = ({ tableSize }) => {


  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRef = useRef(null);

    const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  const is_Admin = localStorage.getItem("is_Admin");


  var routes = [];
  if (is_Admin) {
    routes = auditExecutionTop;
  } else if (Object.keys(userRoles).some(key => key.includes("Business"))) {
    routes = auditBusiness;
  }
  else if (Object.keys(userRoles).some(key => key.includes("Quality Assurance"))) {
    routes = templatesQA;
  }
  //else if (Object.keys(userRoles).some(key => key.includes("Audit Commsion"))) {
  //   routes = forAuditCommission;
  // } else if (Object.keys(userRoles).some(key => key.includes("Quality Assurance"))) {
  //   routes = forQA;
  // } 
  else {
    console.log("No matching role found");
  }

  const handleDropdownToggle = (index) => {
    setOpenDropdown(openDropdown === index ? null : index);
  };


  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const location = useLocation();

  const [sectionsData, setSectionsData] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);
  const [sectionErrors, setSectionErrors] = useState("");
  const [questionErrors, setQuestionErrors] = useState("");

  const [inputs, setInputs] = useState({});
  const [inputValues, setInputValues] = useState({});

  const [combinedData, setCombinedData] = useState([]);


  const addInput = (sectionId) => {
    setInputs({
      ...inputs,
      [sectionId]: [
        ...(inputs[sectionId] || []),
        { id: Date.now(), editing: true, value: "" },
      ],
    });
  };

  const removeInput = (sectionId, inputId) => {
    setInputs({
      ...inputs,
      [sectionId]: inputs[sectionId].filter((input) => input.id !== inputId),
    });
  };

  const handleInputChange = (sectionId, inputId, value) => {
    setInputValues({
      ...inputValues,
      [sectionId]: {
        ...inputValues[sectionId],
        [inputId]: value,
      },
    });
  };

  const handleEdit = (sectionId, inputId) => {
    setInputs({
      ...inputs,
      [sectionId]: inputs[sectionId].map((input) => {
        if (input.id === inputId) {
          return { ...input, editing: true };
        }
        return input;
      }),
    });
  };


  const showValues = () => {
  };
  return (
    <>
      <RenderIcons homeRoutes={routes} />
      <NavRow name={'Create QA Survey'} Create={'Create'} idtrue={true} />


      {/* <div className="create_ques_btn_con">
            <button onClick={showValues}> Save </button>
          </div> */}

      <div className="qaserveytempcon" style={{ width: tableSize ? "80vw" : "90.5vw" }}>
        <QuestionTemp tempID={location?.state?.data} tableSize={tableSize} />
      </div>
    </>
  );
};

export default CreateQasurvey;