import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../../../css/viewuser.css";
import "../../rcm/listofrcap.css";
import OptionDropDown from "../../../OptionDropDown";
import {
    fetchDataV2,
    justFetchDataV2,
    postDataWithFetchV2,
    updatewithFetchDataV2,
    deleteDataV2,
} from "../../../apiUtils";
import { auditRoutes } from "../../../subroutes";
import axios from "axios";
import WindowComponent from "../../../WindowComponent";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../../RenderIcons";
import Button from "../../../user/Button";
import NavRow from "../../../NavRow";

const DetailRcapPwa = ({ tableSize }) => {
    const [errors, setErrors] = useState("");
    const [showDone, setShowDone] = useState(false);
    const [APIData, setAPIData] = useState([]);
    const [successMessageQuestion, setSuccessMessage] = useState(false);
    const [DepartmetData, setDepartmentData] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const successMessage = location.state?.successMessage;
    const [uploadStatus, setUploadStatus] = useState("idle");
    const [badRequest, setBadRequest] = useState("");
    const [file, setFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const { id, planid, tempId, departmentId } = useParams();
    const [editingRowIndex, setEditingRowIndex] = useState(null);
    const [editedRowData, setEditedRowData] = useState({});
    const [entityId, setEntityId] = useState("");
    const [businessUnitOptions, setBusinessUnitOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [control_existing, setExistingControl] = useState("");
    const [control_desired, setDesiredControl] = useState("");
    const [control_design, setControlDesign] = useState("");
    const [audit_procedure_data, setDataRequired] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [likelikhoodError, setLikelikhoodError] = useState(false);
    const [impactError, setImpactError] = useState(false);
    const [existingControlError, setExistingControlError] = useState(false);
    const [desiredControlError, setDesiredControlError] = useState(false);
    const [evaluationError, setEvaluationError] = useState(false);
    const [controlDesignError, setControlDesignError] = useState(false);
    const [controlTypeError, setcontrolTypeError] = useState(false);
    const [resLikelihoodError, setResLikelihoodError] = useState(false);
    const [resImpactError, setResImpactError] = useState(false);
    const [auditStepDetailsError, setAuditStepDetailsError] = useState(false);
    const [dataRequiredError, setDataRequiredError] = useState(false);
    const [selectedBusiness, setSelectedBusiness] = useState(null);
    const [objectiveError, setObjectiveError] = useState(false);
    const [riskIdError, setRiskIdError] = useState(false);
    const [riskCategoryError, setRiskCategoryError] = useState(false);
    const [describeRiskError, setDescribeRiskError] = useState(false);
    const [titleRiskError, setTitleRiskError] = useState(false);
    const segments = location.pathname.split("/");
    const AuditExecution = segments.find(
        (segment) => segment === "AuditExecution"
    );
    const [showNewRow, setShowNewRow] = useState(false);
    const [deptObject, setDeptObject] = useState({});
    const [businessUnitsId, setBusinessUnitsId] = useState([]);
    const [departmentNames, setDepartmentNames] = useState([]);
    const [departmentIds, setDepartmentIds] = useState({});
    const [planData, setPlanData] = useState([]);
    const [showApproves, setShowApproves] = useState(false);
    const user_id = localStorage.getItem("user_id");



    useEffect(() => {
        const timeoutId = setTimeout(() => {
            navigate(".", { replace: true });
        }, 5000);

        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        if (APIData.length > 0) {
            setShowDone(true);
        }
    }, [APIData]);

    const handleDelete = (rcapid) => {
        const parameter = {
            url: AuditExecution
                ? `/api/audit_engagement/workpaper/${rcapid}`
                : `/api/audit_universe/rcap/${rcapid}`,
            navigate: navigate,
            setErrors: setErrors,
            navUrl: AuditExecution
                ? `/AuditExecution/${id}/Planning/${planid}/RiskControlMatrix/`
                : `/AuditUniverse/Rcm/${departmentId}`
        }
        deleteDataV2(parameter);
    };

    const handleInputChange = (e, key) => {
        setEditedRowData({ ...editedRowData, [key]: e.target.value });
    };

    const handleDoneClick = (wid) => {
        if (
            !editedRowData.objective ||
            !editedRowData.risk_id ||
            !editedRowData.risk_title ||
            !editedRowData.inherent_assessment_likelihood ||
            !editedRowData.inherent_assessment_impact ||
            !editedRowData.residual_assessment_likelihood ||
            !editedRowData.residual_assessment_impact ||
            !editedRowData.audit_procedure_step
        ) {
            setObjectiveError(!editedRowData.objective ? true : false);
            setRiskIdError(!editedRowData.risk_id ? true : false);
            setTitleRiskError(!editedRowData.risk_title ? true : false);
            setLikelikhoodError(
                !editedRowData.inherent_assessment_likelihood ? true : false
            );
            setImpactError(!editedRowData.inherent_assessment_impact ? true : false);
            setResLikelihoodError(
                !newRow.residual_assessment_likelihood ? true : false
            );
            setResImpactError(
                !editedRowData.residual_assessment_impact ? true : false
            );
            setAuditStepDetailsError(
                !editedRowData.audit_procedure_step ? true : false
            );
            // setBusinessUnitIdError(!newRow.business_unit ? true : false);
            setErrors("Please fill in all required fields.");
            setTimeout(() => {
                setErrors(null);
            }, 5000);
            return;
        }



        const payload = AuditExecution
            ? {
                ...editedRowData,
                audit_id: id,
                rcm_flag: isChecked,
                business_unit_id: selectedBusiness ? selectedBusiness : editedRowData?.business_unit?.id,
            }
            : { ...editedRowData, audit_id: id, business_unit_id: selectedBusiness ? selectedBusiness : editedRowData?.business_unit?.id };

        // Update the APIData with the edited row data
        const updatedAPIData = APIData.map((data, index) => {
            if (index === editingRowIndex) {
                return payload;
            }
            return data;
        });
        setAPIData(updatedAPIData);

        // Determine the API endpoint based on AuditExecution
        const apiEndpoint = AuditExecution
            ? `/api/audit_engagement/workpaper/${payload.id}`
            : `/api/audit_universe/rcap/${payload.id}`;

        // Make an API call to update the data on the server

        const parameter = {
            url: apiEndpoint,
            payload,
            tofetch: {
                items: [
                    {
                        fetchurl: AuditExecution ? `/api/audit_engagement/workpaper/?audit_id=${id}` : `/api/audit_universe/rcap/?department_ids=${departmentId} `,
                        dataset: AuditExecution ? setAPIData : setAPIData,
                    },
                ],
                setErrors: setErrors,
            },
        };

        updatewithFetchDataV2(parameter);

        setEditingRowIndex(null);
        setEditedRowData({});
    };
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    function impactmapping(value) {
        switch (value) {
          case 1:
            return "Low (1)";
          case 2:
            return "Minor (2)";
          case 3:
            return "Moderate (3)";
          case 4:
            return "Major (4)";
          case 5:
            return "Severe (5)";
          default:
            return "";
        }
      }
      function likemapping(value) {
        switch (value) {
          case 1:
            return "Remote (1)";
          case 2:
            return "Unlikely (2)";
          case 3:
            return "Possible (3)";
          case 4:
            return "Likely (4)";
          case 5:
            return "Almost Certain (5)";
          default:
            return "";
        }
      }

    const handleFileUpload = async (e) => {
        if (file) {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("department", departmentId);

            setUploadStatus("uploading");
            setUploadProgress(0);

            axios
                .post(
                    `${process.env.REACT_APP_API_URL}/api/audit_universe/rcap-upload/`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                        onUploadProgress: (progressEvent) => {
                            const percentCompleted = Math.round(
                                (progressEvent.loaded * 100) / progressEvent.total
                            );
                            setUploadProgress(percentCompleted);
                        },
                    }
                )
                .then((response) => {
                    setUploadStatus("success");
                    window.location.reload(); // Reload the page on successful upload
                })
                .catch((error) => {
                    // console.error("Error uploading file:", error);
                    setBadRequest(error?.response?.data?.error);
                    setUploadStatus("error");
                });
        } else {
            console.error("No file selected");
        }
    };

    const handleEditClick = (index, data) => {
        setEditingRowIndex(index);
        setEditedRowData({ ...data });
    };

    const handleFilterChange = (filterKey, e) => {
        if (filterKey === "business_unit") {
            setFilters({
                ...filters,
                business_unit: { name: e.target.value },
            });
        } else {
            setFilters({ ...filters, [filterKey]: e.target.value });
        }
    };

    const [buData, setBuData] = useState({});
    useEffect(() => {
        if (DepartmetData) {
            setBusinessUnitOptions(
                DepartmetData.business_units ? DepartmetData.business_units : []
            );
            setBuData();
        }
    }, [DepartmetData]);

    const [audit, setAudit] = useState([]);

    useEffect(() => {
        if (id) {
            const parameter = {
                url: `/api/audit_engagement/audit/${id}/`,
                setterFunction: setAudit,
                setErrors: setErrors,
            }
            fetchDataV2(parameter);
        }
    }, [id]);

    const [auditEntity, setAuditEntity] = useState([]);
    useEffect(() => {
        if (AuditExecution) {
            const parameter = {
                url: `/api/audit_engagement/workpaper/?audit_id=${id}`,
                setterFunction: setAPIData,
                setErrors: setErrors,
            }
            fetchDataV2(parameter);
        }
    }, [AuditExecution, id, setAPIData, setErrors]);

    useEffect(() => {
        if (audit.audit_entity) {
            const parameter = {
                url: `/api/audit_universe/audit-entities/${audit?.audit_entity?.id}/`,
                setterFunction: setAuditEntity,
                setErrors: setErrors,
            }
            fetchDataV2(parameter);
        }
    }, [audit]);

    useEffect(() => {
        if (auditEntity.audit_unit) {
            const unitsId = auditEntity.audit_unit.map(
                (unit) => unit.business_units_id
            );
            const deptNames = auditEntity.audit_unit.map(
                (unit) => unit.department_name
            );
            setBusinessUnitsId(unitsId);
            setDepartmentNames(deptNames);
        }
    }, [auditEntity]);

    useEffect(() => {
        const parameter = {
            url: `/api/system/departments/`,
            setterFunction: (allDepartments) => {
                var _deptObject = {};
                allDepartments.map((u) => {
                    _deptObject[u.name] = u.id;
                });
                setDeptObject(_deptObject);
            },
            setErrors: setErrors,
        }
        fetchDataV2(parameter);
    }, []);

    useEffect(() => {
        console.log(deptObject);
        console.log(businessUnitsId);
        console.log(departmentNames);
        setDepartmentIds(
            departmentNames.map((department) => deptObject[department])
        );
    }, [deptObject]);

    useEffect(() => {
        setDepartmentIds(
            departmentNames.map((department) => deptObject[department])
        );
    }, [departmentNames]);

    const removeDuplicates = (arr) => {
        return Array.isArray(arr) ? [...new Set(arr)] : [];
    };
    useEffect(() => {
        const departmentsString = departmentIds
            ? removeDuplicates(departmentIds).toString()
            : "";
        const businessUnitsString = businessUnitsId
            ? businessUnitsId.toString()
            : "";
        if (departmentId) {
            const parameter2 = {
                url: `/api/audit_universe/rcap/?department_ids=${departmentId}`,
                setterFunction: (data) => { setAPIData(data) },
                setErrors: (error) => { setErrors(error) },
                setLoading: setLoading,
            }
            justFetchDataV2(parameter2);

            const parameter = {
                url: `/api/system/departments/${departmentId}/`,
                setterFunction: setDepartmentData,
                setErrors: setErrors,
            }
            fetchDataV2(parameter);
        }
        console.log(DepartmetData);
        console.log(APIData);

        if (
            !departmentId &&
            !businessUnitsString &&
            !departmentsString &&
            !AuditExecution
        ) {
            const parameter3 = {
                url: `/api/audit_universe/rcap/`,
                setterFunction: (data) => { setAPIData(data) },
                setErrors: (error) => { setErrors(error) },
                setLoading: setLoading,
            }
            justFetchDataV2(parameter3);
        }
    }, [departmentIds, businessUnitsId, departmentId, AuditExecution]);

    useEffect(() => {
        if (planData.reviewer) {
            const reviewerId = planData.reviewer ? planData.reviewer.user_id : "";
            if (reviewerId === user_id) {
                setShowApproves(true);

                console.log(showApproves, "showApproves");
            }
        }
    }, [planData]);

    useEffect(() => {
        const parameter = {
            url: `/api/audit_engagement/plan/${planid}/`,
            setterFunction: setPlanData,
            setErrors: setErrors,
        }
        fetchDataV2(parameter);
    }, [planid]);

    const [filters, setFilters] = useState({
        business_unit: "",
        process: "",
        sub_process: "",
        risk_id: "",
        objective: "",
        risk_title: "",
        risk_category: "",
        risk_description: "",
        inherent_assessment_likelihood: "",
        inherent_assessment_impact: "",
        control_existing: "",
        control_desired: "",
        control_evaluation: "",
        control_design: "",
        control_type: "",
        control_frequency: "",
        control_gap: "",
        residual_assessment_likelihood: "",
        residual_assessment_impact: "",
    });

    const filteredData = APIData.filter((data) => {
        return Object.keys(filters).every((key) => {
            if (!filters[key]) return true;
            if (key === "business_unit") {
                return data.business_unit?.name
                    .toLowerCase()
                    .includes(filters.business_unit.name.toLowerCase());
            }
            return data[key]
                ?.toString()
                .toLowerCase()
                .includes(filters[key].toLowerCase());
        });
    });

    const [newRow, setNewRow] = useState({
        process: "",
        sub_process: "",
        risk_id: "",
        objective: "",
        risk_title: "",
        risk_category: "",
        risk_description: "",
        inherent_assessment_likelihood: "",
        inherent_assessment_impact: "",
        control_existing: "",
        control_desired: "",
        control_evaluation: "",
        control_design: "",
        control_type: "",
        control_frequency: "",
        control_gap: "",
        residual_assessment_likelihood: "",
        residual_assessment_impact: "",
        audit_procedure_step: "",
        audit_procedure_data: "",
    });

    const handleCreateRow = () => {
        setShowNewRow(true);
        setNewRow({
            process: "",
            sub_process: "",
            risk_id: "",
            objective: "",
            risk_title: "",
            risk_category: "",
            risk_description: "",
            inherent_assessment_likelihood: "",
            inherent_assessment_impact: "",
            control_existing: "",
            control_desired: "",
            control_evaluation: "",
            control_design: "",
            control_type: "",
            control_frequency: "",
            control_gap: "",
            residual_assessment_likelihood: "",
            residual_assessment_impact: "",
            audit_procedure_step: "",
            audit_procedure_data: "",
        });
    };

    const addRCap = (e) => {
        if (
            !newRow.objective ||
            !newRow.risk_id ||
            !newRow.risk_title ||
            !newRow.inherent_assessment_likelihood ||
            !newRow.inherent_assessment_impact ||
            !newRow.residual_assessment_likelihood ||
            !newRow.residual_assessment_impact ||
            !newRow.audit_procedure_step
        ) {
            setObjectiveError(!newRow.objective ? true : false);
            setRiskIdError(!newRow.risk_id ? true : false);
            setTitleRiskError(!newRow.risk_title ? true : false);
            setLikelikhoodError(
                !newRow.inherent_assessment_likelihood ? true : false
            );
            setImpactError(!newRow.inherent_assessment_impact ? true : false);
            setResLikelihoodError(
                !newRow.residual_assessment_likelihood ? true : false
            );
            setResImpactError(!newRow.residual_assessment_impact ? true : false);
            setAuditStepDetailsError(!newRow.audit_procedure_step ? true : false);
            setErrors("Please fill in all required fields.");
            setTimeout(() => {
                setErrors(null);
            }, 5000);
            return;
        }

        const payload1 = {
            business_unit_id: selectedBusiness,
            department_id: departmentId,
            process: newRow.process,
            sub_process: newRow.sub_process,
            objective: newRow.objective,
            risk_id: newRow.risk_id,
            risk_category: newRow.risk_category,
            risk_description: newRow.risk_description,
            risk_title: newRow.risk_title,
            inherent_assessment_likelihood: parseInt(
                newRow.inherent_assessment_likelihood
            ),
            inherent_assessment_impact: parseInt(newRow.inherent_assessment_impact),
            control_existing: newRow.control_existing,
            control_desired: newRow.control_desired,
            control_evaluation: newRow.control_evaluation,
            control_design: newRow.control_design,
            control_type: newRow.control_type,
            control_frequency: newRow.control_frequency,
            control_gap: newRow.control_gap,
            residual_assessment_likelihood: parseInt(
                newRow.residual_assessment_likelihood
            ),
            residual_assessment_impact: parseInt(newRow.residual_assessment_impact),
            audit_procedure_step: newRow.audit_procedure_step,
            audit_procedure_data: newRow.audit_procedure_data,
            audit_entity: entityId ? entityId : id,
            rcm_template_id: tempId,
        };

        const parameter = {
            url: "/api/audit_universe/rcap/",
            payload: {
                business_unit_id: selectedBusiness,
                department_id: departmentId,
                process: newRow.process,
                sub_process: newRow.sub_process,
                objective: newRow.objective,
                risk_id: newRow.risk_id,
                risk_category: newRow.risk_category,
                risk_description: newRow.risk_description,
                risk_title: newRow.risk_title,
                inherent_assessment_likelihood: parseInt(
                    newRow.inherent_assessment_likelihood
                ),
                inherent_assessment_impact: parseInt(newRow.inherent_assessment_impact),
                control_existing: newRow.control_existing,
                control_desired: newRow.control_desired,
                control_evaluation: newRow.control_evaluation,
                control_design: newRow.control_design,
                control_type: newRow.control_type,
                control_frequency: newRow.control_frequency,
                control_gap: newRow.control_gap,
                residual_assessment_likelihood: parseInt(
                    newRow.residual_assessment_likelihood
                ),
                residual_assessment_impact: parseInt(newRow.residual_assessment_impact),
                audit_procedure_step: newRow.audit_procedure_step,
                audit_procedure_data: newRow.audit_procedure_data,
                audit_entity: entityId ? entityId : id,
                rcm_template_id: tempId,
            },
            tofetch: {
                items: [
                    {
                        fetchurl: `/api/audit_universe/rcap/?department_ids=${departmentId}`,
                        dataset: setAPIData,
                    },
                ],
                setSuccessMessage: setSuccessMessage,
                setErrors: setErrors,
            },
        };

        postDataWithFetchV2(parameter);
        setNewRow("");
        setShowNewRow(false);
    };

    const Category = [
        { label: " Financial", value: "Financial" },
        { label: "Operational", value: "Operational" },
        { label: "Strategic", value: "Strategic" },
        { label: "Compliance", value: "Compliance" },
        { label: "Reputational", value: "Reputational" },
    ];
    const inherentAssessmentImpactOptions = [
        { label: "Insignificant (1)", value: 1 },
        { label: "Minor (2)", value: 2 },
        { label: "Moderate (3)", value: 3 },
        { label: "Major (4)", value: 4 },
        { label: "Severe (5)", value: 5 },
    ];

    const inherentAssessmentLikeOptions = [
        { label: "Remote (1)", value: 1 },
        { label: "Unlikely (2)", value: 2 },
        { label: "Possible (3)", value: 3 },
        { label: "Likely (4)", value: 4 },
        { label: "Almost Certain (5)", value: 5 },
    ];

    const residualAssessmentImpactOptions = [
        { label: "Insignificant (1)", value: 1 },
        { label: "Minor (2)", value: 2 },
        { label: "Moderate (3)", value: 3 },
        { label: "Major (4)", value: 4 },
        { label: "Severe (5)", value: 5 },
    ];

    const residualAssessmentLikeOptions = [
        { label: "Remote (1)", value: 1 },
        { label: "Unlikely (2)", value: 2 },
        { label: "Possible (3)", value: 3 },
        { label: "Likely (4)", value: 4 },
        { label: "Almost Certain (5)", value: 5 },
    ];

    const controlEvaluationOptions = [
        { label: "Effective", value: "Effective" },
        { label: "Ineffective", value: "Ineffective" },
        { label: "Needs Improvement", value: "Needs Improvement" },
    ];
    const controlDesignOptions = [
        { label: "Manual", value: "Manual" },
        { label: "Automated", value: "Automated" },
    ];
    const controlTypeOptions = [
        { label: "Preventive", value: "Preventive" },
        { label: "Detective", value: "Detective" },
        { label: "Corrective", value: "Corrective" },
    ];

    const handleChangea = (event) => {
        if (AuditExecution) {
            const user = businessUnitOptions.find(
                (user) => user?.business_units_id === Number(event.target.value)
            );
            setSelectedBusiness(user?.business_units_id);
        } else {
            const user = businessUnitOptions.find(
                (user) => user?.id === Number(event.target.value)
            );
            setSelectedBusiness(user?.id);
        }
    };
    console.log(selectedBusiness);

    useEffect(() => {
        if (filteredData) {
            const buIds = filteredData.map((data) =>
                data.business_unit ? data.business_unit.id : ""
            );
            setBuData(buIds);
        }
    }, []);

    const rcapUpload = {
        handleFileUpload: handleFileUpload,
        setAPIData: setAPIData,
        setUploadStatus: setUploadStatus,
        setUploadProgress: setUploadProgress,
        setFile: setFile,
        departmentId: departmentId,
        AuditExecution: AuditExecution,
    };

    useEffect(() => {
        if (auditEntity?.audit_unit) {
            setBusinessUnitOptions(auditEntity?.audit_unit);
        }
    }, [auditEntity]);
    console.log(APIData)

    return (
        <>
            {errors && <p className="errormessage">{errors}</p>}
            {successMessage && <p className="successmessage">{successMessage}</p>}
            {successMessageQuestion && (
                <p className="successmessage">Question Added SuccessFully</p>
            )}
            <section className="navroutes">
                <RenderIcons homeRoutes={auditRoutes} />
                <NavRow
                    name={`${DepartmetData?.name ? DepartmetData?.name : "ALL"}`}
                    parentName={audit?.name}
                    Create={"Create"}
                    link={`/Administration/user/createUser`}
                    trueback={true}
                    RcmCreate={() => handleCreateRow()}
                    rcapUpload={rcapUpload}
                    secondName={"Planning"}
                />

                <div className="rcmt_table"
                    style={{ width: tableSize ? "86.5vw" : "92.5vw" }}
                >
                    <h1>This Page is only <br /> available on Mobile App</h1>
                </div>

                <div className="cardscontainer">

                    <div className="previous_rcm_data ">
                        <h2 style={{ fontSize: "14px", fontFamily: "interbold" }}>
                            Risk Control Matrix Audit Procedure
                        </h2>

                        <div className="read_rcm_section">
                            <h2
                                style={{
                                    fontSize: "14px",
                                    fontFamily: "interbold",
                                    marginBottom: "16px",
                                }}
                            >
                                Introductory
                            </h2>

                            <div className="read_rcm_content">
                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Functions</label>
                                    <p>
                                        {DepartmetData.rcap
                                            ? DepartmetData.rcap.business_unit?.name
                                                ? DepartmetData.rcap.business_unit?.name
                                                : "All Functions"
                                            : "All Functions"}
                                    </p>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Process</label>
                                    <p>{DepartmetData.process ? DepartmetData.process : "--"}</p>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Sub-Process</label>
                                    <p>{DepartmetData.sub_process ? DepartmetData.sub_process : "--"}</p>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Objective Description</label>
                                    <p>{APIData.objective ? APIData.objective : "--"}</p>
                                </div>
                            </div>
                        </div>

                        <div className="read_rcm_section">
                            <h2
                                style={{
                                    fontSize: "14px",
                                    fontFamily: "interbold",
                                    marginBottom: "16px",
                                }}
                            >
                                Risk Description
                            </h2>

                            <div className="read_rcm_content">
                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Risk ID</label>
                                    <p>{APIData.risk_id ? APIData.risk_id : ""}</p>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Risk Category</label>
                                    <p>
                                        {APIData.risk_category ? APIData.risk_category : ""}
                                    </p>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Risk Title</label>
                                    <p>{APIData.risk_title ? APIData.risk_title : "--"}</p>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Risk Statement</label>
                                    <p>
                                        {APIData.risk_description
                                            ? APIData.risk_description
                                            : ""}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="read_rcm_section">
                            <h2
                                style={{
                                    fontSize: "14px",
                                    fontFamily: "interbold",
                                    marginBottom: "16px",
                                }}
                            >
                                Inherent Assessment
                            </h2>

                            <div className="read_rcm_content">
                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Inh. Likelihood</label>
                                    <p>
                                        {likemapping(
                                            APIData.inherent_assessment_likelihood
                                                ? APIData.inherent_assessment_likelihood
                                                : "--"
                                        )}
                                    </p>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Inh. Impact</label>
                                    <p>
                                        {impactmapping(
                                            APIData.inherent_assessment_impact
                                                ? APIData.inherent_assessment_impact
                                                : "--"
                                        )}
                                    </p>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Inherent Risk Evaluation Score</label>
                                    <p>
                                        {APIData.risk_inherant_evaluation_score
                                            ? APIData.risk_inherant_evaluation_score
                                            : "--"}
                                    </p>
                                </div>
                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Inherent Risk Evaluation</label>
                                    <p>
                                        {APIData.risk_inherant_evaluation
                                            ? APIData.risk_inherant_evaluation
                                            : "--"}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="read_rcm_section">
                            <h2
                                style={{
                                    fontSize: "14px",
                                    fontFamily: "interbold",
                                    marginBottom: "16px",
                                }}
                            >
                                Control
                            </h2>

                            <div className="read_rcm_content">
                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Control Description</label>
                                    <pre>
                                        {APIData.control_description
                                            ? APIData.control_description
                                            : "--"}
                                    </pre>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Control Design</label>
                                    <p>
                                        {APIData.control_design ? APIData.control_design : "--"}
                                    </p>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Control Type</label>
                                    <p>
                                        {APIData.control_type ? APIData.control_type : "--"}
                                    </p>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Control Evaluation</label>
                                    <p>
                                        {APIData.control_evaluation
                                            ? APIData.control_evaluation
                                            : "--"}
                                    </p>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Best Practices for Reference</label>
                                    <p>
                                        {APIData.control_desired
                                            ? APIData.control_desired
                                            : "--"}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="read_rcm_section">
                            <h2
                                style={{
                                    fontSize: "14px",
                                    fontFamily: "interbold",
                                    marginBottom: "16px",
                                }}
                            >
                                Residual Assessment
                            </h2>

                            <div className="read_rcm_content">
                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Res. Likelihood</label>
                                    <p>
                                        {likemapping(
                                            APIData.residual_assessment_likelihood
                                                ? APIData.residual_assessment_likelihood
                                                : "--"
                                        )}
                                    </p>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Res. Impact</label>
                                    <p>
                                        {impactmapping(
                                            APIData.residual_assessment_impact
                                                ? APIData.residual_assessment_impact
                                                : "--"
                                        )}
                                    </p>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Residual Risk Evaluation Score</label>
                                    <p>
                                        {APIData.risk_residual_evaluation_score
                                            ? APIData.risk_residual_evaluation_score
                                            : "--"}
                                    </p>
                                </div>
                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Residual Risk Evaluation </label>
                                    <p>
                                        {APIData.risk_residual_evaluation
                                            ? APIData.risk_residual_evaluation
                                            : "--"}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="read_rcm_section">
                            <h2
                                style={{
                                    fontSize: "14px",
                                    fontFamily: "interbold",
                                    marginBottom: "16px",
                                }}
                            >
                                Audit Procedure
                            </h2>

                            <div className="read_rcm_content">
                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Audit Step Details</label>
                                    <pre>
                                        {APIData.audit_procedure_step
                                            ? APIData.audit_procedure_step
                                            : "--"}
                                    </pre>
                                </div>

                                <div className="viewworkpap_labelandinputs">
                                    <label htmlFor="">Data Required</label>
                                    <p>
                                        {APIData.audit_procedure_data
                                            ? APIData.audit_procedure_data
                                            : "--"}
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            </section>
        </>
    );
};

export default DetailRcapPwa;
