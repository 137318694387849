import React, { useState, useEffect } from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../../components/Breadcrumb";
import SidebarMenu from "../../../components/SidebarMenu";
import "../../../css/viewuser.css";
import { fetchDataV2 } from "../../apiUtils";
import { issueTracking } from "../../subroutes";
import create from "../../../images/createbutton.svg"
import openarrow from "../../../images/openarrow.svg"
import pie_chart from "../../../images/pie_chart.png"
import "./../issuetracking.css"
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";
import Piechart from "../../../components/issueTracking/Charts/PieChart"
import IssueBarChart from "../../../components/issueTracking/Charts/IssueBarChart"
import AllIssuePieChart from "../Charts/AllIssuePieChart"
import PriorityBarChart from "../Charts/PriorityBarChart"
import AuditStatusPieChart from "../Charts/AuditStatusPieChart";

const AuditAnalytics = ({ tableSize }) => {
    const [errors, setErrors] = useState("")
    const [APIData, setAPIData] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const successMessageColor = location.state?.successMessageColor;
    const successMessage = location.state?.successMessage;
    const [showMessage, setShowMessage] = useState(!!successMessage);
    const originalUrl = location.state?.from;
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            navigate(".", { replace: true });
        }, 5000);

        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        const parameter={
            url:`/api/audit_universe/audit-entities/`,
            setterFunction: setAPIData,
            setErrors:setErrors,
          }
        fetchDataV2(parameter);

    }, []);

    const Viewuser = (data) => {

        const { name, SelectedKeysOther, description, BusinessUnit } = data;

        const localStorageData = {
            name,
            SelectedKeysOther,
            description,
            BusinessUnit,
        };
        localStorage.setItem(
            "key_stakeholder_name",
            data.key_stakeholder ? data.key_stakeholder.name.split(" ")[0] : 'No Units'
        );

        localStorage.setItem(
            "key_stakeholder_id",
            data.key_stakeholder ? data.key_stakeholder.id : ''
        );
        localStorage.setItem("ID", data.id);

        localStorage.setItem("buUnits", JSON.stringify(data.business_units));
        localStorage.setItem("otherUnits", JSON.stringify(data.other_stakeholders));



        Object.entries(localStorageData).forEach(([key, value]) => {
            localStorage.setItem(key, value);
        });

        navigate("/AuditUniverse/auditEntities/ViewAuditEntity");
    };

    const [filters, setFilters] = useState({
        name: "",
        key_stakeholder: "",
    });

    const handleFilterChange = (filterKey, e) => {
        setFilters({ ...filters, [filterKey]: e.target.value });
    };

    const issueData = [
        // Q1
        { name: "3.3(2)", SubObs: 1, Priority: "High", Quarter: "Q1" },
        { name: "3.4", SubObs: 2, Priority: "Medium", Quarter: "Q1" },
        { name: "3.7", SubObs: 3, Priority: "Low", Quarter: "Q1" },
        { name: "3.8", SubObs: 2, Priority: "High", Quarter: "Q1" },
        { name: "3.9", SubObs: 4, Priority: "Medium", Quarter: "Q1" },
        { name: "3.10", SubObs: 2, Priority: "Low", Quarter: "Q1" },
        { name: "3.12", SubObs: 1, Priority: "High", Quarter: "Q1" },
        { name: "3.13", SubObs: 3, Priority: "Medium", Quarter: "Q1" },

        // Q2
        { name: "3.5", SubObs: 3, Priority: "Low", Quarter: "Q2" },
        { name: "3.11", SubObs: 3, Priority: "High", Quarter: "Q2" },
        { name: "3.14(1)", SubObs: 2, Priority: "Medium", Quarter: "Q2" },
        { name: "3.16", SubObs: 4, Priority: "Low", Quarter: "Q2" },
        { name: "3.17", SubObs: 1, Priority: "High", Quarter: "Q2" },
        { name: "3.18", SubObs: 5, Priority: "Medium", Quarter: "Q2" },
        { name: "3.19", SubObs: 3, Priority: "Low", Quarter: "Q2" },
        { name: "3.20", SubObs: 4, Priority: "High", Quarter: "Q2" },

        // Q3
        { name: "3.14(2)", SubObs: 1, Priority: "Medium", Quarter: "Q3" },
        { name: "3.21", SubObs: 3, Priority: "Low", Quarter: "Q3" },
        { name: "3.22", SubObs: 2, Priority: "High", Quarter: "Q3" },
        { name: "3.24", SubObs: 4, Priority: "Medium", Quarter: "Q3" },
        { name: "3.25", SubObs: 5, Priority: "Low", Quarter: "Q3" },
        { name: "3.26", SubObs: 3, Priority: "High", Quarter: "Q3" },
        { name: "3.27", SubObs: 2, Priority: "Medium", Quarter: "Q3" },
        { name: "3.28", SubObs: 4, Priority: "Low", Quarter: "Q3" },

        // Q4
        { name: "3.22", SubObs: 1, Priority: "High", Quarter: "Q4" },
        { name: "3.23", SubObs: 5, Priority: "Medium", Quarter: "Q4" },
        { name: "3.29", SubObs: 3, Priority: "Low", Quarter: "Q4" },
        { name: "3.30", SubObs: 4, Priority: "High", Quarter: "Q4" },
        { name: "3.31", SubObs: 2, Priority: "Medium", Quarter: "Q4" },
        { name: "3.32", SubObs: 1, Priority: "Low", Quarter: "Q4" },
        { name: "3.33", SubObs: 3, Priority: "High", Quarter: "Q4" },
        { name: "3.34", SubObs: 5, Priority: "Medium", Quarter: "Q4" }
    ];

    const auditData = [
        {
            "auditName": "Human Resource & Admin",
            "auditYear": 2022,
            "total": 53,
            "open": 1,
            "closed": 45,
            "overdue": 8,
            "extended": 2,
            "closedUnderMonitoring": 1,
            "issues": [
                {
                    "name": "3.3(2)",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "This sub-observation highlights inconsistencies in HR documentation."
                        }
                    ],
                    "priority": "High",
                    "quarter": "Q1",
                    "status": {
                        "open": 1,
                        "closed": 0,
                        "overdue": 0,
                        "extended": 0,
                        "closedUnderMonitoring": 0
                    }
                },
                {
                    "name": "3.4",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "Sub-observation related to employee performance reviews, with gaps in reporting."
                        },
                        {
                            "name": "SubObs 2",
                            "description": "Evaluation of employee training sessions is found to be incomplete."
                        }
                    ],
                    "priority": "Medium",
                    "quarter": "Q1",
                    "status": {
                        "open": 0,
                        "closed": 1,
                        "overdue": 0,
                        "extended": 0,
                        "closedUnderMonitoring": 0
                    }
                },
                {
                    "name": "3.7",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "Sub-observation identifies missing files in HR records."
                        },
                        {
                            "name": "SubObs 2",
                            "description": "Additional steps are required to ensure all documents are filed correctly."
                        },
                        {
                            "name": "SubObs 3",
                            "description": "Some records are stored in unorganized manner, affecting accessibility."
                        }
                    ],
                    "priority": "Low",
                    "quarter": "Q1",
                    "status": {
                        "open": 0,
                        "closed": 0,
                        "overdue": 1,
                        "extended": 0,
                        "closedUnderMonitoring": 0
                    }
                },
                {
                    "name": "3.8",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "Sub-observation notes the need for better training programs for staff."
                        },
                        {
                            "name": "SubObs 2",
                            "description": "Current programs are deemed insufficient for meeting organizational goals."
                        }
                    ],
                    "priority": "High",
                    "quarter": "Q1",
                    "status": {
                        "open": 0,
                        "closed": 0,
                        "overdue": 0,
                        "extended": 1,
                        "closedUnderMonitoring": 0
                    }
                },
                {
                    "name": "3.9",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "Observation highlights communication failures within HR teams."
                        },
                        {
                            "name": "SubObs 2",
                            "description": "Further training on inter-team communication is needed."
                        },
                        {
                            "name": "SubObs 3",
                            "description": "Teams need to collaborate more effectively to meet organizational objectives."
                        },
                        {
                            "name": "SubObs 4",
                            "description": "Sub-observation suggests setting up clearer communication channels."
                        }
                    ],
                    "priority": "Medium",
                    "quarter": "Q1",
                    "status": {
                        "open": 0,
                        "closed": 1,
                        "overdue": 0,
                        "extended": 0,
                        "closedUnderMonitoring": 0
                    }
                },
                {
                    "name": "3.10",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "Sub-observation identifies issues with tracking HR policy changes."
                        },
                        {
                            "name": "SubObs 2",
                            "description": "Tracking system needs to be more accurate and updated regularly."
                        }
                    ],
                    "priority": "Low",
                    "quarter": "Q1",
                    "status": {
                        "open": 0,
                        "closed": 0,
                        "overdue": 0,
                        "extended": 0,
                        "closedUnderMonitoring": 1
                    }
                },
                {
                    "name": "3.12",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "This sub-observation identifies a lack of clear guidelines for employee exit processes."
                        }
                    ],
                    "priority": "High",
                    "quarter": "Q1",
                    "status": {
                        "open": 1,
                        "closed": 0,
                        "overdue": 0,
                        "extended": 0,
                        "closedUnderMonitoring": 0
                    }
                }
            ]
        },

        {
            "auditName": "Finance",
            "auditYear": 2022,
            "total": 21,
            "open": 1,
            "closed": 11,
            "overdue": 6,
            "extended": 2,
            "closedUnderMonitoring": 1,
            "issues": [
                {
                    "name": "3.22",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "Sub-observation related to financial audit trails, showing inconsistencies."
                        }
                    ],
                    "priority": "High",
                    "quarter": "Q1",
                    "status": {
                        "open": 1,
                        "closed": 0,
                        "overdue": 0,
                        "extended": 0,
                        "closedUnderMonitoring": 0
                    }
                },
                {
                    "name": "3.23",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "Sub-observation regarding incorrect categorization of financial transactions."
                        },
                        {
                            "name": "SubObs 2",
                            "description": "The finance team needs to align categories with current standards."
                        },
                        {
                            "name": "SubObs 3",
                            "description": "Additional checks are needed to improve transaction classification."
                        },
                        {
                            "name": "SubObs 4",
                            "description": "Recommendations include conducting a quarterly review of categories."
                        },
                        {
                            "name": "SubObs 5",
                            "description": "Internal controls need to be strengthened to ensure accurate classification."
                        }
                    ],
                    "priority": "Medium",
                    "quarter": "Q1",
                    "status": {
                        "open": 0,
                        "closed": 1,
                        "overdue": 0,
                        "extended": 0,
                        "closedUnderMonitoring": 0
                    }
                },
                {
                    "name": "3.24",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "Sub-observation on finance reporting errors affecting monthly summaries."
                        },
                        {
                            "name": "SubObs 2",
                            "description": "Reports require further validation and reconciliation for accuracy."
                        },
                        {
                            "name": "SubObs 3",
                            "description": "Improper categorization of expenses needs to be corrected."
                        }
                    ],
                    "priority": "Low",
                    "quarter": "Q1",
                    "status": {
                        "open": 0,
                        "closed": 0,
                        "overdue": 1,
                        "extended": 0,
                        "closedUnderMonitoring": 0
                    }
                },
                {
                    "name": "3.25",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "Sub-observation on gaps in audit trail for financial transactions."
                        },
                        {
                            "name": "SubObs 2",
                            "description": "Reconciliations need to be performed more frequently for transparency."
                        },
                        {
                            "name": "SubObs 3",
                            "description": "Further investigation required into transactions flagged by previous audits."
                        },
                        {
                            "name": "SubObs 4",
                            "description": "Internal checks need to be more rigorous and proactive."
                        },
                        {
                            "name": "SubObs 5",
                            "description": "Recommendations for a dedicated audit review team to investigate anomalies."
                        }
                    ],
                    "priority": "High",
                    "quarter": "Q1",
                    "status": {
                        "open": 0,
                        "closed": 0,
                        "overdue": 0,
                        "extended": 1,
                        "closedUnderMonitoring": 0
                    }
                }
            ]
        },
        {
            "auditName": "IT Security",
            "auditYear": 2022,
            "total": 15,
            "open": 5,
            "closed": 8,
            "overdue": 2,
            "extended": 0,
            "closedUnderMonitoring": 0,
            "issues": [
                {
                    "name": "3.1",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "Security patches are outdated and need to be applied."
                        }
                    ],
                    "priority": "High",
                    "quarter": "Q1",
                    "status": {
                        "open": 1,
                        "closed": 0,
                        "overdue": 0,
                        "extended": 0,
                        "closedUnderMonitoring": 0
                    }
                },
                {
                    "name": "3.2",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "Weak password policies in place, which can lead to unauthorized access."
                        },
                        {
                            "name": "SubObs 2",
                            "description": "Recommendation to strengthen password complexity and expiration policies."
                        }
                    ],
                    "priority": "Medium",
                    "quarter": "Q1",
                    "status": {
                        "open": 0,
                        "closed": 1,
                        "overdue": 0,
                        "extended": 0,
                        "closedUnderMonitoring": 0
                    }
                }
            ]
        },
        {
            "auditName": "Operations",
            "auditYear": 2023,
            "total": 32,
            "open": 3,
            "closed": 25,
            "overdue": 4,
            "extended": 0,
            "closedUnderMonitoring": 0,
            "issues": [
                {
                    "name": "3.8",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "Delay in shipment of raw materials for manufacturing."
                        }
                    ],
                    "priority": "High",
                    "quarter": "Q1",
                    "status": {
                        "open": 1,
                        "closed": 0,
                        "overdue": 0,
                        "extended": 0,
                        "closedUnderMonitoring": 0
                    }
                }
            ]
        },
        {
            "auditName": "Sales & Marketing",
            "auditYear": 2023,
            "total": 28,
            "open": 4,
            "closed": 18,
            "overdue": 4,
            "extended": 0,
            "closedUnderMonitoring": 2,
            "issues": [
                {
                    "name": "2.1",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "Inaccurate tracking of sales leads."
                        }
                    ],
                    "priority": "Medium",
                    "quarter": "Q1",
                    "status": {
                        "open": 1,
                        "closed": 0,
                        "overdue": 0,
                        "extended": 0,
                        "closedUnderMonitoring": 0
                    }
                }
            ]
        }
    ]

    function calculateAuditStatus(data, auditName) {
        let statusCounts = {
            open: 0,
            closed: 0,
            overdue: 0,
            extended: 0,
            closedUnderMonitoring: 0
        };

        const audit = data.find(audit => audit.auditName === auditName); // Find the specific audit

        if (audit) {
            audit.issues.forEach(issue => {
                statusCounts.open += issue.status.open || 0;
                statusCounts.closed += issue.status.closed || 0;
                statusCounts.overdue += issue.status.overdue || 0;
                statusCounts.extended += issue.status.extended || 0;
                statusCounts.closedUnderMonitoring += issue.status.closedUnderMonitoring || 0;
            });
        }

        return statusCounts;
    }

    // Example usage:
    const auditName = "Human Resource & Admin"; // The audit you want to focus on
    const result = calculateAuditStatus(auditData, auditName);


    const filteredData = APIData.filter((data) => {

        const fullName = data.name.toLowerCase();
        const NameMatch = filters.name ? fullName.includes(filters.name.toLowerCase()) : true;
        const keyStakeholderName = "";
        const KeyStakeholder = filters.key_stakeholder ? keyStakeholderName.includes(filters.key_stakeholder.toLowerCase()) : true;

        const businessUnitMatch = filters.business_unit ? data.business_units.some(unit => {
            const unitNameLower = unit.name.toLowerCase();
            const filterUnitLower = filters.business_unit.toLowerCase();
            return unitNameLower.includes(filterUnitLower);
        }) : true;

        return NameMatch && KeyStakeholder && businessUnitMatch;
    });





    return (
        <>
            {errors && <p className="errormessage">{errors}</p>}

            {successMessage && <p className="successmessage">{successMessage}</p>}

            <section className="navroutes">
                <RenderIcons homeRoutes={issueTracking} />
                <NavRow name={'Audit Analytics'} Create={'Create'} idtrue={true} />


                <div className="analytics_container" style={{ width: tableSize ? "100%" : "100%" }} >

                    <div className="analytics_grid_container">

                        <div className="analytic_grid_item">

                            <h5> Issue's status of this Audit </h5>
                            <AllIssuePieChart
                                data={{
                                    total: result.open + result.closed + result.overdue + result.extended + result.closedUnderMonitoring,
                                    open: result.open,
                                    closed: result.closed,
                                    overdue: result.overdue,
                                    extended: result.extended,
                                    closedUnderMonitoring: result.closedUnderMonitoring,
                                }}
                            />

                        </div>

                        <div className="analytic_grid_item">


                            <h5> Issue's Priorities of Observations </h5>
                            <PriorityBarChart data={auditData} />

                        </div>


                        <div className="analytic_grid_item">
                            <h2 style={{ fontSize: "16px", fontFamily: "interbold", marginBottom: "16px" }}>Q1</h2>
                            <IssueBarChart data={issueData.filter(item => item.Quarter === "Q1")} />
                        </div>

                        <div className="analytic_grid_item">
                            <h2 style={{ fontSize: "16px", fontFamily: "interbold", marginBottom: "16px" }}>Q2</h2>
                            <IssueBarChart data={issueData.filter(item => item.Quarter === "Q2")} />
                        </div>

                        <div className="analytic_grid_item">
                            <h2 style={{ fontSize: "16px", fontFamily: "interbold", marginBottom: "16px" }}>Q3</h2>
                            <IssueBarChart data={issueData.filter(item => item.Quarter === "Q3")} />
                        </div>

                        <div className="analytic_grid_item">
                            <h2 style={{ fontSize: "16px", fontFamily: "interbold", marginBottom: "16px" }}>Q4</h2>
                            <IssueBarChart data={issueData.filter(item => item.Quarter === "Q4")} />
                        </div>

                    </div>

                </div>

            </section>
        </>
    );
};

export default AuditAnalytics;
