import React, { useMemo } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import SidebarMenu from "./SidebarMenu";
import "../App.css";
import SuperAvatar from "./SuperAvatar";


 

const RenderIcons = ({ homeRoutes, id }) => {

  const navigate = useNavigate();
  const avaUserName = localStorage.getItem("userName");


  const renderIcons = useMemo(() => {
    return homeRoutes.map((route, index) => {
      if (route.subRoutes) {
        return <SidebarMenu route={route} key={index} />;
      }

      return (

        <NavLink
          to={`${route.path.replace(":id", id)}`}
          key={index}
          className="navlink"
          activeclassname="active"
        >
          <div className="navicon">
            <img src={route.icon} alt={`${route.name} Icon`} />
          </div>
          <h5 className="h5_intersemibold">{route.name}</h5>
        </NavLink>

      );
    });
  }, [homeRoutes]);

  return (
    <>

      <div className="back_acc_bar"  >
        <svg onClick={ () => { navigate (-1) } } width="44" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 19.9201L8.47997 13.4001C7.70997 12.6301 7.70997 11.3701 8.47997 10.6001L15 4.08008" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

        <SuperAvatar firstName={avaUserName} classOfAvatar={"top_avatar"} />

      </div>

      <div className="navBars">
        {renderIcons}
      </div>
      
    </>
  );
};

export default RenderIcons;
