import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../../css/viewuser.css";
import { fetchDataV2, updateDataV2, fetchDataObjectV2, convertDate } from "../../apiUtils";
import { auditExecution,auditExecutionInnerBU,auditExecutionInnerQA } from "../../subroutes";
import "../auditplan/auditplan.css";
import "./auditreporting.css";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";
import openarrow from "../../../images/openarrow.svg";


const AuditReporting = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [areAllTasksDone, setAreAllTasksDone] = useState();

  const location = useLocation();
  const navigate = useNavigate();
  const successMessage = location.state?.successMessage;
  const { id } = useParams();
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [task, setTaskDetails] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [observationUrl, setObservationUrl] = useState("");
  const [APIaudit, setAudit] = useState([]);
  const [loading, setLoading] = useState(false);
  const is_Admin = localStorage.getItem("is_Admin");


  const statusMap = {
    InitialObservation: [
      "Initial Draft",
      "Closed",
      "Initial Draft Admin Approve",
    ],
    QAApprovalInitialObservation: [
      "Initial Draft Admin Approve",
      "Initial Draft Qa Approve",
    ],
    BusinessOwnerApproval: ["Initial Draft Qa Approve", "Approved Draft", "Initial Draft Business Approve"],
    FinalizeObservation: ["Approved Draft", "Finalized Draft"],
    qaAPprovalFinalizeObservation: [
      "Finalized Draft",
      "Finalized Draft Admin Approve",
      "Finalized Draft Qa Approve"
    ],
    AuditDirectorApproval: ["Finalized Draft Admin Approve"],
    GenerateReportList: ["Finalized Draft Qa Approve"]
  };

  const userRolesCheck = JSON.parse(localStorage.getItem("userRoles"));

  var routes = [];
  if (is_Admin || Object.keys(userRolesCheck).some(key => key.includes("Audit Team"))) {
    routes = auditExecution;
  } else if (Object.keys(userRolesCheck).some(key => key.includes("Business"))) {
    routes = auditExecutionInnerBU;
  }
  else if (Object.keys(userRolesCheck).some(key => key.includes("Quality Assurance"))) {
    console.log("i am here t")
    routes = auditExecutionInnerQA;
  }
  else {
    console.log("No matching role found");
  }

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/${id}/`,
      setterFunction: setAudit,
      setErrors: setErrors,
      setLoading: setLoading
    }
    fetchDataObjectV2(parameter);

  }, [id]);

  useEffect(() => {
    const url = window.location.href;
    const parts = url.split("/");
    const observationValue = parts[parts.length - 1];
    setObservationUrl(observationValue);
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/observation/audit/${id}/`,
      setterFunction: setAPIData,
      setErrors: setErrors
    }
    fetchDataV2(parameter);
  }, [id]);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/${id}/`,
      setterFunction: setAuditData,
      setErrors: setErrors
    }
    fetchDataV2(parameter);
  }, [id]);

  useEffect(() => {
    setAreAllTasksDone(APIData.every((task) => task?.task?.status === "done"));
  }, [APIData]);

  const [filters, setFilters] = useState({
    id: "",
    status: "",
    workpaper_id: "",
    summary: "",
    preparer: "",
    reviewer_name: "",
    created_at: "",
  });

  const handleFilterChange = (filterKey, e) => {
    setFilters({ ...filters, [filterKey]: e.target.value });
  };

  const filteredData = APIData.filter((data) => {
    const {
      id,
      summary,
      preparer,
      reviewer,
      created_at,
      task,
      workpapertestcase,
    } = data;

    const matchesId = filters.id
      ? (id || "").toString().includes(filters.id)
      : true;

    const matchesStatus = filters.status
      ? task && task.status.toLowerCase().includes(filters.status.toLowerCase())
      : true;

    const matchesWorkpaperId = filters.workpaper_id
      ? workpapertestcase.some((wpTestCase) =>
        wpTestCase.workpaper.id
          .toString()
          .includes(filters.workpaper_id.toString())
      )
      : true;

    const matchesSummary = filters.summary
      ? (summary || "").toLowerCase().includes(filters.summary.toLowerCase())
      : true;

    const matchesPreparer = filters.preparer
      ? preparer &&
      preparer.name.toLowerCase().includes(filters.preparer.toLowerCase())
      : true;

    const matchesReviewer = filters.reviewer
      ? reviewer &&
      reviewer.name.toLowerCase().includes(filters.reviewer.toLowerCase())
      : true;

    const matchesCreatedAt = filters.created_at
      ? (created_at || "").toString().includes(filters.created_at)
      : true;

    return (
      matchesId &&
      matchesStatus &&
      matchesSummary &&
      matchesWorkpaperId &&
      matchesPreparer &&
      matchesReviewer &&
      matchesCreatedAt
    );
  });

  const handlePopupClose = (task) => {
    const Parameter = {
      url: `/api/system/task/${task?.task?.id}`,
      payload: {
        title: task.objective,
        status: "done",
      },
      setErrors: setErrors,
      navigate: navigate,
      navUrl: `/AuditExecution/${id}/Reporting`
    };

    updateDataV2(Parameter);


    setAPIData((prevAPIData) =>
      prevAPIData.map((task) => {
        if (task.id === selectedTaskId) {
          return {
            ...task,
            task: {
              ...task.task,
              status: "Done",
            },
          };
        }
        return task;
      })
    );
    window.location.reload();

    setShowPopup(false);
  };

  const handlePopupCloseNo = () => {
    setShowPopup(false);
  };

  const viewObservation = (obejctionId, navigateTo) => {
    const navigateMap = {
      InitialObservation: `/AuditExecution/${id}/Reporting/InitialObservation/${obejctionId}`,
      QAApprovalInitialObservation: `/AuditExecution/${id}/Reporting/QAApprovalInitialObservation/${obejctionId}`,
      BusinessOwnerApproval: `/AuditExecution/${id}/Reporting/BusinessOwnerApproval/${obejctionId}`,
      FinalizeObservation: `/AuditExecution/${id}/Reporting/FinalizeObservation/${obejctionId}`,
      qaAPprovalFinalizeObservation: `/AuditExecution/${id}/Reporting/qaAPprovalFinalizeObservation/${obejctionId}`,
      AuditDirectorApproval: `/AuditExecution/${id}/Reporting/AuditDirectorApproval/${obejctionId}`,
      GenerateReportList: `/AuditExecution/${id}/Reporting/GenerateReportList/${obejctionId}`,
    };

    navigate(navigateMap[navigateTo] || "");
  };

  const name = auditData.name;
  const trueStatus = {
    createInitialObservation:
      auditData.report_status?.CREATE_INITIAL_OBSERVATION,
    approveInitialObservation:
      auditData.report_status?.APPROVE_INITIAL_OBSERVATION,
    businessApproval: auditData.report_status?.BUSINESS_APPROVAL,
    finalizedObservation: auditData.report_status?.FINALIZED_OBSERVATION,
    approveQAFinalApproval:
      auditData.report_status?.APPROVE_QA_FINAL_OBSERVATION,
    approveAdminFinalApproval:
      auditData.report_status?.APPROVE_ADMIN_FINAL_OBSERVATION,
    phase: auditData?.phase,
    setPercentage: setPercentage,
    observationUrl: observationUrl,
  };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}
      {showPopup && (
        <div className="popupATP">
          <div className="subpopupATP">
            <p>Do you want the task to be done?</p>
            <div className="subpopupBtnDiv">
              <button onClick={() => handlePopupClose(task)}>Yes</button>
              <button onClick={handlePopupCloseNo}>No</button>
            </div>
          </div>
        </div>
      )}

      <section className="navroutes">
        <RenderIcons homeRoutes={routes} id={id} />
        <NavRow
          name={name}
          parentName={APIaudit?.name}
          trueStatus={trueStatus}
          observationUrl={observationUrl}
          auditData={auditData}
          backToExecution={`/AuditExecution/${id}/Reporting`}
          id={id}
        />

        <div className="reportingTaskTableDiv" style={{ width: tableSize ? "79.5vw" : "90vw" }} >

          <table>
            <thead style={{ position: "sticky", top: "0", zIndex: 1000 }}>
              <tr className="tablehead">
                {[
                  "Status",
                  "UID",
                  "WorkPaperId",
                  "Title",
                  "Preparer",
                  "Reviewer",
                  "Certified On",
                ].map((header, index) => (
                  <th key={index}>
                    <h4 className="thhead">{header}</h4>
                    <input
                      type="text"
                      placeholder="--"
                      className="thfilter"
                      onChange={(e) =>
                        handleFilterChange(header.toLowerCase(), e)
                      }
                      value={filters[header.toLowerCase()]}
                    />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredData.filter((data) =>
                statusMap[observationUrl]?.includes(data.status)
              )
                .map((data) => (
                  <tr
                    key={data.id}
                    onClick={() => viewObservation(data.id, observationUrl)}
                  >
                    <td style={{ textAlign: "center" }}>{data.status}</td>
                    <td>{data.id}</td>
                    <td>
                      {[
                        ...new Set(
                          (data.workpapertestcase || []).map(
                            (wp) => wp.workpaper?.id
                          )
                        ),
                      ].map((uniqueId, index) => (
                        <span key={index}>{uniqueId + " "}</span>
                      ))}
                    </td>
                    <td>{data.summary}</td>
                    <td>{data.preparer?.name}</td>
                    <td>{data.reviewer?.name}</td>
                    <td>{new Date(data.created_at).toLocaleDateString()}</td>
                  </tr>
                ))}
            </tbody>
          </table>

        </div>

        <div className="cardscontainer">
          {filteredData
            .filter((data) => statusMap[observationUrl]?.includes(data.status)) // Apply the same filtering logic
            .map((data) => (
              <div className="deptcard"
                onClick={() => viewObservation(data.id, observationUrl)} // Same click handler as the table
                key={data.id}
              >
                <div className="bunstkhlder">

                  <h2>
                    <p className="buplacehldr">UID: {data.id}</p>
                  </h2>

                  <h5>
                    <p className="buplacehldr">
                      Preparer: {data.preparer?.name || "N/A"}
                    </p>
                  </h5>
                  
                </div>
                <div className="deptnauthnbtn">
                  <h6>
                    <p className="buplacehldr">Certified On: </p>
                    <p className="buplacehldr">
                      {data.created_at
                        ? new Date(data.created_at).toLocaleDateString()
                        : "N/A"}
                    </p>
                  </h6>

                </div>
                <img src={openarrow} alt="Open Arrow" />
              </div>
            ))}
        </div>


      </section>
    </>
  );
};

export default AuditReporting;
