import React, { useState, useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import "../../../css/viewuser.css";
import {
  fetchDataV2,
  updateDataV2,
  fetchDataObjectV2,
} from "../../apiUtils";
import { auditExecution } from "../../subroutes";
import "../auditplan/auditplan.css";
import "./auditreporting.css";
import heatmap from "../../../images/heatmapSVG.svg";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";
const GenerateReportMoreDetails = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [areAllTasksDone, setAreAllTasksDone] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const successMessage = location.state?.successMessage;
  const { id, obejctionId } = useParams();
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [task, setTaskDetails] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const [introduction, setIntroduction] = useState();
  const [introductionerror, setIntroductionError] = useState();
  const [audit_background, setBackground] = useState();
  const [audit_backgrounderror, setBackgroundError] = useState();
  const [final_opinion, setFinalOpinion] = useState();
  const [final_opinionerror, setFinalOpinionError] = useState();
  const [reportData, SetReportData] = useState([]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  console.log(obejctionId);
  useEffect(() => {
    const parameter ={
      url :`/api/audit_engagement/observation/${obejctionId}`,
      setterFunction : setAPIData,
      setErrors : setErrors,
      setLoading : setLoading
     }
    fetchDataObjectV2(parameter);

  }, []);

  useEffect(() => {
    const parameter ={
      url : `/api/audit_engagement/report/?audit_id=${id}`, 
      setterFunction :SetReportData,
      setErrors : setErrors
     }
    fetchDataV2(parameter,
      (data) => {
        console.log("Fetched files:", data.files); // Check data structure
        SetReportData(data.files || []);
      }
    );
  }, []);

  useEffect(() => {
    const parameter ={
      url :`/api/audit_engagement/observation/?audit_id=${id}`, 
      setterFunction :setAPIData,
      setErrors : setErrors
     }
    fetchDataV2(parameter);
  }, []);

  useEffect(() => {
    const parameter ={
      url :`/api/audit_engagement/audit/${id}/`, 
      setterFunction :setAPIData,
      setErrors : setErrors
     }
    fetchDataV2(parameter);
  }, [id]);

  useEffect(() => {
    setIntroduction(auditData?.introduction);
    setBackground(auditData?.audit_background);
    setFinalOpinion(auditData?.final_opinion);
  }, [auditData]);

  useEffect(() => {
    setAreAllTasksDone(APIData.every((task) => task?.task?.status === "done"));
  }, [APIData]);

  const [filters, setFilters] = useState({
    id: "",
    status: "",
    workpaper_id: "",
    summary: "",
    preparer: "",
    reviewer_name: "",
    created_at: "",
  });



  const updateMoreDetails = (e) => {
    if (!introduction || !audit_background || !final_opinion) {
      setIntroductionError(!introduction ? true : false);
      setBackgroundError(!audit_background ? true : false);
      setFinalOpinionError(!final_opinion ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    e.preventDefault();
    const Parameter = {
      url:   `/api/audit_engagement/audit/${id}`,
      payload: {
       risk_assessment: auditData?.risk_assessment?.id,
        audit_entity_id: auditData?.audit_entity?.id,
        auditors_id: auditData?.auditors.map((auditor) => auditor.id),
        business_users_id: auditData?.business_users.map(
          (business_users) => business_users.id
        ),
        actual_start_date: auditData?.actual_start_date,
        actual_end_date: auditData?.actual_end_date,
        planned_start_date: auditData?.audit_event?.planned_start_date,
        planned_end_date: auditData?.audit_event?.planned_end_date,
        audit_scope: auditData?.audit_scope,
        audit_phases: auditData?.audit_phases,
        other_relevant_information: auditData?.other_relevant_information,
        introduction,
        audit_background,
        final_opinion,
      },
      setErrors: setErrors,
    };

    updateDataV2(Parameter);
  
  };


  const handlePopupClose = (task) => {
    const Parameter = {
      url:  `/api/system/task/${task?.task?.id}`,
      payload: {
        title: task.objective,
        status: "done",
      },
      setErrors: setErrors,
      navigate: navigate,
      navUrl:   `/AuditExecution/${id}/Reporting`
    };

    updateDataV2(Parameter);


   
    setAPIData((prevAPIData) =>
      prevAPIData.map((task) => {
        if (task.id === selectedTaskId) {
          return {
            ...task,
            task: {
              ...task.task,
              status: "Done",
            },
          };
        }
        return task;
      })
    );
    window.location.reload();

    setShowPopup(false);
  };

  const handlePopupCloseNo = () => {
    setShowPopup(false);
  };




 
  const moreDetails = () => {
    console.log("i ahahahaha");
    navigate(`/AuditExecution/${id}/Reporting/GenerateReportList`);
  };

  const phaseTrue = {
    phase: auditData.phase,
  };
  const [APIaudit, setAudit] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const parameter ={
      url :  `/api/audit_engagement/audit/${id}/`,
      setterFunction : setAudit,
      setErrors : setErrors,
      setLoading : setLoading
     }
    fetchDataObjectV2(parameter);
  }, [id]);

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      {successMessage && <p className="successmessage">{successMessage}</p>}
      {showPopup && (
        <div className="popupATP">
          <div className="subpopupATP">
            <p>Do you want the task to be done?</p>
            <div className="subpopupBtnDiv">
              <button onClick={() => handlePopupClose(task)}>Yes</button>
              <button onClick={handlePopupCloseNo}>No</button>
            </div>
          </div>
        </div>
      )}
      <section className="navroutes">
        <RenderIcons homeRoutes={auditExecution} id={id} />
        <NavRow
          name={"More Details"}
          phaseTrue={phaseTrue}
          trueReport={() => moreDetails()}
          moreReport={true}
          parentName={APIaudit?.name}
        />

       

        <div
          className="moreDetailContainer"
          style={{ width: tableSize ? "86.5vw" : "90.5vw" }}
        >
          <form>
            <h2>More Details</h2>

            <div className="lbl_txa">
              <label htmlFor="">Introduction</label>
              <textarea
                style={{ border: introductionerror ? "1px solid #FF4646" : "" }}
                value={introduction}
                onChange={(e) => {
                  setIntroduction(e.target.value);
                  setIntroductionError(false);
                }}
                placeholder="Introduction"
              ></textarea>
            </div>
            <div className="lbl_txa">
              <label htmlFor="">Background</label>
              <textarea
                style={{
                  border: audit_backgrounderror ? "1px solid #FF4646" : "",
                }}
                value={audit_background}
                onChange={(e) => {
                  setBackground(e.target.value);
                  setBackgroundError(false);
                }}
                placeholder="background"
              ></textarea>
            </div>
            <div className="lbl_txa">
              <label htmlFor="">Final Opinion</label>
              <textarea
                style={{
                  border: final_opinionerror ? "1px solid #FF4646" : "",
                }}
                value={final_opinion}
                onChange={(e) => {
                  setFinalOpinion(e.target.value);
                  setFinalOpinionError(false);
                }}
                placeholder="final Opinion"
              ></textarea>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="heat_map_div">
                <img src={heatmap} />
              </div>
             
            </div>
            <div className="save-GRMD">
              <button 
              className="cmn_btn"
              style={{ backgroundColor: "#65CC5C" }}
              onClick={(e) => updateMoreDetails(e)}>save</button>
            </div>
          </form>
        </div>

       
      </section>
    </>
  );
};

export default GenerateReportMoreDetails;
