import React, { useState, useEffect, useMemo } from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import SidebarMenu from "../SidebarMenu";
import create from "../../images/createbutton.svg";
import "../../css/viewuser.css";
import Breadcrumb from "../Breadcrumb";
import MobAvatar from "./MobAvatar";
import { justFetchDataV2 } from "../apiUtils";
import { homeRoutes } from "../subroutes";
import RenderIcons from "../RenderIcons";
import BounceLoader from "react-spinners/BounceLoader";
import Button from "./Button";
import NavRow from "../NavRow";
const Users = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const successMessage = location.state?.successMessage;
  const [loading, setLoading] = useState(false); 

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [navigate]);

  useEffect(() => {
    const parameter ={
      url : `/api/system/users/`,
      setterFunction :(data) => {setAPIData(data)},
      setErrors : (error) => {setErrors(error)},
      setLoading: setLoading,
    }
    
    justFetchDataV2(parameter);
  }, []);


  const Viewuser = (data) => {
    const id = data.id;
    navigate(`/Administration/user/${id}/`);
  };

  const [filters, setFilters] = useState({
    first_name: "",
    department: "",
    designation: "",
    groups: "",
    business_unit: "",
  });

  const handleFilterChange = (filterKey, e) => {
    const value = e.target.value.toLowerCase();
    setFilters({ ...filters, [filterKey]: value });
  };

  const filteredData = useMemo(() => {
    return APIData.filter((data) => {
      const fullName = `${data.first_name} ${data.last_name}`.toLowerCase();
      const firstNameMatch = fullName.includes(filters.first_name);

      const departmentName = data.department
        ? data.department.name.toLowerCase()
        : "--";
      const departmentMatch = departmentName.includes(filters.department);

      const businessUnitName = data.business_unit
        ? data.business_unit.name.toLowerCase()
        : "--";
      const businessUnitMatch = businessUnitName.includes(filters.business_unit);

      const designationName = data.designation
        ? data.designation.toLowerCase()
        : "--";
      const designationMatch = designationName.includes(filters.designation);

      const groupMatch = data.groups.some((group) =>
        group.name.toLowerCase().includes(filters.groups)
      );

      return (
        firstNameMatch &&
        designationMatch &&
        departmentMatch &&
        businessUnitMatch &&
        groupMatch
      );
    });
  }, [APIData, filters]);


const navigateToCreate = ()=>{
  navigate(`/Administration/user/createUser`)
}



  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        <RenderIcons homeRoutes={homeRoutes} />
        <NavRow name={'Users'} Create ={'Create'} link={`/Administration/user/createUser`}  idtrue={true}/>

        {/* <div className="filter-container">
          <div className="filter">
            <div className="views">
              <h4 className="h3_interextrabold">Users</h4>
            </div>
            <div className="searchncbtn">
              <input
                type="text"
                placeholder="Search"
                onChange={(e) => handleFilterChange("first_name", e)}
                className="searchbar"
              />
              <div className="createuser">
                

                <Button buttonName="Create" buttonColor="#4CAF50" onClick={navigateToCreate} />
              </div>
            </div>
          </div>
        </div> */}
        <div className="tablecontainer">
          <table style={{ width: tableSize ? "80vw" : "90.5vw" }}>
            <thead style={{ position: "sticky", top: "0", zIndex: 1000 }}>
              <tr className="tablehead">
                <th>
                  <h4 className="thhead"> Name</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("first_name", e)}
                    value={filters.first_name}
                  />
                </th>
                <th>
                  <h4 className="thhead"> Department</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("department", e)}
                    value={filters.department}
                  />
                </th>
                <th>
                  <h4 className="thhead"> Designation</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("designation", e)}
                    value={filters.designation}
                  />
                </th>
                <th>
                  <h4 className="thhead"> Roles</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("groups", e)}
                    value={filters.groups}
                  />
                </th>
                <th>
                  <h4 className="thhead"> Business Unit</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("business_unit", e)}
                    value={filters.business_unit}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <div className="spinner-bounce-loader">
                  <BounceLoader color="#a6fafa" />
                </div>

              ) : filteredData.length === 0 ? (
                <p className="data-error-text">No data found</p>
              ) : (
                filteredData
                  .sort((a, b) => a?.last_name?.localeCompare(b?.last_name))
                  .sort((a, b) => a?.first_name?.localeCompare(b?.first_name))
                  .map((user) => (
                    <tr className="tabledata" onClick={() => Viewuser(user)} key={user.id}>
                      <td>
                        {user.first_name} {user.last_name}
                      </td>
                      <td>{user.department ? user.department.name : " "}</td>
                      <td>{user.designation}</td>
                      <td>
                        <div className="tagscontainer">
                          {user.groups
                            ? user.groups

                              .map((user) => user.name)
                              .slice(0, 3)
                              .join(", ") + (user.groups?.length > 3 ? "..." : "")

                            : ""}
                        </div>
                      </td>
                      <td>
                        <div className="tagscontainer">
                          <div className="tag">
                            {user.business_unit ? user.business_unit.name : "noBU"}
                          </div>
                        </div>
                      </td>

                    </tr>
                  ))


              )}
            </tbody>
          </table>
        </div>
        <div className="cardscontainer">
          {filteredData.map((data) => (
            <div className="usercard" onClick={() => Viewuser(data)} key={data.id}>
              <div className="crdtop">
                <MobAvatar
                  style={{ height: "6vh", width: "6vw" }}
                  firstName={data.first_name}
                />
                <h3>{data.first_name ? data.first_name : " "} {data.last_name ? data.last_name : " "}</h3>
              </div>
              <div className="crdbtm">
                <h4>{data.Name}</h4>
                <h5>{data.department ? data.department.name : " "}</h5>
                <h6>
                  {" "}
                  Emp-Id - <span>{data.employeed_id}</span>{" "}
                </h6>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Users;
