import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../../css/viewuser.css";
import "../rcm/listofrcap.css";
import OptionDropDown from "../../OptionDropDown";
import {
  postDataWithFile,
  fetchDataV2,
  justFetchDataV2,
  postDataWithFetchV2,
  updatewithFetchDataV2,
  deleteDataV2,
} from "../../apiUtils";
import { auditRoutes } from "../../subroutes";
import corporate from "../../../images/corporate.svg";
import star from "../../../images/star.svg";
import risk from "../../../images/risk.svg";
import auditPlan from "../../../images/auditPlan.svg";
import audit_icon from "../../../images/audit_icon.svg";
import planning_icon from "../../../images/planning_icon.svg";
import fieldwork_icon from "../../../images/fieldwork_icon.svg";
import reporting_icon from "../../../images/reporting_icon.svg";
import wrapupqa_icon from "../../../images/wrapup_icon.svg";
import axios from "axios";
import WindowComponent from "../../WindowComponent";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../RenderIcons";
import Button from "../../user/Button";
import NavRow from "../../NavRow";
const ListOfAllRcapPage = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [showDone, setShowDone] = useState(false);
  const [APIData, setAPIData] = useState([]);
  const [successMessageQuestion, setSuccessMessage] = useState(false);
  const [DepartmetData, setDepartmentData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const successMessage = location.state?.successMessage;
  const [uploadStatus, setUploadStatus] = useState("idle");
  const [badRequest, setBadRequest] = useState("");
  const [file, setFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const { id, planid, tempId, departmentId } = useParams();
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [editedRowData, setEditedRowData] = useState({});
  const [entityId, setEntityId] = useState("");
  const [businessUnitOptions, setBusinessUnitOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [control_existing, setExistingControl] = useState("");
  const [control_desired, setDesiredControl] = useState("");
  const [control_design, setControlDesign] = useState("");
  const [audit_procedure_data, setDataRequired] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [likelikhoodError, setLikelikhoodError] = useState(false);
  const [impactError, setImpactError] = useState(false);
  const [existingControlError, setExistingControlError] = useState(false);
  const [desiredControlError, setDesiredControlError] = useState(false);
  const [evaluationError, setEvaluationError] = useState(false);
  const [controlDesignError, setControlDesignError] = useState(false);
  const [controlTypeError, setcontrolTypeError] = useState(false);
  const [resLikelihoodError, setResLikelihoodError] = useState(false);
  const [resImpactError, setResImpactError] = useState(false);
  const [auditStepDetailsError, setAuditStepDetailsError] = useState(false);
  const [dataRequiredError, setDataRequiredError] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [objectiveError, setObjectiveError] = useState(false);
  const [riskIdError, setRiskIdError] = useState(false);
  const [riskCategoryError, setRiskCategoryError] = useState(false);
  const [describeRiskError, setDescribeRiskError] = useState(false);
  const [titleRiskError, setTitleRiskError] = useState(false);
  const segments = location.pathname.split("/");
  const AuditExecution = segments.find(
    (segment) => segment === "AuditExecution"
  );
  const [showNewRow, setShowNewRow] = useState(false);
  const [deptObject, setDeptObject] = useState({});
  const [businessUnitsId, setBusinessUnitsId] = useState([]);
  const [departmentNames, setDepartmentNames] = useState([]);
  const [departmentIds, setDepartmentIds] = useState({});
  const [planData, setPlanData] = useState([]);
  const [showApproves, setShowApproves] = useState(false);
  const user_id = localStorage.getItem("user_id");
  const [showSelectFileButton, setShowSelectFileButton] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [successMessageFileUpload, setSuccessMessageFileUpload] = useState(false);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
      setSuccessMessage(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [successMessageFileUpload]);

  function impactmapping(value) {
    switch (value) {
      case 1:
        return "Low (1)";
      case 2:
        return "Minor (2)";
      case 3:
        return "Moderate (3)";
      case 4:
        return "Major (4)";
      case 5:
        return "Severe (5)";
      default:
        return "";
    }
  }
  function likemapping(value) {
    switch (value) {
      case 1:
        return "Remote (1)";
      case 2:
        return "Unlikely (2)";
      case 3:
        return "Possible (3)";
      case 4:
        return "Likely (4)";
      case 5:
        return "Almost Certain (5)";
      default:
        return "";
    }
  }

  const Viewuser = (data) => {
    const raid = data.id;

    navigate(`/AuditUniverse/Rcm/${departmentId}/${raid}/RcapDetailPWA`);
    console.log(raid)
    console.log(data)
  };


  const closeEdit = (id) => {
    handleEditClick(false);
  };
  const closeCreate = () => {
    window.location.reload();
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (APIData.length > 0) {
      setShowDone(true);
    }
  }, [APIData]);

  const handleDelete = (rcapid) => {
    const parameter = {
      url: AuditExecution
        ? `/api/audit_engagement/workpaper/${rcapid}`
        : `/api/audit_universe/rcap/${rcapid}`,
      navigate: navigate,
      setErrors: setErrors,
      navUrl: AuditExecution
        ? `/AuditExecution/${id}/Planning/${planid}/RiskControlMatrix/`
        : `/AuditUniverse/Rcm/${departmentId}`
    }
    deleteDataV2(parameter);
  };

  const handleInputChange = (e, key) => {
    setEditedRowData({ ...editedRowData, [key]: e.target.value });
  };

  const handleDoneClick = (wid) => {
    if (
      !editedRowData.objective ||
      !editedRowData.risk_id ||
      !editedRowData.risk_title ||
      !editedRowData.inherent_assessment_likelihood ||
      !editedRowData.inherent_assessment_impact ||
      !editedRowData.residual_assessment_likelihood ||
      !editedRowData.residual_assessment_impact ||
      !editedRowData.audit_procedure_step
    ) {
      setObjectiveError(!editedRowData.objective ? true : false);
      setRiskIdError(!editedRowData.risk_id ? true : false);
      setTitleRiskError(!editedRowData.risk_title ? true : false);
      setLikelikhoodError(
        !editedRowData.inherent_assessment_likelihood ? true : false
      );
      setImpactError(!editedRowData.inherent_assessment_impact ? true : false);
      setResLikelihoodError(
        !newRow.residual_assessment_likelihood ? true : false
      );
      setResImpactError(
        !editedRowData.residual_assessment_impact ? true : false
      );
      setAuditStepDetailsError(
        !editedRowData.audit_procedure_step ? true : false
      );
      // setBusinessUnitIdError(!newRow.business_unit ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }



    const payload = AuditExecution
      ? {
        ...editedRowData,
        audit_id: id,
        rcm_flag: isChecked,
        business_unit_id: selectedBusiness ? selectedBusiness : editedRowData?.business_unit?.id,
      }
      : { ...editedRowData, audit_id: id, business_unit_id: selectedBusiness ? selectedBusiness : editedRowData?.business_unit?.id };

    // Update the APIData with the edited row data
    const updatedAPIData = APIData.map((data, index) => {
      if (index === editingRowIndex) {
        return payload;
      }
      return data;
    });
    setAPIData(updatedAPIData);

    // Determine the API endpoint based on AuditExecution
    const apiEndpoint = AuditExecution
      ? `/api/audit_engagement/workpaper/${payload.id}`
      : `/api/audit_universe/rcap/${payload.id}`;

    // Make an API call to update the data on the server

    const parameter = {
      url: apiEndpoint,
      payload,
      tofetch: {
        items: [
          {
            fetchurl: AuditExecution ? `/api/audit_engagement/workpaper/?audit_id=${id}` : `/api/audit_universe/rcap/?department_ids=${departmentId} `,
            dataset: AuditExecution ? setAPIData : setAPIData,
          },
        ],
        setErrors: setErrors,
      },
    };

    updatewithFetchDataV2(parameter);

    setEditingRowIndex(null);
    setEditedRowData({});
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFiles((prevSelectedFiles) => {
        if (!Array.isArray(prevSelectedFiles)) {
          console.error("selectedFiles is not an array", prevSelectedFiles);
          return [file];
        }
        return [...prevSelectedFiles, file];
      });
      setShowSelectFileButton(false);
    }
  };

  const handleFileUpload = (e) => {
    // if (file) {
    //   const formData = new FormData();
    //   formData.append("file", file);
    //   formData.append("department", departmentId);

    //   setUploadStatus("uploading");
    //   setUploadProgress(0);

    //   axios
    //     .post(
    //       `${process.env.REACT_APP_API_URL}/api/audit_universe/rcap-upload/`,
    //       formData,
    //       {
    //         headers: {
    //           "Content-Type": "multipart/form-data",
    //         },
    //         onUploadProgress: (progressEvent) => {
    //           const percentCompleted = Math.round(
    //             (progressEvent.loaded * 100) / progressEvent.total
    //           );
    //           setUploadProgress(percentCompleted);
    //         },
    //       }
    //     )
    //     .then((response) => {
    //       setUploadStatus("success");
    //       window.location.reload(); // Reload the page on successful upload
    //     })
    //     .catch((error) => {
    //       // console.error("Error uploading file:", error);
    //       setBadRequest(error?.response?.data?.error);
    //       setUploadStatus("error");
    //     });
    // } else {
    //   console.error("No file selected");
    // }

    if (selectedFiles.length === 0) {
      setErrors("Please select a file to upload.");
      return;
    }

    const parameter = {
      url: "/api/audit_universe/rcap-upload/",
      files: selectedFiles,
      data: {
        department: departmentId,
      },
      setErrors: setErrors,
      setSuccessMessage: setSuccessMessage,
    };

    postDataWithFile(parameter);
    setUploadStatus("success");
    setSelectedFiles(null); // Reset properly
    setShowSelectFileButton(true);
  };

  const handleEditClick = (index, data) => {
    setEditingRowIndex(index);
    setEditedRowData({ ...data });
  };

  const handleFilterChange = (filterKey, e) => {
    if (filterKey === "business_unit") {
      setFilters({
        ...filters,
        business_unit: { name: e.target.value },
      });
    } else {
      setFilters({ ...filters, [filterKey]: e.target.value });
    }
  };

  const [buData, setBuData] = useState({});
  useEffect(() => {
    if (DepartmetData) {
      setBusinessUnitOptions(
        DepartmetData.business_units ? DepartmetData.business_units : []
      );
      setBuData();
    }
  }, [DepartmetData]);

  const [audit, setAudit] = useState([]);

  useEffect(() => {
    if (id) {
      const parameter = {
        url: `/api/audit_engagement/audit/${id}/`,
        setterFunction: setAudit,
        setErrors: setErrors,
      }
      fetchDataV2(parameter);
    }
  }, [id]);

  const [auditEntity, setAuditEntity] = useState([]);
  useEffect(() => {
    if (AuditExecution) {
      const parameter = {
        url: `/api/audit_engagement/workpaper/?audit_id=${id}`,
        setterFunction: setAPIData,
        setErrors: setErrors,
      }
      fetchDataV2(parameter);
    }
  }, [AuditExecution, id, setAPIData, setErrors]);

  useEffect(() => {
    if (audit.audit_entity) {
      const parameter = {
        url: `/api/audit_universe/audit-entities/${audit?.audit_entity?.id}/`,
        setterFunction: setAuditEntity,
        setErrors: setErrors,
      }
      fetchDataV2(parameter);
    }
  }, [audit]);

  useEffect(() => {
    if (auditEntity.audit_unit) {
      const unitsId = auditEntity.audit_unit.map(
        (unit) => unit.business_units_id
      );
      const deptNames = auditEntity.audit_unit.map(
        (unit) => unit.department_name
      );
      setBusinessUnitsId(unitsId);
      setDepartmentNames(deptNames);
    }
  }, [auditEntity]);

  useEffect(() => {
    const parameter = {
      url: `/api/system/departments/`,
      setterFunction: (allDepartments) => {
        var _deptObject = {};
        allDepartments.map((u) => {
          _deptObject[u.name] = u.id;
        });
        setDeptObject(_deptObject);
      },
      setErrors: setErrors,
    }
    fetchDataV2(parameter);
  }, []);

  useEffect(() => {
    console.log(deptObject);
    console.log(businessUnitsId);
    console.log(departmentNames);
    setDepartmentIds(
      departmentNames.map((department) => deptObject[department])
    );
  }, [deptObject]);

  useEffect(() => {
    setDepartmentIds(
      departmentNames.map((department) => deptObject[department])
    );
  }, [departmentNames]);

  const removeDuplicates = (arr) => {
    return Array.isArray(arr) ? [...new Set(arr)] : [];
  };
  useEffect(() => {
    const departmentsString = departmentIds
      ? removeDuplicates(departmentIds).toString()
      : "";
    const businessUnitsString = businessUnitsId
      ? businessUnitsId.toString()
      : "";
    if (departmentId) {
      const parameter2 = {
        url: `/api/audit_universe/rcap/?department_ids=${departmentId}`,
        setterFunction: (data) => { setAPIData(data) },
        setErrors: (error) => { setErrors(error) },
        setLoading: setLoading,
      }
      justFetchDataV2(parameter2);

      const parameter = {
        url: `/api/system/departments/${departmentId}/`,
        setterFunction: setDepartmentData,
        setErrors: setErrors,
      }
      fetchDataV2(parameter);
    }
    console.log(DepartmetData);
    console.log(APIData);

    if (
      !departmentId &&
      !businessUnitsString &&
      !departmentsString &&
      !AuditExecution
    ) {
      const parameter3 = {
        url: `/api/audit_universe/rcap/`,
        setterFunction: (data) => { setAPIData(data) },
        setErrors: (error) => { setErrors(error) },
        setLoading: setLoading,
      }
      justFetchDataV2(parameter3);
    }
  }, [departmentIds, businessUnitsId, departmentId, AuditExecution]);

  useEffect(() => {
    if (planData.reviewer) {
      const reviewerId = planData.reviewer ? planData.reviewer.user_id : "";
      if (reviewerId === user_id) {
        setShowApproves(true);

        console.log(showApproves, "showApproves");
      }
    }
  }, [planData]);

  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/plan/${planid}/`,
      setterFunction: setPlanData,
      setErrors: setErrors,
    }
    fetchDataV2(parameter);
  }, [planid]);

  const [filters, setFilters] = useState({
    business_unit: "",
    process: "",
    sub_process: "",
    risk_id: "",
    objective: "",
    risk_title: "",
    risk_category: "",
    risk_description: "",
    inherent_assessment_likelihood: "",
    inherent_assessment_impact: "",
    control_existing: "",
    control_desired: "",
    control_evaluation: "",
    control_design: "",
    control_type: "",
    control_frequency: "",
    control_gap: "",
    residual_assessment_likelihood: "",
    residual_assessment_impact: "",
  });

  const filteredData = APIData.filter((data) => {
    return Object.keys(filters).every((key) => {
      if (!filters[key]) return true;
      if (key === "business_unit") {
        return data.business_unit?.name
          .toLowerCase()
          .includes(filters.business_unit.name.toLowerCase());
      }
      return data[key]
        ?.toString()
        .toLowerCase()
        .includes(filters[key].toLowerCase());
    });
  });

  const [newRow, setNewRow] = useState({
    process: "",
    sub_process: "",
    risk_id: "",
    objective: "",
    risk_title: "",
    risk_category: "",
    risk_description: "",
    inherent_assessment_likelihood: "",
    inherent_assessment_impact: "",
    control_existing: "",
    control_desired: "",
    control_evaluation: "",
    control_design: "",
    control_type: "",
    control_frequency: "",
    control_gap: "",
    residual_assessment_likelihood: "",
    residual_assessment_impact: "",
    audit_procedure_step: "",
    audit_procedure_data: "",
  });

  const handleCreateRow = () => {
    setShowNewRow(true);
    setNewRow({
      process: "",
      sub_process: "",
      risk_id: "",
      objective: "",
      risk_title: "",
      risk_category: "",
      risk_description: "",
      inherent_assessment_likelihood: "",
      inherent_assessment_impact: "",
      control_existing: "",
      control_desired: "",
      control_evaluation: "",
      control_design: "",
      control_type: "",
      control_frequency: "",
      control_gap: "",
      residual_assessment_likelihood: "",
      residual_assessment_impact: "",
      audit_procedure_step: "",
      audit_procedure_data: "",
    });
  };

  const addRCap = (e) => {
    if (
      !newRow.objective ||
      !newRow.risk_id ||
      !newRow.risk_title ||
      !newRow.inherent_assessment_likelihood ||
      !newRow.inherent_assessment_impact ||
      !newRow.residual_assessment_likelihood ||
      !newRow.residual_assessment_impact ||
      !newRow.audit_procedure_step
    ) {
      setObjectiveError(!newRow.objective ? true : false);
      setRiskIdError(!newRow.risk_id ? true : false);
      setTitleRiskError(!newRow.risk_title ? true : false);
      setLikelikhoodError(
        !newRow.inherent_assessment_likelihood ? true : false
      );
      setImpactError(!newRow.inherent_assessment_impact ? true : false);
      setResLikelihoodError(
        !newRow.residual_assessment_likelihood ? true : false
      );
      setResImpactError(!newRow.residual_assessment_impact ? true : false);
      setAuditStepDetailsError(!newRow.audit_procedure_step ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    const payload1 = {
      business_unit_id: selectedBusiness,
      department_id: departmentId,
      process: newRow.process,
      sub_process: newRow.sub_process,
      objective: newRow.objective,
      risk_id: newRow.risk_id,
      risk_category: newRow.risk_category,
      risk_description: newRow.risk_description,
      risk_title: newRow.risk_title,
      inherent_assessment_likelihood: parseInt(
        newRow.inherent_assessment_likelihood
      ),
      inherent_assessment_impact: parseInt(newRow.inherent_assessment_impact),
      control_existing: newRow.control_existing,
      control_desired: newRow.control_desired,
      control_evaluation: newRow.control_evaluation,
      control_design: newRow.control_design,
      control_type: newRow.control_type,
      control_frequency: newRow.control_frequency,
      control_gap: newRow.control_gap,
      residual_assessment_likelihood: parseInt(
        newRow.residual_assessment_likelihood
      ),
      residual_assessment_impact: parseInt(newRow.residual_assessment_impact),
      audit_procedure_step: newRow.audit_procedure_step,
      audit_procedure_data: newRow.audit_procedure_data,
      audit_entity: entityId ? entityId : id,
      rcm_template_id: tempId,
    };

    const parameter = {
      url: "/api/audit_universe/rcap/",
      payload: {
        business_unit_id: selectedBusiness,
        department_id: departmentId,
        process: newRow.process,
        sub_process: newRow.sub_process,
        objective: newRow.objective,
        risk_id: newRow.risk_id,
        risk_category: newRow.risk_category,
        risk_description: newRow.risk_description,
        risk_title: newRow.risk_title,
        inherent_assessment_likelihood: parseInt(
          newRow.inherent_assessment_likelihood
        ),
        inherent_assessment_impact: parseInt(newRow.inherent_assessment_impact),
        control_existing: newRow.control_existing,
        control_desired: newRow.control_desired,
        control_evaluation: newRow.control_evaluation,
        control_design: newRow.control_design,
        control_type: newRow.control_type,
        control_frequency: newRow.control_frequency,
        control_gap: newRow.control_gap,
        residual_assessment_likelihood: parseInt(
          newRow.residual_assessment_likelihood
        ),
        residual_assessment_impact: parseInt(newRow.residual_assessment_impact),
        audit_procedure_step: newRow.audit_procedure_step,
        audit_procedure_data: newRow.audit_procedure_data,
        audit_entity: entityId ? entityId : id,
        rcm_template_id: tempId,
      },
      tofetch: {
        items: [
          {
            fetchurl: `/api/audit_universe/rcap/?department_ids=${departmentId}`,
            dataset: setAPIData,
          },
        ],
        setSuccessMessage: setSuccessMessage,
        setErrors: setErrors,
      },
    };

    postDataWithFetchV2(parameter);
    setNewRow("");
    setShowNewRow(false);
  };

  const Category = [
    { label: " Financial", value: "Financial" },
    { label: "Operational", value: "Operational" },
    { label: "Strategic", value: "Strategic" },
    { label: "Compliance", value: "Compliance" },
    { label: "Reputational", value: "Reputational" },
  ];
  const inherentAssessmentImpactOptions = [
    { label: "Insignificant (1)", value: 1 },
    { label: "Minor (2)", value: 2 },
    { label: "Moderate (3)", value: 3 },
    { label: "Major (4)", value: 4 },
    { label: "Severe (5)", value: 5 },
  ];

  const inherentAssessmentLikeOptions = [
    { label: "Remote (1)", value: 1 },
    { label: "Unlikely (2)", value: 2 },
    { label: "Possible (3)", value: 3 },
    { label: "Likely (4)", value: 4 },
    { label: "Almost Certain (5)", value: 5 },
  ];

  const residualAssessmentImpactOptions = [
    { label: "Insignificant (1)", value: 1 },
    { label: "Minor (2)", value: 2 },
    { label: "Moderate (3)", value: 3 },
    { label: "Major (4)", value: 4 },
    { label: "Severe (5)", value: 5 },
  ];

  const residualAssessmentLikeOptions = [
    { label: "Remote (1)", value: 1 },
    { label: "Unlikely (2)", value: 2 },
    { label: "Possible (3)", value: 3 },
    { label: "Likely (4)", value: 4 },
    { label: "Almost Certain (5)", value: 5 },
  ];

  const controlEvaluationOptions = [
    { label: "Effective", value: "Effective" },
    { label: "Ineffective", value: "Ineffective" },
    { label: "Needs Improvement", value: "Needs Improvement" },
  ];
  const controlDesignOptions = [
    { label: "Manual", value: "Manual" },
    { label: "Automated", value: "Automated" },
  ];
  const controlTypeOptions = [
    { label: "Preventive", value: "Preventive" },
    { label: "Detective", value: "Detective" },
    { label: "Corrective", value: "Corrective" },
  ];

  const handleChangea = (event) => {
    if (AuditExecution) {
      const user = businessUnitOptions.find(
        (user) => user?.business_units_id === Number(event.target.value)
      );
      setSelectedBusiness(user?.business_units_id);
    } else {
      const user = businessUnitOptions.find(
        (user) => user?.id === Number(event.target.value)
      );
      setSelectedBusiness(user?.id);
    }
  };
  console.log(selectedBusiness);

  useEffect(() => {
    if (filteredData) {
      const buIds = filteredData.map((data) =>
        data.business_unit ? data.business_unit.id : ""
      );
      setBuData(buIds);
    }
  }, []);

  const rcapUpload = {
    handleFileUpload: handleFileUpload,
    setAPIData: setAPIData,
    setUploadStatus: setUploadStatus,
    setUploadProgress: setUploadProgress,
    setFile: setFile,
    departmentId: departmentId,
    AuditExecution: AuditExecution,
    selectedFiles:selectedFiles,
    setSelectedFiles:setSelectedFiles,
    showSelectFileButton:showSelectFileButton,
    setShowSelectFileButton:setShowSelectFileButton,
    handleFileSelect:handleFileSelect,
    successMessageFileUpload:successMessageFileUpload,
    postDataWithFile:postDataWithFile
  };

  useEffect(() => {
    if (auditEntity?.audit_unit) {
      setBusinessUnitOptions(auditEntity?.audit_unit);
    }
  }, [auditEntity]);

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}
      {successMessageQuestion && (
        <p className="successmessage">Question Added SuccessFully</p>
      )}
      <section className="navroutes">
        <RenderIcons homeRoutes={auditRoutes} />
        <NavRow
          name={`${DepartmetData?.name ? DepartmetData?.name : "ALL"}`}
          parentName={audit?.name}
          Create={"Create"}
          link={`/Administration/user/createUser`}
          trueback={true}
          RcmCreate={() => handleCreateRow()}
          rcapUpload={rcapUpload}
          secondName={"Planning"}
        />

        <div className="rcmt_table"
          style={{ width: tableSize ? "86.5vw" : "92.5vw" }}
        >
          <table>
            <thead style={{ position: "sticky", top: "0", zIndex: 0 }}>
              <tr>
                {editingRowIndex !== null && AuditExecution && (
                  <th colSpan={1} style={{ textAlign: "center" }}>
                    <h4 className="thhead"> </h4>
                  </th>
                )}

                <th colSpan={1} style={{ textAlign: "center" }}>
                  <h4 className="thhead"> </h4>
                </th>

                <th
                  colSpan={3}
                  style={{
                    textAlign: "center",
                    backgroundColor: "var(--rcm_bluelight)",
                  }}
                >
                  <h4 className="thhead"> Business Control </h4>
                </th>

                <th
                  colSpan={5}
                  style={{
                    textAlign: "center",
                    backgroundColor: "var(--rcm_greendark)",
                  }}
                >
                  <h4 className="thhead"> Risk Description </h4>
                </th>

                <th
                  colSpan={4}
                  style={{
                    textAlign: "center",
                    backgroundColor: "var(--rcm_red)",
                  }}
                >
                  <h4 className="thhead"> Inherent Assessment </h4>
                </th>

                <th
                  colSpan={5}
                  style={{
                    textAlign: "center",
                    backgroundColor: "var(--rcm_greenlight)",
                  }}
                >
                  <h4 className="thhead"> Control Statement </h4>
                </th>

                <th
                  colSpan={4}
                  style={{
                    textAlign: "center",
                    backgroundColor: "var(--rcm_yellow)",
                  }}
                >
                  <h4 className="thhead"> Residual Assessment </h4>
                </th>

                <th
                  colSpan={2}
                  style={{
                    textAlign: "center",
                    backgroundColor: "var(--rcm_bluedark)",
                  }}
                >
                  <h4 className="thhead"> Audit Procedure </h4>
                </th>
              </tr>
            </thead>

            <thead style={{ position: "sticky", top: "0", zIndex: 50 }}>
              <tr>
                <th>
                  <h4>
                    <h4 className="thhead">Action</h4>
                  </h4>
                </th>
                {editingRowIndex !== null && AuditExecution && (
                  <th>
                    <h4 className="thhead">Also Update in RCM</h4>
                    <input type="text" placeholder="--" className="thfilter" />
                  </th>
                )}
                <th style={{ backgroundColor: "var(--rcm_bluelight)" }}>
                  <h4 className="thhead">Business Unit</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("business_unit", e)}
                    value={filters.business_unit.name}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_bluelight)" }}>
                  <h4 className="thhead">Process</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("process", e)}
                    value={filters.process}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_bluelight)" }}>
                  <h4 className="thhead">Sub Process</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("sub_process", e)}
                    value={filters.sub_process}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greendark)" }}>
                  <h4 className="thhead">ID</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("risk_id", e)}
                    value={filters.risk_id}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greendark)" }}>
                  <h4 className="thhead">Objective</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("objective", e)}
                    value={filters.objective}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greendark)" }}>
                  <h4 className="thhead">Title</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("risk_title", e)}
                    value={filters.risk_title}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greendark)" }}>
                  <h4 className="thhead">Category</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("risk_category", e)}
                    value={filters.risk_category}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greendark)" }}>
                  <h4 className="thhead">Statement</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("risk_description", e)}
                    value={filters.risk_description}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_red)" }}>
                  <h4 className="thhead">Likelihood</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("inherent_assessment_likelihood", e)
                    }
                    value={filters.inherent_assessment_likelihood}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_red)" }}>
                  <h4 className="thhead">Impact</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("inherent_assessment_impact", e)
                    }
                    value={filters.inherent_assessment_impact}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_red)" }}>
                  <h4 className="thhead">Risk Evaluation Score</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("risk_inherant_evaluation_score", e)
                    }
                    value={filters.risk_inherant_evaluation_score}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_red)" }}>
                  <h4 className="thhead">Risk Evaluation</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("risk_inherant_evaluation", e)
                    }
                    value={filters.risk_inherant_evaluation}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greenlight)" }}>
                  <h4 className="thhead">Control Statement</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("control_existing", e)}
                    value={filters.control_existing}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greenlight)" }}>
                  <h4 className="thhead">
                    Best Practices for Reference (Desired Control){" "}
                  </h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("control_desired", e)}
                    value={filters.control_desired}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greenlight)" }}>
                  <h4 className="thhead">Evaluation</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("control_evaluation", e)
                    }
                    value={filters.control_evaluation}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greenlight)" }}>
                  <h4 className="thhead">Design</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("control_design", e)}
                    value={filters.control_design}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_greenlight)" }}>
                  <h4 className="thhead">Type</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("control_type", e)}
                    value={filters.control_type}
                  />
                </th>

                <th style={{ backgroundColor: "var(--rcm_yellow)" }}>
                  <h4 className="thhead">Likelihood</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("residual_assessment_likelihood", e)
                    }
                    value={filters.residual_assessment_likelihood}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_yellow)" }}>
                  <h4 className="thhead">Impact</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("residual_assessment_impact", e)
                    }
                    value={filters.residual_assessment_impact}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_yellow)" }}>
                  <h4 className="thhead">Residual Evaluation Score</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("risk_residual_evaluation_score", e)
                    }
                    value={filters.risk_residual_evaluation_score}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_yellow)" }}>
                  <h4 className="thhead">Residual Evaluation</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("risk_residual_evaluation", e)
                    }
                    value={filters.risk_residual_evaluation}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_bluedark)" }}>
                  <h4 className="thhead">Step Details</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("audit_procedure_step", e)
                    }
                    value={filters.audit_procedure_step}
                  />
                </th>
                <th style={{ backgroundColor: "var(--rcm_bluedark)" }}>
                  <h4 className="thhead"> Data Required</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("audit_procedure_data", e)
                    }
                    value={filters.audit_procedure_data}
                  />
                </th>
              </tr>
            </thead>

            <tbody>
              <tr style={{ display: showNewRow ? "" : "none" }}>
                <td>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "1vw",
                    }}
                  >
                    <Button
                      buttonName="Save"
                      buttonColor="#4CAF50"
                      onClick={() => addRCap()}
                    />

                    <Button
                      buttonName="Close"
                      buttonColor="#4CAF50"
                      onClick={closeCreate}
                    />
                  </div>
                </td>

                <td>
                  <div>
                    <select id="user-dropdown" onChange={handleChangea}>
                      <option value="">--Select Business Unit--</option>
                      {businessUnitOptions.map((user) => (
                        <option key={user.id} value={user.id}>
                          {user.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </td>

                <td>
                  <textarea
                    type="text"
                    placeholder="Process"
                    style={{
                      width: tableSize ? "12vw" : "15vw",
                    }}
                    value={newRow.process}
                    onChange={(e) => {
                      setNewRow({ ...newRow, process: e.target.value });
                    }}
                  />
                </td>

                <td>
                  <textarea
                    type="text"
                    placeholder="SubProcess"
                    style={{
                      width: tableSize ? "12vw" : "15vw",
                    }}
                    value={newRow.sub_process}
                    onChange={(e) => {
                      setNewRow({ ...newRow, sub_process: e.target.value });
                    }}
                  />
                </td>

                <td>
                  <textarea
                    type="text"
                    placeholder="NG57FRE"
                    style={{
                      width: tableSize ? "19vw" : "22vw",
                      border: riskIdError ? "1px solid #FF4646" : "",
                    }}
                    value={newRow.risk_id}
                    onChange={(e) => {
                      setNewRow({ ...newRow, risk_id: e.target.value });
                      setRiskIdError(false);
                    }}
                  />
                </td>

                <td>
                  <textarea
                    type="text"
                    placeholder="Unauthorized Access to Financial Data"
                    style={{
                      width: tableSize ? "50vw" : "60vw",
                      border: objectiveError ? "1px solid #FF4646" : "",
                    }}
                    value={newRow.objective}
                    onChange={(e) => {
                      setNewRow({ ...newRow, objective: e.target.value });
                      setObjectiveError(false);
                    }}
                  />
                </td>

                <td>
                  <textarea
                    type="text"
                    placeholder="Unauthorized Access to Financial Data"
                    style={{
                      width: tableSize ? "50vw" : "60vw",
                      border: titleRiskError ? "1px solid #FF4646" : "",
                    }}
                    value={newRow.risk_title}
                    onChange={(e) => {
                      setNewRow({ ...newRow, risk_title: e.target.value });
                      setTitleRiskError(false);
                    }}
                  />
                </td>

                <td>
                  <OptionDropDown
                    tableSize={tableSize}
                    inputType="text"
                    inputPlaceholder="Financial"
                    inputStyle={{
                      width: tableSize ? "19vw" : "22vw",
                      border: riskCategoryError ? "1px solid #FF4646" : "",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    inputValue={newRow.risk_category}
                    onInputChange={(e) => {
                      setNewRow({ ...newRow, risk_category: e.target.value });
                      setRiskCategoryError(false);
                    }}
                    options={Category}
                  />
                </td>

                <td>
                  <textarea
                    type="text"
                    placeholder="Describe Risk"
                    style={{
                      width: tableSize ? "35vw" : "38vw",
                      border: describeRiskError ? "1px solid #FF4646" : "",
                    }}
                    value={newRow.risk_description}
                    onChange={(e) => {
                      setNewRow({
                        ...newRow,
                        risk_description: e.target.value,
                      });
                    }}
                  />
                </td>

                <td>
                  <OptionDropDown
                    tableSize={tableSize}
                    inputType="text"
                    inputPlaceholder="NG57FRE"
                    inputStyle={{
                      width: tableSize ? "19vw" : "22vw",
                      border: likelikhoodError ? "1px solid #FF4646" : "",
                    }}
                    inputValue={newRow.inherent_assessment_likelihood}
                    onInputChange={(e) => {
                      setNewRow({
                        ...newRow,
                        inherent_assessment_likelihood: e.target.value,
                      });
                      setLikelikhoodError(false);
                    }}
                    options={inherentAssessmentLikeOptions}
                  />
                </td>

                <td>
                  <OptionDropDown
                    tableSize={tableSize}
                    inputType="text"
                    inputPlaceholder="NG57FRE"
                    inputStyle={{
                      width: tableSize ? "19vw" : "22vw",
                      border: impactError ? "1px solid #FF4646" : "",
                    }}
                    inputValue={newRow.inherent_assessment_impact}
                    onInputChange={(e) => {
                      setNewRow({
                        ...newRow,
                        inherent_assessment_impact: e.target.value,
                      });
                      setImpactError(false);
                    }}
                    options={inherentAssessmentImpactOptions}
                  />
                </td>
                <td></td>
                <td></td>

                <td>
                  <textarea
                    type="text"
                    placeholder="NG57FRE"
                    style={{
                      width: tableSize ? "50vw" : "85vw",
                      border: existingControlError ? "1px solid #FF4646" : "",
                    }}
                    value={newRow.control_existing}
                    onChange={(e) => {
                      setNewRow({
                        ...newRow,
                        control_existing: e.target.value,
                      });
                      setExistingControl(e.target.value);
                    }}
                  />
                </td>

                <td>
                  <textarea
                    type="text"
                    placeholder="NG57FRE"
                    style={{
                      width: tableSize ? "50vw" : "85vw",
                      border: desiredControlError ? "1px solid #FF4646" : "",
                    }}
                    value={newRow.control_desired}
                    onChange={(e) => {
                      setNewRow({
                        ...newRow,
                        control_desired: e.target.value,
                      });
                      setDesiredControl(e.target.value);
                    }}
                  />
                </td>

                <td>
                  <OptionDropDown
                    tableSize={tableSize}
                    inputType="text"
                    inputPlaceholder="NG57FRE"
                    inputStyle={{
                      width: tableSize ? "12vw" : "15vw",
                      border: evaluationError ? "1px solid #FF4646" : "",
                    }}
                    inputValue={newRow.control_evaluation}
                    onInputChange={(e) => {
                      setNewRow({
                        ...newRow,
                        control_evaluation: e.target.value,
                      });
                    }}
                    options={controlEvaluationOptions}
                  />
                </td>

                <td>
                  <OptionDropDown
                    tableSize={tableSize}
                    inputType="text"
                    inputPlaceholder="NG57FRE"
                    inputStyle={{
                      width: tableSize ? "12vw" : "15vw",
                      border: controlDesignError ? "1px solid #FF4646" : "",
                    }}
                    inputValue={control_design}
                    onInputChange={(e) => {
                      setControlDesign(e.target.value);
                    }}
                    options={controlDesignOptions}
                  />
                </td>

                <td>
                  <OptionDropDown
                    tableSize={tableSize}
                    inputType="text"
                    inputPlaceholder="NG57FRE"
                    inputStyle={{
                      width: tableSize ? "12vw" : "15vw",
                      border: controlTypeError ? "1px solid #FF4646" : "",
                    }}
                    inputValue={newRow.control_type}
                    onInputChange={(e) => {
                      setNewRow({ ...newRow, control_type: e.target.value });
                    }}
                    options={controlTypeOptions}
                  />
                </td>

                <td>
                  <OptionDropDown
                    tableSize={tableSize}
                    inputType="text"
                    inputPlaceholder="NG57FRE"
                    inputStyle={{
                      width: tableSize ? "12vw" : "15vw",
                      border: resLikelihoodError ? "1px solid #FF4646" : "",
                    }}
                    inputValue={newRow.residual_assessment_likelihood}
                    onInputChange={(e) => {
                      setNewRow({
                        ...newRow,
                        residual_assessment_likelihood: e.target.value,
                      });
                      setResLikelihoodError(false);
                    }}
                    options={residualAssessmentLikeOptions}
                  />
                </td>

                <td>
                  <OptionDropDown
                    tableSize={tableSize}
                    inputType="text"
                    inputPlaceholder="NG57FRE"
                    inputStyle={{
                      width: tableSize ? "12vw" : "15vw",
                      border: resImpactError ? "1px solid #FF4646" : "",
                    }}
                    inputValue={newRow.residual_assessment_impact}
                    onInputChange={(e) => {
                      setNewRow({
                        ...newRow,
                        residual_assessment_impact: e.target.value,
                      });
                      setResImpactError(false);
                    }}
                    options={residualAssessmentImpactOptions}
                  />
                </td>
                <td></td>
                <td></td>
                <td>
                  <textarea
                    type="text"
                    placeholder="NG57FRE"
                    style={{
                      width: tableSize ? "50vw" : "60vw",
                      border: auditStepDetailsError ? "1px solid #FF4646" : "",
                    }}
                    value={newRow.audit_procedure_step}
                    onChange={(e) => {
                      setNewRow({
                        ...newRow,
                        audit_procedure_step: e.target.value,
                      });
                      setAuditStepDetailsError(false);
                    }}
                  />
                </td>

                <td>
                  <textarea
                    type="text"
                    placeholder="NG57FRE"
                    style={{
                      width: tableSize ? "50vw" : "60vw",
                      border: dataRequiredError ? "1px solid #FF4646" : "",
                    }}
                    value={newRow.audit_procedure_data}
                    onChange={(e) => {
                      setNewRow({
                        ...newRow,
                        audit_procedure_data: e.target.value,
                      });
                      setDataRequired(e.target.value);
                    }}
                  />
                </td>
              </tr>

              {loading ? (
                <div className="spinner-bounce-loader">
                  <BounceLoader color="#a6fafa" />
                </div>
              ) : filteredData.length === 0 && !showNewRow ? (
                <p className="data-error-text">No data found</p>
              ) : (
                filteredData
                  .sort((a, b) => {
                    const riskIdA = a.risk_id || "";
                    const riskIdB = b.risk_id || "";
                    return riskIdA.localeCompare(riskIdB);
                  })
                  .map((data, index) => (
                    <tr
                      key={data.id}
                      onDoubleClick={() => handleEditClick(index, data)}
                    >
                      {editingRowIndex === index ? (
                        <>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "1vw",
                              }}
                            >
                              <Button
                                buttonName="Save"
                                buttonColor="#4CAF50"
                                onClick={() => handleDoneClick(data.id)}
                              />

                              <Button
                                buttonName="Close"
                                buttonColor="#4CAF50"
                                onClick={closeEdit}
                              />
                            </div>
                          </td>
                          {AuditExecution && (
                            <td>
                              <input
                                style={{
                                  width: "18px",
                                  height: "18px",
                                  marginLeft: "32px",
                                }}
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                              />
                            </td>
                          )}
                          <td>
                            <div>
                              <select id="user-dropdown" onChange={handleChangea}>
                                <option value="">
                                  {AuditExecution
                                    ? data.rcap?.business_unit?.name ||
                                    "--Select Business Unit--"
                                    : data.business_unit?.name ||
                                    "--Select Business Unit--"}
                                </option>

                                {AuditExecution
                                  ? businessUnitOptions.filter((user) => user?.business_units_id !== data?.rcap.business_unit?.id).map((user) => (
                                    <option key={user.business_units_id} value={user.business_units_id}>
                                      {user.name}
                                    </option>
                                  ))
                                  : businessUnitOptions.filter((user) => user?.id !== data?.business_unit?.id).map((user) => (
                                    <option key={user.id} value={user.id}>
                                      {user.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </td>
                          <td>
                            <textarea
                              type="text"
                              value={editedRowData.process}
                              onChange={(e) => handleInputChange(e, "process")}
                            />
                          </td>
                          <td>
                            <textarea
                              type="text"
                              value={editedRowData.sub_process}
                              onChange={(e) =>
                                handleInputChange(e, "sub_process")
                              }
                            />
                          </td>
                          <td>
                            <textarea
                              type="text"
                              style={{
                                border: riskIdError ? "1px solid #FF4646" : "",
                              }}
                              value={editedRowData.risk_id}
                              onChange={(e) => {
                                handleInputChange(e, "risk_id");
                                setRiskIdError(false);
                              }}
                            />
                          </td>
                          <td>
                            <textarea
                              type="text"
                              style={{
                                width: tableSize ? "50vw" : "60vw",
                                border: objectiveError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                              value={editedRowData.objective}
                              onChange={(e) => {
                                handleInputChange(e, "objective");
                                setObjectiveError(false);
                              }}
                            />
                          </td>
                          <td>
                            <textarea
                              type="text"
                              style={{
                                width: tableSize ? "50vw" : "60vw",
                                border: titleRiskError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                              value={editedRowData.risk_title}
                              onChange={(e) => {
                                handleInputChange(e, "risk_title");
                                setTitleRiskError(false);
                              }}
                            />
                          </td>

                          <td>
                            <OptionDropDown
                              inputValue={editedRowData.risk_category}
                              onInputChange={(e) =>
                                handleInputChange(e, "risk_category")
                              }
                              options={Category}
                              inputStyle={{
                                width: tableSize ? "12vw" : "15vw",
                                border: riskCategoryError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                            />
                          </td>

                          <td>
                            <textarea
                              type="text"
                              style={{
                                width: tableSize ? "35vw" : "38vw",
                                border: describeRiskError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                              value={editedRowData.risk_description}
                              onChange={(e) =>
                                handleInputChange(e, "risk_description")
                              }
                            />
                          </td>
                          <td>
                            <OptionDropDown
                              inputValue={
                                editedRowData.inherent_assessment_likelihood
                              }
                              onInputChange={(e) =>
                                handleInputChange(
                                  e,
                                  "inherent_assessment_likelihood"
                                )
                              }
                              options={inherentAssessmentLikeOptions}
                              inputStyle={{
                                width: tableSize ? "12vw" : "15vw",
                                border: likelikhoodError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                            />
                          </td>

                          <td>
                            <OptionDropDown
                              inputValue={
                                editedRowData.inherent_assessment_impact
                              }
                              onInputChange={(e) =>
                                handleInputChange(
                                  e,
                                  "inherent_assessment_impact"
                                )
                              }
                              options={inherentAssessmentLikeOptions}
                              inputStyle={{
                                width: tableSize ? "12vw" : "15vw",
                                border: impactError ? "1px solid #FF4646" : "",
                              }}
                            />
                          </td>
                          <td></td>
                          <td></td>
                          <td>
                            <textarea
                              type="text"
                              style={{
                                width: tableSize ? "50vw" : "85vw",
                                border: existingControlError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                              value={editedRowData.control_existing}
                              onChange={(e) =>
                                handleInputChange(e, "control_existing")
                              }
                            />
                          </td>
                          <td>
                            <textarea
                              type="text"
                              style={{
                                width: tableSize ? "50vw" : "85vw",
                                border: desiredControlError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                              value={editedRowData.control_desired}
                              onChange={(e) =>
                                handleInputChange(e, "control_desired")
                              }
                            />
                          </td>

                          <td>
                            <OptionDropDown
                              inputValue={editedRowData.control_evaluation}
                              onInputChange={(e) =>
                                handleInputChange(e, "control_evaluation")
                              }
                              options={controlEvaluationOptions}
                              inputStyle={{
                                width: tableSize ? "12vw" : "15vw",
                                border: evaluationError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                            />
                          </td>

                          <td>
                            <OptionDropDown
                              inputValue={editedRowData.control_design}
                              onInputChange={(e) =>
                                handleInputChange(e, "control_design")
                              }
                              options={controlDesignOptions}
                              inputStyle={{
                                width: tableSize ? "12vw" : "15vw",
                                border: controlDesignError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                            />
                          </td>
                          <td>
                            <OptionDropDown
                              inputValue={editedRowData.control_type}
                              onInputChange={(e) =>
                                handleInputChange(e, "control_type")
                              }
                              options={controlTypeOptions}
                              inputStyle={{
                                width: tableSize ? "12vw" : "15vw",
                                border: controlTypeError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                            />
                          </td>

                          <td>
                            <OptionDropDown
                              inputValue={
                                editedRowData.residual_assessment_likelihood
                              }
                              onInputChange={(e) =>
                                handleInputChange(
                                  e,
                                  "residual_assessment_likelihood"
                                )
                              }
                              options={residualAssessmentLikeOptions}
                              inputStyle={{
                                width: tableSize ? "12vw" : "15vw",
                                border: resLikelihoodError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                            />
                          </td>
                          <td>
                            <OptionDropDown
                              // label="Risk Category"
                              inputValue={
                                editedRowData.residual_assessment_impact
                              }
                              onInputChange={(e) =>
                                handleInputChange(
                                  e,
                                  "residual_assessment_impact"
                                )
                              }
                              options={residualAssessmentImpactOptions}
                              inputStyle={{
                                width: tableSize ? "12vw" : "15vw",
                                border: resImpactError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                            />
                          </td>
                          <td></td>
                          <td></td>
                          <td>
                            <textarea
                              type="text"
                              style={{
                                width: tableSize ? "50vw" : "85vw",
                                border: auditStepDetailsError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                              value={editedRowData.audit_procedure_step}
                              onChange={(e) => {
                                handleInputChange(e, "audit_procedure_step");
                                setAuditStepDetailsError(false);
                              }}
                            />
                          </td>
                          <td>
                            <textarea
                              type="type"
                              style={{
                                width: tableSize ? "50vw" : "85vw",
                                border: dataRequiredError
                                  ? "1px solid #FF4646"
                                  : "",
                              }}
                              value={editedRowData.audit_procedure_data}
                              onChange={(e) =>
                                handleInputChange(e, "audit_procedure_data")
                              }
                            />
                          </td>
                        </>
                      ) : (
                        <>
                          <td>
                            {" "}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: "1vw",
                                position: "sticky",
                                left: "0",
                                zIndex: 0,
                              }}
                            >
                              <WindowComponent
                                onDelete={() => handleDelete(data.id)}
                              />

                              <Button
                                buttonName="Edit"
                                buttonColor="#4CAF50"
                                onClick={() => handleEditClick(index, data)}
                              />
                            </div>
                          </td>
                          {editingRowIndex !== null && AuditExecution && (
                            <td>
                              <p style={{ maxWidth: "20px" }}> </p>
                            </td>
                          )}
                          <td>
                            <p style={{ maxWidth: "200px" }}>
                              {AuditExecution
                                ? data?.rcap?.business_unit
                                  ? data?.rcap?.business_unit?.name
                                  : "All Functions"
                                : data?.business_unit
                                  ? data?.business_unit?.name
                                  : "All Functions"}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "200px" }}>{data.process}</p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "300px" }}>
                              {data.sub_process}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "100px" }}>{data.risk_id}</p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "500px" }}>
                              {data.objective}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "500px" }}>
                              {data.risk_title}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "100px" }}>
                              {data.risk_category}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "600px" }}>
                              {data.risk_description}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "200px" }}>
                              {likemapping(data.inherent_assessment_likelihood)}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "200px" }}>
                              {impactmapping(data.inherent_assessment_impact)}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "200px" }}>
                              {data.risk_inherant_evaluation_score}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "200px" }}>
                              {data.risk_inherant_evaluation}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "600px" }}>
                              {data.control_existing}
                            </p>
                          </td>
                          <td>
                            <pre style={{ maxWidth: "600px" }}>
                              {data.control_desired}
                            </pre>
                          </td>
                          <td>
                            <p style={{ maxWidth: "200px" }}>
                              {data.control_evaluation}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "200px" }}>
                              {data.control_design}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "200px" }}>
                              {data.control_type}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "200px" }}>
                              {likemapping(data.residual_assessment_likelihood)}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "200px" }}>
                              {impactmapping(data.residual_assessment_impact)}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "200px" }}>
                              {data.risk_residual_evaluation_score}
                            </p>
                          </td>
                          <td>
                            <p style={{ maxWidth: "200px" }}>
                              {data.risk_residual_evaluation}
                            </p>
                          </td>
                          <td>
                            <pre style={{ maxWidth: "600px" }}>
                              {data.audit_procedure_step}
                            </pre>
                          </td>
                          <td>
                            <pre style={{ maxWidth: "600px" }}>
                              {data.audit_procedure_data}
                            </pre>
                          </td>
                        </>
                      )}
                    </tr>
                  ))
              )}
            </tbody>

          </table>
        </div>

        <div className="cardscontainer">

          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
            }}
          >
            <thead>
              <tr>
                <th
                  style={{
                    padding: "12px 15px",
                    textAlign: "left",
                    border: "1px solid #ddd",
                    backgroundColor: "#f4f4f4",
                    fontWeight: "bold",
                  }}
                >
                  Risk ID
                </th>
                <th
                  style={{
                    padding: "12px 15px",
                    textAlign: "left",
                    border: "1px solid #ddd",
                    backgroundColor: "#f4f4f4",
                    fontWeight: "bold",
                  }}
                >
                  Business Unit
                </th>
                <th
                  style={{
                    padding: "12px 15px",
                    textAlign: "left",
                    border: "1px solid #ddd",
                    backgroundColor: "#f4f4f4",
                    fontWeight: "bold",
                  }}
                >
                  Process
                </th>
                <th
                  style={{
                    padding: "12px 15px",
                    textAlign: "left",
                    border: "1px solid #ddd",
                    backgroundColor: "#f4f4f4",
                    fontWeight: "bold",
                  }}
                >
                  Sub Process
                </th>
                <th
                  style={{
                    padding: "12px 15px",
                    textAlign: "left",
                    border: "1px solid #ddd",
                    backgroundColor: "#f4f4f4",
                    fontWeight: "bold",
                  }}
                >
                  Objective
                </th>
                <th
                  style={{
                    padding: "12px 15px",
                    textAlign: "left",
                    border: "1px solid #ddd",
                    backgroundColor: "#f4f4f4",
                    fontWeight: "bold",
                  }}
                >
                  Risk Title
                </th>
              </tr>
            </thead>

            {/* Table Body (rows) */}
            <tbody>
              {filteredData.sort((u, v) => u.id - v.id).map((data) => (
                <tr
                  onClick={() => Viewuser(data)}
                  key={data.id}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <td
                    style={{
                      padding: "12px 15px",
                      textAlign: "left",
                      border: "1px solid #ddd",
                    }}
                  >
                    {data?.risk_id}
                  </td>
                  <td
                    style={{
                      padding: "12px 15px",
                      textAlign: "left",
                      border: "1px solid #ddd",
                    }}
                  >
                    {data?.rcap?.business_unit
                      ? data?.rcap?.business_unit?.name
                      : "All Functions"}
                  </td>
                  <td
                    style={{
                      padding: "12px 15px",
                      textAlign: "left",
                      border: "1px solid #ddd",
                    }}
                  >
                    {data?.process}
                  </td>
                  <td
                    style={{
                      padding: "12px 15px",
                      textAlign: "left",
                      border: "1px solid #ddd",
                    }}
                  >
                    {data?.sub_process}
                  </td>
                  <td
                    style={{
                      padding: "12px 15px",
                      textAlign: "left",
                      border: "1px solid #ddd",
                      
                    }}
                  >
                    <p
                      style={{
                        width: "200px", 
                        whiteSpace:"normal"
                      }}
                    >
                      {data?.objective}
                    </p>

                  </td>
                  <td
                    style={{
                      padding: "12px 15px",
                      textAlign: "left",
                      border: "1px solid #ddd",
                    }}
                  >
                    {data?.risk_title}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

        </div>

      </section>
    </>
  );
};

export default ListOfAllRcapPage;
