import React, { useState, useEffect } from "react";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../../Breadcrumb";
import SidebarMenu from "../../../SidebarMenu";
import "../../../../css/viewuser.css";
import { fetchDataObjectV2 } from "../../../apiUtils";
import { auditExecution } from "../../../subroutes";
import "../createtask/createtask.css";
import MultiOptionSelector from "../../../MultiOptionSelector";
import Schedulemeeting from "../schedulemeeting/Schedulemeeting";
import SchedulemeetingView from "../schedulemeeting/SchedulemeetingView";
import Viewauditmemo from "../auditmemo/Viewauditmemo";
import ViewInitialReqList from "../initialreqlist/ViewInitialReqList";
import ViewRcmTask from "../../../auditUniverse/rcm/ViewRcmTask";
import GenericTaskView from "../GenericTaskView";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../../RenderIcons";
import NavRow from "../../../NavRow";
const ViewTask = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [APIAuditData, setAPIAuditData] = useState([]);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const originalUrl = location.state?.from;
  const { id, planid } = useParams();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const parameter ={
      url :  `/api/audit_engagement/plan/${planid}/`,
      setterFunction : setAPIData,
      setErrors : setErrors,
      setLoading : setLoading
     }
    fetchDataObjectV2(parameter);
    
  }, []);

  // useEffect(() => {



  const [filters, setFilters] = useState({
    name: "",
    key_stakeholder: "",
  });

  const handleFilterChange = (filterKey, e) => {
    setFilters({ ...filters, [filterKey]: e.target.value });
  };

  const renderIcons = () => {
    return auditExecution.map((route, index) => {
      if (route.subRoutes) {
        return <SidebarMenu route={route} key={index} />;
      }

      return (
        <NavLink
          to={`${route.path.replace(":id", id)}`}
          key={index}
          className="navlink"
          activeclassname="active1"
        >
          <div className="navicon">
            {<img src={route.icon} alt="Dashboard Icon" />}
          </div>
          <div className="navicon">{route.name}</div>
        </NavLink>
      );
    });
  };

  let renderComponent = "";
  console.log(loading);
  if (APIData && APIData.data) {
    renderComponent = (data) => {
      switch (data.type) {
        case "Schedule Meeting":
          return (
            <SchedulemeetingView APIData={APIData} tableSize={tableSize} />
          );
        case "Prepare Audit Memo":
          return <Viewauditmemo APIData={APIData} tableSize={tableSize} />;
        case "Send Initial List Of Requirements":
          return <ViewInitialReqList APIData={APIData} tableSize={tableSize} />;
        case "Fill Rcm Template":
          return <ViewRcmTask APIData={APIData} tableSize={tableSize} />;
        case "Task":
          return <GenericTaskView APIData={APIData} tableSize={tableSize} />;

        default:
          return null;
      }
    };
  }

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      {successMessage && <p className="successmessage">{successMessage}</p>}

      {loading ? (
        <div className="spinner-bounce-loader">
          <BounceLoader color="#a6fafa" />
        </div>
      ) : APIData && APIData.data === 0 ? (
        <p className="data-error-text">No data found</p>
      ) : APIData && APIData.data ? (
        renderComponent(APIData.data)
      ) : null}

      <div className="cardscontainer"></div>
    </>
  );
};

export default ViewTask;
