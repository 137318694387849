import React, { useState, useEffect } from "react";
import {
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import "../../../css/viewuser.css";
import { fetchDataV2, postDataV2,fetchDataObjectV2 } from "../../apiUtils";
import openarrow from "../../../images/openarrow.svg";
import "../auditplan/auditplan.css";
import "./publishreport.css";
import backBtn from "../../../images/backLogo.svg";
import NavRow from "../../NavRow";
import RenderIcons from "../../RenderIcons";
import { auditExecution } from "../../subroutes";

const PublishReport = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [APIData1, setAPIData1] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [pdfBlob, setPdfBlob] = useState(null);
  const [filteredDataDone, setFilteredData] = useState();
  const successMessage = location.state?.successMessage;
  const { id,reportId, obejctionId  } = useParams();
  const [APIaudit, setAudit] = useState([]);
  const [loading,setLoading] = useState([]);


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const parameter ={
      url :`/api/audit_engagement/observation/?audit_id=${id}`, 
      setterFunction :setAPIData1,
      setErrors : setErrors
     }
    fetchDataV2(parameter);
  }, []);

  useEffect(() => {
    const fetchAuditData = () => {
      const parameter = {
        url: `/api/audit_engagement/audit/${id}/`,
        setterFunction: setAudit,
        setErrors: setErrors,
        setLoading: setLoading,
      };
      fetchDataObjectV2(parameter);

      const parameter1 = {
        url: `/api/audit_engagement/observation/${obejctionId}/`,
        setterFunction: setAPIData,
        setErrors: setErrors,
        setLoading: setLoading,
      };
      fetchDataObjectV2(parameter1);
    };

    fetchAuditData();
  }, [id, obejctionId]);

  useEffect(() => {
    setFilteredData(APIData1.filter((data) => data?.task?.status === "done"));
  }, [APIData1]);

  useEffect(() => {
    const parameter ={
      url :`/api/audit_universe/audit-entities/`, 
      setterFunction :setAPIData,
      setErrors : setErrors
     }
    fetchDataV2(parameter);
  }, []);

  const handlePublishReport = () => {
    const parameter = {
      url: `/api/audit_engagement/audit/${id}/report`,
      payload: {},
      navigate: navigate,
      setErrors: setErrors,
      navUrl: `/api/audit_engagement/audit/${id}/report`,
    };
    postDataV2(parameter);
  };

  useEffect(() => {
    const parameter ={
      url :`/api/audit_engagement/audit/${id}/report_file/`, 
      setterFunction :handlePdfData,
      setErrors : setErrors
     }
    fetchDataV2(parameter);
  }, [id]);

  const handlePdfData = (data) => {
    const pdfData = data[0]?.document?.blob;
    setPdfBlob(pdfData);
  };

  const [filters, setFilters] = useState({
    id: "",
    status: "",
    workpaper_id: "",
    summary: "",
    preparer: "",
    reviewer_name: "",
    created_at: "",
  });

  const filteredData = APIData1.filter((data) => {
    const {
      id,
      summary,
      preparer,
      reviewer,
      created_at,
      task,
      workpapertestcase,
    } = data;

    const matchesId = filters.id
      ? (id || "").toString().includes(filters.id)
      : true;

    const matchesStatus = filters.status
      ? task && task.status.toLowerCase().includes(filters.status.toLowerCase())
      : true;

    const matchesWorkpaperId = filters.workpaper_id
      ? workpapertestcase.some((wpTestCase) =>
          wpTestCase.workpaper.id
            .toString()
            .includes(filters.workpaper_id.toString())
        )
      : true;

    const matchesSummary = filters.summary
      ? (summary || "").toLowerCase().includes(filters.summary.toLowerCase())
      : true;

    const matchesPreparer = filters.preparer
      ? preparer &&
        preparer.name.toLowerCase().includes(filters.preparer.toLowerCase())
      : true;

    const matchesReviewer = filters.reviewer
      ? reviewer &&
        reviewer.name.toLowerCase().includes(filters.reviewer.toLowerCase())
      : true;

    const matchesCreatedAt = filters.created_at
      ? (created_at || "").toString().includes(filters.created_at)
      : true;

    return (
      matchesId &&
      matchesStatus &&
      matchesSummary &&
      matchesWorkpaperId &&
      matchesPreparer &&
      matchesReviewer &&
      matchesCreatedAt
    );
  });

  useEffect(() => {
    console.log("Filtered Data:", filteredData);
  }, [filteredData]);

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      {successMessage && <p className="successmessage">{successMessage}</p>}
      <RenderIcons homeRoutes={auditExecution} id={id} />
      <NavRow name={"Publish Report"}  parentName={APIaudit?.name} idtrue={true} />

      <section className="navroutes">
        <div
          className="publish_report"
          style={{ width: tableSize ? "100%" : "100%" }}
        >
      
            <div className="backToReport">
              <button
                className="cmn_btn"
                style={{ backgroundColor: "#65CC5C" }}
                onClick={handlePublishReport}
              >
                Publish Report
              </button>
              
            </div>
          

          
            {pdfBlob ? (
              <iframe
                src={`data:application/pdf;base64,${pdfBlob}`}
                title="Report PDF"
                className="reportPdf"
              />
            ) : (
              <p>Loading PDF...</p>
            )}
          
        </div>

        <div className="cardscontainer">
          {filteredData.map((data) => (
            <div className="aecard">
              <h1>{data.name} </h1>

              <img src={openarrow} />
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default PublishReport;
