import React, { useState, useEffect, useRef } from "react";
import { Link, Navigate, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../css/top.css";
import notify from "../images/notify.svg";
import user from "../images/user.svg";
import department from "../images/department.svg";
import BU from "../images/BU.svg";
import corporate from "../images/corporate.svg";
import SidebarMenu from "./SidebarMenu";
import { auditRoutes, homeRoutes } from "./subroutes";
import SuperAvatar from "./SuperAvatar";
import AccountWindow from "../components/AccountWindow"; // Import the AccountWindow component
import notify_mobile_icon from "../images/notification_mobile_icon.svg"
import { FaCog, FaSignOutAlt } from "react-icons/fa";
let routes = [];

const Top = ({ isOpen }) => {
  const [showSide, setShowSide] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const [showAccountWindow, setShowAccountWindow] = useState(false); // State to manage the display of AccountWindow
  const accountWindowRef = useRef(null); // Create a reference for the AccountWindow
  // const [isOpen, setIsOpen] = useState(true);
  // const toggle = () => {
  //   handleChange(!isOpen);
  //   setIsOpen(!isOpen)
  // };
  const navigate = useNavigate();
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const heading = url.pathname.split("/")[1];

  const username = localStorage.getItem("userName");
  const userLastName = localStorage.getItem("userLastName");
  const designation = localStorage.getItem("designation");
  const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  const is_Admin = localStorage.getItem("is_Admin");

  const toggleSide = () => {
    navigate(-1)
  };
  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  const avaUserName = localStorage.getItem("userName");

  let titleHeading;
  switch (heading) {
    case "home":
      routes = homeRoutes;
      titleHeading = "Dashboard";
      break;
    case "Administration":
      routes = homeRoutes;
      titleHeading = "Administration";
      break;
    case "AuditUniverse":
      routes = auditRoutes;
      titleHeading = "Audit Universe";
      break;
    case "AuditExecution":
      routes = [];
      titleHeading = "Audit Execution";
      break;
    case "issuetracking":
      routes = [];
      titleHeading = "Issue Tracking";
      break;
    default:
      console.log("");
  }

  const clearStorage = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.clear();
    window.location.href = "/";
  };

  const handleAccountClick = () => {
    setShowAccountWindow(!showAccountWindow);
  };

  // Handle clicks outside of AccountWindow
  const handleClickOutside = (event) => {
    if (accountWindowRef.current && !accountWindowRef.current.contains(event.target)) {
      setShowAccountWindow(false);
    }
  };

  useEffect(() => {
    if (showAccountWindow) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup event listener when component is unmounted or when AccountWindow is closed
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showAccountWindow]);

  return (
    <>
      <div className="top">

        <div className="top_left">

          <div className="hamburgercontainer" onClick={toggleSide}>
            <svg width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.00009 16.92L1.48009 10.4C0.710088 9.62996 0.710088 8.36996 1.48009 7.59996L8.00009 1.07996" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>





        </div>

        <div className="top_right">

          <div className="notaacc" onClick={() => setShowDropdown(!showDropdown)} style={{ flexDirection: isOpen ? "row" : "column", background: isOpen ? "" : "transparent", border: isOpen ? "" : "none" }}>


            <div className="avatar_name_sidebar">

              <div className="acc"
              // onClick={handleAccountClick}
              >
                <SuperAvatar firstName={avaUserName} classOfAvatar={"top_avatar"} />
              </div>

              <div className="pers_name" style={{ display: isOpen ? "" : "none" }}>
                <h2>{username + " " + userLastName}</h2>
                <h2>{designation}</h2>
              </div>

            </div>





            <img className="notify_mobile_icon" src={notify_mobile_icon} />
            <img src={notify} alt="notify_icon" />
            {showDropdown && (
              <div className="dropdown1" style={{

                position: 'absolute',
                left: isOpen ? '13%' : '-23%',
                border: isOpen ? "" : "none"

              }}>

                {isOpen ? (
                  <>

                    <Link to="/Usersettings" className="dropdown-item">
                      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 9.11011V14.8801C3 17.0001 3 17.0001 5 18.3501L10.5 21.5301C11.33 22.0101 12.68 22.0101 13.5 21.5301L19 18.3501C21 17.0001 21 17.0001 21 14.8901V9.11011C21 7.00011 21 7.00011 19 5.65011L13.5 2.47011C12.68 1.99011 11.33 1.99011 10.5 2.47011L5 5.65011C3 7.00011 3 7.00011 3 9.11011Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                      Settings
                    </Link>

                    <button onClick={clearStorage} className="dropdown-item">
                      <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.90002 7.55999C9.21002 3.95999 11.06 2.48999 15.11 2.48999H15.24C19.71 2.48999 21.5 4.27999 21.5 8.74999V15.27C21.5 19.74 19.71 21.53 15.24 21.53H15.11C11.09 21.53 9.24002 20.08 8.91002 16.54" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M15 12H3.62" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M5.85 8.6499L2.5 11.9999L5.85 15.3499" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                      Logout
                    </button>

                  </>
                ) : (
                  <>

                    <Link to="/Usersettings" className="dropdown-item">
                      <FaCog className="icon" title="Setting" />
                    </Link>

                    <button onClick={clearStorage} className="dropdown-item">
                      <FaSignOutAlt className="icon" title="Logout" />
                    </button>

                  </>
                )}

                {/* <Link to="/settings" className="dropdown-item1">
              Settings
            </Link>
            <button onClick={clearStorage} className="cmn_btn" style={{backgroundColor:'red'}}>
              Logout
            </button> */}
              </div>
            )}
          </div>

        </div>

      </div>



      {/* {showAccountWindow && (
        <div ref={accountWindowRef}>
          <AccountWindow />
        </div>
      )} */}
    </>
  );
};

export default Top;
