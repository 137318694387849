import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import Breadcrumb from "../../Breadcrumb";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import "../auditPlan/calenderviewpage.css";
import { auditRoutes } from "../../subroutes";
import Timeline from "./Timeline";
import { justFetchDataV2 } from "../../apiUtils";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../RenderIcons"
import NavRow from "../../NavRow";
const CalenderViewPage = ({ tableSize }) => {
  const [APIData, setAPIData] = useState([]);
  const [errors, setErrors] = useState("");
  const [auditStatus, setAuditStatus] = useState({});
  const [loading, setLoading] = useState(false);
  var audit = ''
  const { auid } = useParams();
  const [selectedId, setSelectedId] = useState("");
  const [removeId, setRemoveId] = useState(false);
  const [isCreateClicked, setIsCreateClicked] = useState(false);
  const [APIAllData, setAPIAllData] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  const successMessageColor = location.state?.successMessageColor;
  const successMessage = location.state?.successMessage;
    const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  const is_Admin = localStorage.getItem("is_Admin");

  const Roles = is_Admin && Object.keys(userRoles).some(key => key.includes("Audit Team"));

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [navigate]);

  useEffect(() => {
    const parameter ={
      url : `/api/audit_universe/audit-events/plan/${auid}/`,
      setterFunction :(data) => {setAPIAllData(data)},
      setErrors : (error) => {setErrors(error)},
      setLoading: setLoading,
    }
    
    justFetchDataV2(parameter);
  }, []);
  console.log(auid)

  useEffect(() => {
    if (APIAllData.length > 0 && !removeId) {
      const lastItemId = APIAllData[APIAllData.length - 1].id;
      console.log(lastItemId)
      setSelectedId(lastItemId);
    }
  }, [APIAllData, removeId]);

  useEffect(() => {
    if (selectedId) {
      console.log(selectedId)
      const parameter2={
        url : `/api/audit_universe/audit-plan/${auid}/`,
        setterFunction :(data) => {setAPIData(data)},
        setErrors : (error) => {setErrors(error)},
        setLoading: setLoading,
      }
      justFetchDataV2(parameter2);
    }
  }, [selectedId]);

  const handleChange = (e) => {
    setSelectedId(e.target.value);
    setRemoveId(true);
  };




  const renderIcons = () => {
    return auditRoutes.map((route) => {
      if (route.subRoutes) {
        return <SidebarMenu route={route} key={route.path} />;
      }

      return (
        <NavLink
          to={route.path}
          key={route.path}
          className="navlink"
          activeclassname="active1"
        >
          <div className="navicon">
            <img src={route.icon} alt="Dashboard Icon" />
          </div>
          <div className="navicon">{route.name}</div>
        </NavLink>
      );
    });
  };

  const [timelineData, setTimelineData] = useState([]);

  const parseDate = (dateStr) => {
    const date = new Date(dateStr);
    const month = date.toLocaleString("default", { month: "short" });
    return month;
  };

  const getYearRange = (plan) => {
    const planStartDate = new Date(plan.plan_start_date);
    const planEndDate = new Date(plan.plan_end_date);

    if (!isNaN(planStartDate) && !isNaN(planEndDate)) {
      const startYear = planStartDate.getFullYear();
      const endYear = planEndDate.getFullYear();
      const yearDifference = endYear - startYear;
      return Array.from(
        { length: yearDifference + 1 },
        (_, i) => startYear + i
      );
    } else {
      console.log(`Invalid dates for plan ${plan.id}`);
      return [];
    }
  };

  const handleCreateClick = () => {
    setIsCreateClicked(true);
  };

  const handleApproveClick = () => {
    console.log("Approved");
  };

  const handleRejectClick = () => {
    console.log("Rejected");
  };

  const [showPopup, setShowPopup] = useState(false);
  const handleSendClick = () => {
    setShowPopup(true);
    setTimeout(() => setShowPopup(false), 2000);
  };

  const renderAuditPlanCalenderData = (data) => {
    return
  }


  const truePlan = {
    handleCreateClick: handleCreateClick,
    handleApproveClick: handleApproveClick,
    handleRejectClick: handleRejectClick
  }

  return (
    <>
      {showPopup && (
        <div className="popup">
          <p>Audit Plan Created</p>
        </div>
      )}
      <section className="navroutes">
        <RenderIcons homeRoutes={auditRoutes} />
        <NavRow name={"Audit Plan"} truePlan={truePlan} />

        <div className="calendar_container"
          style={{ width: tableSize ? "81vw" : "88.5vw" }}
        >
          {loading ? (
            <div className="spinner-bounce-loader">
              <BounceLoader color="#a6fafa" />
            </div>

          ) : APIData.length === 0 ? (
            <p className="data-error-text">No data found</p>
          ) : (
            APIData.map((plan) => {
              const years = getYearRange(plan);
              return (

                plan.audit_events.length !== 0 &&
                years.map((year) => {

                  const relevantEvents = plan.audit_events.filter((event) => {

                    const eventStartYear = new Date(
                      event.planned_start_date
                    ).getFullYear();
                    const eventEndYear = new Date(
                      event.planned_end_date
                    ).getFullYear();
                    {/* const auditplanid=plan.id */ }
                    return (
                      (eventStartYear <= year && eventEndYear >= year) ||
                      (eventStartYear < year && eventEndYear > year)

                    );
                  });



                  if (relevantEvents.length > 0) {
                    return (
                      <div key={year} className="calendar">
                        <div className="year_entitieslist">

                          <div className="calender_header">
                            <div className="year_con">
                              <span>{year}</span>
                            </div>

                            <div className="monthHeader">
                              <div className="yearlyQuarter_con">
                                <h6 className="quarter">Q1</h6>
                                <h6 className="quarter">Q2</h6>
                                <h6 className="quarter">Q3</h6>
                                <h6 className="quarter">Q4</h6>
                              </div>
                              <div className="months_con">
                                {Array.from({ length: 12 }, (_, month) => {
                                  const monthStart = new Date(
                                    Date.UTC(year, month, 1)
                                  );
                                  const monthEnd = new Date(
                                    Date.UTC(year, month + 1, 0)
                                  );
                                  return (
                                    <h6 key={month}>
                                      {monthStart.toLocaleString("default", {
                                        month: "short",
                                      })}
                                    </h6>
                                  );
                                })}
                              </div>
                            </div>
                          </div>



                          <div className="calender_content">
                            <div className="entities_list">
                              {relevantEvents.map((item, index) => (
                                <div className="audit_entity" key={index}>
                                  <h6>{item.audit_entity.name}</h6>
                                </div>
                              ))}
                            </div>

                            <div className="timelineDiv">
                              {relevantEvents.map((event, index) => {
                                audit = event.audit ? event.audit : ""
                                const eventStartDate = new Date(
                                  event.planned_start_date
                                );
                                const eventEndDate = new Date(event.planned_end_date);
                                const eventStartYear = eventStartDate.getFullYear();
                                const eventEndYear = eventEndDate.getFullYear();
                                {/* const auditplanid=event.id;
                            const auditentityid=event.audit_entity.id; */}

                                let adjustedStartDate = eventStartDate;
                                let adjustedEndDate = eventEndDate;

                                if (eventStartYear < year) {
                                  adjustedStartDate = new Date(Date.UTC(year, 0, 1));
                                }
                                if (eventEndYear > year) {

                                  adjustedEndDate = new Date(Date.UTC(year, 11, 31));
                                } else {

                                }
                                {/* console.log(event) */ }


                                return (
                                  <Timeline
                                    key={`${event.id}-${year}`}
                                    APIData={{
                                      id: `${event.id}-${year}`,
                                      startDate: adjustedStartDate,
                                      endDate: adjustedEndDate,
                                      monthStart: parseDate(adjustedStartDate),
                                      monthEnd: parseDate(adjustedEndDate),
                                      showForm: false,

                                    }}
                                    timelineData={timelineData}
                                    setTimelineData={setTimelineData}
                                    handleSendClick={handleSendClick}
                                    endyear={eventEndDate}
                                    startyear={eventStartDate}
                                    audit_event_id={event.id}
                                    audit_entity={event.audit_entity.name}
                                    auditplanid={plan.id}
                                    auditentityid={event.audit_entity.id}
                                    auditStatus={event.audit.status}
                                    risk_assessment_id={plan.risk_assessment_id}
                                  />
                                );
                              })}
                            </div>
                          </div>

                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })
              );
            })
          )}


        </div>

        <div className="cardscontainer">
          {loading ? (
            <div className="spinner-bounce-loader">
              <BounceLoader color="#a6fafa" />
            </div>
          ) : APIData.length === 0 ? (
            <p className="data-error-text">No data found</p>
          ) : (
            APIData.map((plan) => {
              const years = getYearRange(plan);
              return (
                plan.audit_events.length !== 0 &&
                years.map((year) => {
                  const relevantEvents = plan.audit_events.filter((event) => {
                    const eventStartYear = new Date(event.planned_start_date).getFullYear();
                    const eventEndYear = new Date(event.planned_end_date).getFullYear();

                    return (
                      (eventStartYear <= year && eventEndYear >= year) ||
                      (eventStartYear < year && eventEndYear > year)
                    );
                  });

                  return relevantEvents.map((event, index) => {
                    const eventStartDate = new Date(event.planned_start_date).toLocaleDateString();
                    const eventEndDate = new Date(event.planned_end_date).toLocaleDateString();

                    return (
                      <div className="deptcard" key={`${event.id}-${index}`}>
                        {/* Display Entity Name */}
                        <h2>{event.audit_entity.name}</h2>

                        {/* Display Year */}
                        <h6>Year: {year}</h6>

                        {/* Display Date Range */}
                        <p style={{ fontFamily:"intersemibold" }} >
                          From: {eventStartDate} To: {eventEndDate}
                        </p>
                      </div>
                    );
                  });
                })
              );
            })
          )}
        </div>


      </section>
    </>
  );
};

export default CalenderViewPage;
