import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";

import sendComment from "../../../images/send.svg";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import "./issuedetailpage.css";

import {
  fetchDataV2,
  updateDataV2,
  justPostDataV2,
  deleteDataWithFetchV2,
  deleteDataV2,
  justUpdateDataV2,
  postDataWithFile,
} from "../../apiUtils";
import { issueTracking } from "../../subroutes";
import AuditReportingSeclector from "../../auditExecution/auditReporting/AuditReportingSelector";
import SuperAvatar from "../../SuperAvatar";
import IconButton from "../../iconbutton/IconButton";
import WindowComponent from "../../WindowComponent";
import savesubObv from "../../../images/saveBtn.svg";
import cutbutton from "../../../images/cut.svg";
import blue_add_button from "../../../images/blue_plus_button.svg";
import createBtn from "../../../images/createbutton.svg";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";

const Issuedetailpage = ({ tableSize }) => {
  const lastName = localStorage.getItem("userLastName");
  const avaUserName = localStorage.getItem("userName");
  const [APIQData, setAPIQData] = useState("");
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [owner_id, setOwner] = useState("");
  const [assign_to_1_id, setAssign_to_id] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [summary, setSummary] = useState("");
  const [preparer_id, setPreparerId] = useState("");
  const [assign_to_idError, setAssign_to_idError] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [taskData, setTaskData] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [comments, setComments] = useState([]);
  const [showComments, setShowComments] = useState(false);
  const [successMessage, setSuccessmessage] = useState(
    location.state?.successMessage
  );
  const { id, reportId } = useParams();
  const [sub_observation, setSubObservations] = useState([]);
  const [startdate, setStartdate] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [objective, setTitle] = useState("");
  const [rating, setObservationRating] = useState("");
  const [management_response, setActionPlan] = useState("");
  const [observationid, setObservationId] = useState("");
  const [level_1_ownerError, setLevel1OwnerError] = useState("");
  const [assign_to_2_id, setLevel2Owner] = useState("");
  const [level_2_ownerError, setLevel2OwnerError] = useState("");
  const [management_response_due_date, setActionDueDate] = useState("");
  const [response_audit, setAuditResponse] = useState("");
  const [response_audit_due_date, setResponseActionDueDate] = useState("");
  const [short_description, setShortDescription] = useState("");
  const { issueid } = useParams();
  const [inputList, setInputList] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [isComments, setIsComments] = useState(true);
  const [fileObjective, setFileObjective] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [activitylog, setActivityLog] = useState([]);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [showSelectFileButton, setShowSelectFileButton] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [successMessageFileUpload, setSuccessMessage] = useState(false);
  const [successMessageFileDelete, setSuccessMessageDelete] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [navigate]);

  useEffect(() => {
    setEditMode(false);
    const parameter = {
      url: `/api/issues/${issueid}/`,
      setterFunction: setAPIData,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, [issueid]);

  useEffect(() => {
    const parameter = {
      url: `/api/issues/issuefile/?issue_id=${issueid}`,
      setterFunction: setUploadedFiles,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, [issueid]);

  useEffect(() => {
    const parameter = {
      url: `/api/system/activity/?action_on=Issue&action_on_id=${issueid}`,
      setterFunction: setActivityLog,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, []);

  useEffect(() => {
    if (APIData) {
      setTitle(APIData?.objective);
      setObservationRating(APIData?.rating);
      setShortDescription(APIData?.short_description);
      // console.log(APIData?.short_description);
      setObservationId(APIData?.observation?.id);
      setAPIQData(APIData?.task?.id);
      setStartdate(APIData.due_date);
      setActionPlan(APIData?.management_response);
      setActionDueDate(APIData?.management_response_due_date);
      setSummary(APIData.summary);
      setAuditResponse(APIData?.response_audit);
      setResponseActionDueDate(APIData?.response_audit_due_date);
      setSubObservations(APIData?.sub_observation);
      setSubObservations(APIData?.sub_observation || []);
    }
  }, [APIData]);

  const [taskTitle, setTaskTitle] = useState("");

  useEffect(() => {
    if (APIData) {
      setAPIQData(APIData?.task?.id);
      setTaskTitle(APIData?.task?.title);
    }
  }, [APIData]);

  useEffect(() => {
    if (editMode) {
      if (!assign_to_1_id) {
        setAssign_to_idError(!assign_to_1_id ? true : false);
        setErrors("Please Fill the Field");
        return;
      }
      const parameter = {
        url: `/api/issues/${issueid}/assing_1/`,
        payload: {
          assign_to_1_id: assign_to_1_id,
        },
        setErrors: setErrors,
      };
      justUpdateDataV2(parameter);
    }
  }, [assign_to_1_id]);

  useEffect(() => {
    if (editMode) {
      if (!owner_id) {
        setLevel1OwnerError(!owner_id ? true : false);
        setErrors("Please Fill the Field");
        return;
      }
      const parameter = {
        url: `/api/issues/${issueid}/owner/`,
        payload: {
          owner_id: owner_id,
        },
        setErrors: setErrors,
      };

      justUpdateDataV2(parameter);
    }
  }, [owner_id]);

  useEffect(() => {
    if (editMode) {
      if (!assign_to_2_id) {
        setLevel2OwnerError(!assign_to_2_id ? true : false);
        setErrors("Please Fill the Field");
        return;
      }
      const parameter = {
        url: `/api/issues/${issueid}/assing_2/`,
        payload: {
          assign_to_2_id: assign_to_2_id,
        },
        setErrors: setErrors,
      };
      justUpdateDataV2(parameter);
    }
  }, [assign_to_2_id]);

  const ChangeDate = () => {
    const parameter = {
      url: `/api/issues/${issueid}/due_date/`,
      payload: {
        management_response_due_date,
      },
      setErrors: setErrors,
    };
    justUpdateDataV2(parameter);
  };

  const [filters, setFilters] = useState({
    name: "",
    key_stakeholder: "",
  });

  const UpdateIssue = () => {
    const assign1id = parseInt(assign_to_1_id, 10);
    const assign2id = parseInt(assign_to_2_id, 10);
    const Parameter = {
      url: `/api/issues/${issueid}`,
      payload: {
        objective,
        observation_id: observationid,
        short_description,
        rating,
        management_response,
        preparer_id,
        assign_to_1_id: assign1id,
        assign_to_2_id: assign2id,
        owner_id,
        management_response_due_date,
        response_audit,
        response_audit_due_date,
        sub_observation,
        summary,
      },
      setErrors: setErrors,
    };

    updateDataV2(Parameter);
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const commentRef = useRef(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);

  const handleAddComment = () => {
    if (inputValue.trim()) {
      const currentDateTime = new Date();
      const formattedDate = currentDateTime.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "2-digit",
      });
      const formattedTime = currentDateTime.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      const newComment = {
        text: inputValue,
        date: formattedDate,
        time: formattedTime,
      };

      setComments([...comments, newComment]);
      setTaskData([...taskData, newComment]);

      const parameter = {
        url: `/api/system/comments/`,
        payload: {
          user_id: localStorage.getItem("user_id"),
          task_id: APIQData?.task?.id,
          text: inputValue,
        },
        setErrors: setErrors,
      };
      justPostDataV2(parameter);

      setInputValue("");
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleAddComment();
    }
  };

  const daysSince = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const timeDiff = now - date;
    const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    if (daysDiff <= 0) return "today";
    return daysDiff === Number.NaN ? 0 : "" + " days ago";
  };

  const handleEditSave = () => {
    setEditMode(true);
  };

  useEffect(() => {
    if (sub_observation && sub_observation.length > 0) {
      const initialInputs = sub_observation.map((observation) => ({
        value: observation,
        isEditing: false,
      }));
      setInputList(initialInputs);
    }
  }, [sub_observation]);

  function convertDate(dateString) {
    if (dateString) {
      const date = new Date(dateString);
      date.setDate(date.getDate() - 15);
      const formattedDate = date.toISOString().split("T")[0];
      return formattedDate;
    }
  }

  const onDelete = (e) => {
    e.preventDefault();
    const parameter = {
      url: `/api/issues/${issueid}`,
      navigate: navigate,
      setErrors: setErrors,
      navUrl: "/issuetracking/issues",
    };

    deleteDataV2(parameter);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if selectedFiles is an array and add the new file if it's not already present
      if (
        Array.isArray(selectedFiles) &&
        !selectedFiles.some((f) => f.name === file.name && f.size === file.size)
      ) {
        setSelectedFiles([...selectedFiles, file]);
      } else {
        console.warn("File already selected.");
      }
      console.log(file);
      setShowSelectFileButton(false);
    }
  };

  const handleFileUpload = () => {
    if (selectedFiles?.length === 0) {
      setErrors("Please select a file to upload.");
      return;
    }

    const parameter = {
      url: `/api/issues/issuefile/`,
      data: { issue_id: APIData?.id },
      files: selectedFiles,
      setErrors: setErrors,
      tofetch: {
        items: [
          {
            fetchurl: `/api/issues/issuefile/?issue_id=${issueid}`,
            dataset: setUploadedFiles,
          },
        ],
      },
      setSuccessMessage: setSuccessMessage,
    };

    postDataWithFile(parameter);
    setSelectedFiles();
    setShowSelectFileButton(true);

    console.log("Files for upload:", selectedFiles);
  };
  const handleDeleteModalOpen = (fileId) => {
    setFileToDelete(fileId);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalClose = () => {
    setIsDeleteModalOpen(false);
    setFileToDelete(null);
  };

  const confirmDeleteFile = () => {
    if (fileToDelete) {
      removeFile(fileToDelete);
      handleDeleteModalClose();
    }
  };

  const removeFile = (associated_with_id) => {
    const parameter = {
      url: `/api/issues/issuefile/${associated_with_id}/`,
      navigate: navigate,
      setErrors: setErrors,
      tofetch: {
        items: [
          {
            fetchurl: `/api/issues/issuefile/?issue_id=${issueid}`,
            dataset: setUploadedFiles,
          },
        ],
      },
      setSuccessMessage: setSuccessMessageDelete,
    };
    deleteDataWithFetchV2(parameter);
  };

  const handleCancelClick = () => {
    setEditMode(false);
    window.location.reload();
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
      setSuccessMessage(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [successMessageFileUpload]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
      setSuccessMessageDelete(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [successMessageFileDelete]);

  return (
    <>
      {isDeleteModalOpen && (
        <div className="popupATP">
          <div className="subpopupATP">
            <p>Are you sure you want to delete this file?</p>
            <div className="subpopupBtnDiv">
              <button onClick={confirmDeleteFile}> Delete</button>
              <button onClick={handleDeleteModalClose}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}
      {successMessageFileUpload && (
        <p className="successmessage">File Uploaded SuccessFully</p>
      )}
      {successMessageFileDelete && (
        <p className="successmessage">File Deleted SuccessFully</p>
      )}

      <RenderIcons homeRoutes={issueTracking} />

      <NavRow name={editMode ? "Edit Issue Details" : "Issue Details"} />

      <section className="navroutes">
        <div
          className="issues_detail_section"
          style={{ width: tableSize ? "100%" : "100%" }}
          onDoubleClick={handleEditSave}
        >
          <div className="backToReport">
            <button
              className="cmn_btn"
              onClick={() => UpdateIssue()}
              style={{ backgroundColor: editMode ? "#65CC5C" : "#7A7A7A" }}
              disabled={!editMode}
            >
              <svg
                width="12"
                height="10"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.72621L3.18182 6.90803L9 1.08984"
                  stroke="white"
                  stroke-width="1.45455"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Save
            </button>

            <button
              className="cmn_btn"
              style={{ backgroundColor: "#5571DF" }}
              onClick={() => handleEditSave()}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.83958 2.4008L3.36624 8.19413C3.15958 8.41413 2.95958 8.84746 2.91958 9.14746L2.67291 11.3075C2.58624 12.0875 3.14624 12.6208 3.91958 12.4875L6.06624 12.1208C6.36624 12.0675 6.78624 11.8475 6.99291 11.6208L12.4662 5.82746C13.4129 4.82746 13.8396 3.68746 12.3662 2.29413C10.8996 0.914129 9.78624 1.4008 8.83958 2.4008Z"
                  stroke="white"
                  stroke-width="1.2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.92676 3.36719C8.21342 5.20719 9.70676 6.61385 11.5601 6.80052"
                  stroke="white"
                  stroke-width="1.2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Edit
            </button>

            {editMode && (
              <button
                className="cmn_btn"
                style={{ backgroundColor: "#d6d6d6", color: "#000" }}
                onClick={() => handleCancelClick()}
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L10.9989 10.9989"
                    stroke="#292D32"
                    stroke-width="1.76758"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M1.00107 10.9989L11 1"
                    stroke="#292D32"
                    stroke-width="1.76758"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Cancel Edit
              </button>
            )}

            {/* {editMode ? (
              <IconButton type="save" tooltip="Save" onClick={UpdateIssue} />
            ) : (
              <IconButton type="edit" tooltip="Edit" onClick={handleEditSave} />
            )} */}

            {/* <WindowComponent onDelete={onDelete} /> */}
          </div>

          <div className="issue_detail_grid_container">
            <div className="issue_detail_grid_item">
              <h4>Overview</h4>
              <div className="lng_lbl_inpt">
                <label>Title</label>
                <input
                  type="text"
                  placeholder="Title"
                  value={objective}
                  readOnly={!editMode}
                  onChange={(e) => setTitle(e.target.value)}
                  style={{
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  }}
                />
              </div>{" "}
              <AuditReportingSeclector
                apiUrl="/api/audit_universe/admin-audit-team/"
                setSelectorId={setOwner}
                setSelectorError={setLevel1OwnerError}
                selectorError={level_1_ownerError}
                Name="name"
                title="Owner"
                editMode={editMode}
                initialId={APIData?.owner ? APIData?.owner?.id : ""}
                initialName={APIData?.owner ? APIData?.owner?.name : ""}
              />
              <AuditReportingSeclector
                apiUrl="/api/audit_universe/business-stakeholders/"
                setSelectorId={setAssign_to_id}
                setSelectorError={setAssign_to_idError}
                selectorError={assign_to_idError}
                Name="name"
                title="Assign to"
                editMode={editMode}
                initialId={APIData?.assign_to_1 ? APIData?.assign_to_1.id : ""}
                initialName={
                  APIData?.assign_to_1 ? APIData?.assign_to_1.name : ""
                }
              />
            </div>

            <div className="issue_detail_grid_item">
              <h5>Issue Detail</h5>

              <div className="lbl_txa">
                <label>Issue description</label>
                <textarea
                  type="text"
                  placeholder="Issue description"
                  value={summary}
                  readOnly={!editMode}
                  onChange={(e) => setSummary(e.target.value)}
                  style={{
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  }}
                />
              </div>

              <div className="lbl_inpt">
                <label>Issue Rating</label>
                <input
                  type="text"
                  value={rating}
                  readOnly={!editMode}
                  onChange={(e) => setObservationRating(e.target.value)}
                  style={{
                    borderRadius: "5px",
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  }}
                />
              </div>
            </div>

            <div className="issue_detail_grid_item_span_2">
              <h5>Management Response</h5>

              <div className="lbl_txa">
                <label>Action Plan</label>
                <textarea
                  type="text"
                  placeholder="Action Plan"
                  value={management_response}
                  readOnly={!editMode}
                  onChange={(e) => setActionPlan(e.target.value)}
                  style={{
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  }}
                />
              </div>

              <div className="lbl_inpt">
                <label>Due Date</label>
                <input
                  type="date"
                  value={convertDate(management_response_due_date)}
                  readOnly={!editMode}
                  onChange={(e) => setActionDueDate(e.target.value)}
                  style={{
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  }}
                />
              </div>

              <AuditReportingSeclector
                apiUrl="/api/audit_universe/business-stakeholders/"
                setSelectorId={setLevel2Owner}
                setSelectorError={setLevel2OwnerError}
                selectorError={level_2_ownerError}
                Name="name"
                title={"Level 2 Owner"}
                editMode={editMode}
                initialId={APIData?.assign_to_2 ? APIData?.assign_to_2.id : ""}
                initialName={
                  APIData?.assign_to_2 ? APIData?.assign_to_2.name : ""
                }
              />
            </div>
          </div>
          <div className="fw_grid_container">
            <div className="grid_item">
              <div className="obs_file_upload_header">
                <h2 style={{ fontSize: "14px", fontFamily: "interbold" }}>
                  File Upload
                </h2>

                {showSelectFileButton ? (
                  <button
                    className="select-file-button"
                    onClick={() => document.getElementById("fileInput").click()}
                  >
                    <img src={createBtn} alt="" />
                    <input
                      type="file"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={handleFileSelect}
                    />
                  </button>
                ) : (
                  <button
                    style={{ backgroundColor: "#5771DF" }}
                    className="cmn_btn"
                    onClick={handleFileUpload}
                  >
                    Upload File
                  </button>
                )}
              </div>

              {selectedFiles?.map((file, index) => (
                <div key={index}>
                  <h6>{file.name}</h6>
                </div>
              ))}
              <div className="uploaded_issue_files_section">
                {uploadedFiles.length > 0 ? (
                  uploadedFiles.map((document, index) => (
                    <div key={index} className="uploaded_issue_file">
                      <h6>{document.documents?.filename}</h6>
                      <button
                        className="cmn_btn_icn"
                        style={{ backgroundColor: "#FE2768" }}
                        onClick={() =>
                          handleDeleteModalOpen(
                            document.documents?.associated_with_id
                          )
                        } // Pass the correct ID
                      >
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14 3.98568C11.78 3.76568 9.54667 3.65234 7.32 3.65234C6 3.65234 4.68 3.71901 3.36 3.85234L2 3.98568"
                            stroke="white"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M5.66699 3.31203L5.81366 2.4387C5.92033 1.80536 6.00033 1.33203 7.12699 1.33203H8.87366C10.0003 1.33203 10.087 1.83203 10.187 2.44536L10.3337 3.31203"
                            stroke="white"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12.5669 6.09375L12.1336 12.8071C12.0603 13.8537 12.0003 14.6671 10.1403 14.6671H5.86026C4.00026 14.6671 3.94026 13.8537 3.86693 12.8071L3.43359 6.09375"
                            stroke="white"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M6.88672 11H9.10672"
                            stroke="white"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M6.33301 8.33203H9.66634"
                            stroke="white"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  ))
                ) : (
                  <h6>No files available</h6>
                )}
              </div>
            </div>

            {/* 6 cell */}
            <div className="comment_section fw_grid_item">
              <div className="heading_toggle_aq">
                <h2 style={{ fontSize: "14px", fontFamily: "interbold" }}>
                  Comments & Activity
                </h2>

                <div className="togglecon">
                  <div
                    className="toggle-option"
                    onClick={() => setIsComments(true)}
                    style={{
                      backgroundColor: isComments ? "#fff" : "",
                      borderRadius: "1vw",
                    }}
                  >
                    Comments
                  </div>

                  <div
                    className="toggle-option"
                    onClick={() => setIsComments(false)}
                    style={{
                      backgroundColor: !isComments ? "#fff" : "",
                      borderRadius: "1vw",
                    }}
                  >
                    Activity
                  </div>
                </div>
              </div>

              {isComments ? (
                <>
                  <div className="initial_comment">
                    <input
                      type="text"
                      placeholder="Enter a comment..."
                      value={inputValue}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      style={{ width: "90%" }}
                    />
                    <div className="sendComm">
                      <img
                        onClick={handleAddComment}
                        src={sendComment}
                        alt="Send Comment"
                      />
                    </div>
                  </div>

                  <div className="all_comments">
                    {taskData
                      .slice()
                      .reverse()
                      .map((comment, index) => {
                        const { text, date, time } = comment;
                        return (
                          <div className="comment_wrapper_" key={index}>
                            <div className="comment_head">
                              <div className="avatar_name_AQ">
                                <SuperAvatar
                                  firstName={
                                    comment.user
                                      ? comment.user.name
                                      : `${avaUserName} ${lastName}`
                                  }
                                  classOfAvatar="Avtaraudit"
                                />
                                <h5>
                                  {comment.user
                                    ? comment.user.name
                                    : `${avaUserName} ${lastName}`}
                                </h5>
                              </div>
                              <div className="time_date_AQ">
                                <span>
                                  <sub>{daysSince(comment.created_at)}</sub>
                                </span>
                              </div>
                            </div>
                            <div className="comment_AQ">
                              <p>{text}</p>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </>
              ) : (
                <div className="all_comments">
                  {activitylog
                    .sort(
                      (a, b) => new Date(b.created_at) - new Date(a.created_at)
                    )
                    .map((activity, index) => (
                      <div className="activity_wrapper_com">
                        <div className="activity_head">
                          <span className="status_complete">
                            {activity.action}
                          </span>
                          <div className="time_date_AQ">
                            <span>
                              <sub>{convertDate(activity.created_at)}</sub>
                            </span>
                          </div>
                        </div>

                        <div className="activity_AQ">
                          <div className="activity_transaction">
                            <div className="avatar_name_AQ">
                              <SuperAvatar
                                firstName={activity.action_by_id.name}
                                classOfAvatar="Avtaraudit"
                              />
                              <h5>{activity.action_by_id.name}</h5>
                            </div>

                            <h4 style={{ wordWrap: "break-word" }}>
                              {activity.log.length > 30
                                ? `${activity.log.substring(0, 30)}...`
                                : activity.log}
                            </h4>

                            {activity.action !== "CREATE" &&
                              activity.action !== "UPDATE" && (
                                <div className="avatar_name_AQ">
                                  <SuperAvatar
                                    firstName={"Affan Shaikh"}
                                    classOfAvatar="Avtaraudit"
                                  />
                                  <h5> Affan Shaikh </h5>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>

          <div className="button_container">
            <button
              className="cmn_btn"
              style={{ backgroundColor: "#FE2768" }}
              onClick={onDelete}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 3.98568C11.78 3.76568 9.54667 3.65234 7.32 3.65234C6 3.65234 4.68 3.71901 3.36 3.85234L2 3.98568"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.66699 3.31203L5.81366 2.4387C5.92033 1.80536 6.00033 1.33203 7.12699 1.33203H8.87366C10.0003 1.33203 10.087 1.83203 10.187 2.44536L10.3337 3.31203"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.5669 6.09375L12.1336 12.8071C12.0603 13.8537 12.0003 14.6671 10.1403 14.6671H5.86026C4.00026 14.6671 3.94026 13.8537 3.86693 12.8071L3.43359 6.09375"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.88672 11H9.10672"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.33301 8.33203H9.66634"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Delete
            </button>
            <button
              className="cmn_btn"
              style={{ backgroundColor: editMode ? "#5757df" : "#7A7A7A" }}
              onClick={ChangeDate}
              disabled={!editMode}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 3.98568C11.78 3.76568 9.54667 3.65234 7.32 3.65234C6 3.65234 4.68 3.71901 3.36 3.85234L2 3.98568"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M5.66699 3.31203L5.81366 2.4387C5.92033 1.80536 6.00033 1.33203 7.12699 1.33203H8.87366C10.0003 1.33203 10.087 1.83203 10.187 2.44536L10.3337 3.31203"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12.5669 6.09375L12.1336 12.8071C12.0603 13.8537 12.0003 14.6671 10.1403 14.6671H5.86026C4.00026 14.6671 3.94026 13.8537 3.86693 12.8071L3.43359 6.09375"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.88672 11H9.10672"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.33301 8.33203H9.66634"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Extend Date
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Issuedetailpage;
