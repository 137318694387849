import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../../css/viewuser.css";
import "../riskAssesment/riskassessment.css";
import createBtn from "../../../images/createbutton.svg";
import {
  justFetchDataV2,
  convertDate,
  deleteDataWithFetchV2,
  postDataV2,
} from "../../apiUtils";
import { auditRoutes,auditRoutesBu } from "../../subroutes";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";
import BounceLoader from "react-spinners/BounceLoader";


const RiskassesmentTable = ({ tableSize }) => {
  const location = useLocation();
  const [APIAllData, setAPIAllData] = useState([]);
  const [Errors, setErrors] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const successMessage = location.state?.successMessage;
  const [showMessage, setShowMessage] = useState(!!successMessage);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  const is_Admin = localStorage.getItem("is_Admin");
  const [successMessageQuestion, setSuccessMessage] = useState(false);
  const [title, setTitle] = useState("");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
      setSuccessMessage(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const parameter ={
      url : `/api/audit_universe/risk-assessments/`,
      setterFunction :(data) => {setAPIAllData(data)},
      setErrors : (error) => {setErrors(error)},
      setLoading: setLoading,
    }
    
    justFetchDataV2(parameter);
  }, []);

  const Viewuser = (data) => {
    const raid = data.id;
    console.log(data);
    navigate(`/AuditUniverse/riskAssesment/${raid}`);
  };

  const NavigateToAnsQAPWA = (data) => {
    const raid = data.id;
    console.log(data);
    navigate(`/AuditUniverse/riskAssesment/${raid}`);
  };

  const [filters, setFilters] = useState({
    AuditTitle: "",
    assessment_date: "",
    status: "",
  });

  const RiskAssesssmentStatus = (status) => {
    if (status === "Open") {
      return <span className="status_todo">{status}</span>;
    } else if (status === "Closed") {
      return <span className="status_complete">{status}</span>;
    } else {
      return <span className="status_default">{status}</span>; // Default case
    }
  };

  const createRisk = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const parameter = {
      url: `/api/audit_universe/risk-assessments/`,
      payload: {
        title: title || `Risk Assessment For The Year Of ${year}`,
        assessment_date: `${year}-${month}-${day}`,
        details: "start risk assessment now",
      },
      setErrors: setErrors,
    };

    postDataV2(parameter);
    setShowPopup(false); 
  };

  const handlePopup = () => {
    setShowPopup(true);
  };
  const handlePopupCloseNocreate = () => {
    setShowPopup(false)
  }

  const [selectedRisk, setSelectedRisk] = useState(null);

  const handlePopupClose = (data) => {
    setSelectedRisk(data);
    setShowDeletePopup(true);
  };

  const handlePopupCloseNo = () => {
    setShowDeletePopup(false);
  };

  const createIcon = {
    createBtn: createBtn,
    createRisk: createRisk,
    handlePopup: handlePopup,
    handlePopupCloseNo: handlePopupCloseNo,
    showPopup: showPopup,
  };

  const handleFilterChange = (filterKey, e) => {
    const value = e.target.value.toLowerCase();
    setFilters({ ...filters, [filterKey]: value });
  };

  const filteredData = useMemo(() => {
    return APIAllData.filter((data) => {
      const title = data?.title?.toLowerCase() || "";
      const status = data?.status?.toLowerCase() || "--";
      const assessmentDate = data?.assessment_date?.toLowerCase() || "";

      return (
        (!filters.AuditTitle || title.includes(filters.AuditTitle)) &&
        (!filters.assessment_date ||
          assessmentDate.includes(filters.assessment_date)) &&
        (!filters.status || status.includes(filters.status))
      );
    });
  }, [APIAllData, filters]);

  const deleterisksassessmentdata = (data) => {
    const raid = data?.id;
    const parameter ={
      url : `/api/audit_universe/risk-assessments/${raid}/`,
      navigate : navigate,
      setErrors : setErrors,
      tofetch: {
        items: [
          {
            fetchurl: "/api/audit_universe/risk-assessments/",
            dataset: setAPIAllData,
          },
        ],
        setSuccessMessage: setSuccessMessage,
        setErrors: setErrors,
      },
    }
    deleteDataWithFetchV2(parameter);
    setShowDeletePopup(false)
  };

  return (
    <>
      {Errors && <p className="errormessage">{Errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}
      {successMessageQuestion && (
        <p className="successmessage">Risk Assessment Deleted Successfully.</p>
      )}
      {showDeletePopup && (
        <div className="popupATP">
          <div className="subpopupATP">
            <p>Do you want to delete this Risk Assessment?</p>
            <div className="subpopupBtnDiv">
              <button onClick={() => deleterisksassessmentdata(selectedRisk)}>
                Yes
              </button>
              <button onClick={handlePopupCloseNo}>No</button>
            </div>
          </div>
        </div>
      )}

{showPopup && (
  <div className="create_ra_modal">
    
      <h2 style={{ fontSize: "16px" }}>Create a New Risk Assessment?</h2>
      <div className="lng_lbl_inpt">
        <label>Enter Title</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      <div className="btn-option">
        <button className="cmn_btn" style={{backgroundColor:"#65CC5C"}} onClick={createRisk}>
        <svg
                  width="12"
                  height="10"
                  viewBox="0 0 10 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 4.72621L3.18182 6.90803L9 1.08984"
                    stroke="white"
                    stroke-width="1.45455"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>Create</button>
        <button className="cmn_btn" style={{backgroundColor:"#FE2768"}} onClick={handlePopupCloseNocreate}>
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 1L10.9989 10.9989" stroke="white" stroke-width="1.76758" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1.00107 10.9989L11 1" stroke="white" stroke-width="1.76758" stroke-linecap="round" stroke-linejoin="round"/>
</svg>Cancel</button>
      </div>
      </div>
    
  </div>
)}
      <RenderIcons homeRoutes={Object.keys(userRoles).some(key => key.includes("Business"))
 && !is_Admin ?auditRoutesBu:auditRoutes} />
      <NavRow name={"Risk Assessment"} createIcon={createIcon} Create={"Create"} idtrue={true} />


      <div className="tablecontainer">

        <table style={{ width: tableSize ? "80vw" : "90.5vw" }}>
          <thead style={{ position: "sticky", top: "0", zIndex: 1000 }}>

            <tr className="tablehead">

              <th>
                <h4>UID</h4>
              </th>

              <th>
                <h4 className="thhead"> Name</h4>
                <input
                  type="text"
                  placeholder="--"
                  className="thfilter"
                  onChange={(e) => handleFilterChange("AuditTitle", e)}
                  value={filters.AuditTitle}
                />
              </th>

              <th>
                <h4 className="thhead"> Year</h4>
                <input
                  type="text"
                  placeholder="--"
                  className="thfilter"
                  onChange={(e) => handleFilterChange("assessment_date", e)}
                  value={filters.assessment_date}
                />
              </th>

              <th>
                <h4 className="thhead"> Status</h4>
                <input
                  type="text"
                  placeholder="--"
                  className="thfilter"
                  onChange={(e) => handleFilterChange("status", e)}
                  value={filters.status}
                />
              </th>

              <th>
                <h4 className="thhead"> Action</h4>
              </th>

            </tr>

          </thead>

          <tbody>

            {loading ? (
              <div className="spinner-bounce-loader">
                <BounceLoader color="#a6fafa" />
              </div>

            ) : filteredData.length === 0 ? (
              <p className="data-error-text">No data found</p>
            ) : (
              filteredData.filter(data => !Object.keys(userRoles).some(key => key.includes("Business")) && is_Admin || data.status === "Open").sort((u, v) => u.id - v.id).map((data, index) => (
                <tr key={index}>
                  <td>
                    <span className="status_todo">{data.id}</span>
                  </td>
                  <td onClick={() => Viewuser(data)} >{data?.title}</td>
                  <td onClick={() => Viewuser(data)}>{convertDate(data?.assessment_date)}</td>
                  <td onClick={() => Viewuser(data)}>{RiskAssesssmentStatus(data?.status)}</td>
                  <td>
                    <button
                      className="cmn_btn"
                      style={{ backgroundColor: "#FE2768" }}
                      onClick={() => handlePopupClose(data)}
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14 3.98568C11.78 3.76568 9.54667 3.65234 7.32 3.65234C6 3.65234 4.68 3.71901 3.36 3.85234L2 3.98568"
                          stroke="white"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M5.66699 3.31203L5.81366 2.4387C5.92033 1.80536 6.00033 1.33203 7.12699 1.33203H8.87366C10.0003 1.33203 10.087 1.83203 10.187 2.44536L10.3337 3.31203"
                          stroke="white"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12.5669 6.09375L12.1336 12.8071C12.0603 13.8537 12.0003 14.6671 10.1403 14.6671H5.86026C4.00026 14.6671 3.94026 13.8537 3.86693 12.8071L3.43359 6.09375"
                          stroke="white"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.88672 11H9.10672"
                          stroke="white"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M6.33301 8.33203H9.66634"
                          stroke="white"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>{" "}
                      Delete
                    </button>

                  </td>
                </tr>
              )))}

          </tbody>

        </table>

      </div>

      <div className="cardscontainer">

        {loading ? (
          <div className="spinner-bounce-loader">
            <BounceLoader color="#a6fafa" />
          </div>
        ) : filteredData.length === 0 ? (
          <p className="data-error-text">No data found</p>
        ) : (
          filteredData.sort((u, v) => u.id - v.id).map((data, index) => (
            <div
              className="deptcard"
              onClick={() => NavigateToAnsQAPWA(data)}
              key={data.id} // Use dynamic id as key
            >
              <h2>
                {data?.title ? (
                  data.title
                ) : (
                  <p className="deptplacehlder">Department Name</p>
                )}
              </h2>
              <h6>
                {data?.department_head?.name ? (
                  data.department_head.name
                ) : (
                  <p className="deptplacehlder">Keystakeholder</p>
                )}
              </h6>

            </div>
          ))
        )}

      </div>




    </>
  );
};

export default RiskassesmentTable;
