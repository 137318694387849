import axios from "axios";
import React, { useState, useEffect } from "react";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import WindowComponent from "../../WindowComponent";
import SidebarMenu from "../../SidebarMenu";
import createBtnfile from "../../../images/createbutton.svg";
import "../../../css/viewuser.css";
import cut from "../../../images/cut.svg";
import cut_button from "../../../images/cut.svg";
import Breadcrumb from "../../Breadcrumb";
import "../../../css/createentity.css";
import Avataraudit from "./AvatarAudit";
import add_button from "../../../images/add_button.svg";
import Button from "../../user/Button";
import {
  postDataWithFile,
  fetchDataV2,
  fetchDataObjectV2,
  updateReturnResponse,
  postDataReturnId,
  deleteDataV2,
} from "../../apiUtils";
import SingleOptionSelector from "../../SingleOptionSelector";
import { auditRoutes } from "../../subroutes";
import pencil from "../../../images/edit_pencil.svg";
import save_details from "../../../images/saveBtn.svg";
import backlogo from "../../../images/backLogo.svg";
import LongMultiOptionSelector from "../../LongMultiOptionSelector";
import MultiOptionSelector from "../../MultiOptionSelector";
import IconButton from "../../iconbutton/IconButton";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";
const ViewAudit = ({ tableSize }) => {
  const [loading, setLoading] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [errors, setErrors] = useState("");
  const [tmpId, setTmpId] = useState("");
  const [rcmId, setRcmId] = useState("");
  const [showRiskCMPopup, setShowRiskCMPopup] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [selectedKeyError, setSelectedKeyError] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();

  const [APIData, setAPIData] = useState([]);
  const [APIDatacount, setAPIDataCount] = useState([]);
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("idle");
  const [uploadProgress, setUploadProgress] = useState(0);
  const location = useLocation();

  const [showSelectFileButton, setShowSelectFileButton] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [successMessageFileUpload, setSuccessMessage] = useState(false);

  const { id } = useParams();
  useEffect(() => {
    const parameter = {
      url: `/api/audit_universe/audit-entities/${id}/`,
      setterFunction: setAPIData,
      setErrors: setErrors,
      setLoading: setLoading,
    };
    fetchDataObjectV2(parameter);
  }, [id]);
  useEffect(() => {
    const parameter = {
      url: `/api/audit_universe/questionnaire-templates/${id}/count/`,
      setterFunction: setAPIDataCount,
      setErrors: setErrors,
      setLoading: setLoading,
    };
    fetchDataObjectV2(parameter);
  }, [id]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
      setSuccessMessage(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [successMessageFileUpload]);

  useEffect(() => {
    setTmpId(
      APIData.questionnaire_template ? APIData.questionnaire_template.id : ""
    );
  }, [APIData]);
  useEffect(() => {
    setRcmId(APIData.rcm_template ? APIData.rcm_template.id : "");
  }, [APIData]);

  const Id = APIData.id;
  const auditName = APIData.name;
  const auditDescription = APIData.description;
  const auditSelectedKeysid = APIData.key_stakeholder
    ? APIData.key_stakeholder.id
    : "";
  const auditSelectedKeysname = APIData.key_stakeholder
    ? APIData.key_stakeholder.name
    : "";
  const buUnitsString = APIData.audit_unit;

  useEffect(() => {
    const auditSelectedKeysid = APIData.key_stakeholder
      ? APIData.key_stakeholder.id
      : "";
    const auditSelectedKeysname = APIData.key_stakeholder
      ? APIData.key_stakeholder.name
      : "";
    setKey_stakeholder_id(auditSelectedKeysid);
    setSelectedDepts([auditSelectedKeysname]);
    const buUnitsString = APIData.audit_unit;

    let buUnits = [];
    if (buUnitsString) {
      try {
        buUnits = buUnitsString;
      } catch (e) {
        console.error("Failed to parse buUnitsString:", e);
      }
    }
    const ids = buUnits.map((item) => item.business_units_id);
    const Names = buUnits.map((item) => item.name);

    setBusiness_unit_id(ids);
    const auditSelectedKeysOther = APIData.other_stakeholders
      ? APIData.other_stakeholders
      : [];
    const otherIds = auditSelectedKeysOther.map((item) => item.id);
    const otherNames = auditSelectedKeysOther.map((item) => item.name);
    setSelectedUnits(Names);
    setOther_stakeholders_id(otherIds);
    setSelectedOtherKey(otherNames);
    setName(auditName);
    setDescription(auditDescription);
    const parameter = {
      url: `/api/audit_universe/audit-entities/${id}/history/`,
      setterFunction: setHistoryData,
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
    setName(auditName || "");
    setDescription(auditDescription || "");
  }, [buUnitsString]);

  const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  const is_Admin = localStorage.getItem("is_Admin");
  const Roles = Object.keys(userRoles).some((key) =>
    key.includes("Audit Team")
  );

  const handleFirstNameChange = (event) => {
    const newName = event.target.value;
    setFirstName(newName);
    setFirstNameError(false);
    setName(newName);

    if (newName.length > 0) {
      const initialLetter = newName[0].toUpperCase();
    }
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const updateAPIData = (e) => {
    e.preventDefault();
    if (!name || !description || !key_stakeholder_id) {
      setFirstNameError(!name ? true : false);
      setDescriptionError(!description ? true : false);
      setSelectedKeyError(!key_stakeholder_id ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    const parameter = {
      url: `/api/audit_universe/audit-entities/${Id}/`,
      payload: {
        name,
        description,
        business_units_id,
        key_stakeholder_id,
        other_stakeholders_id,
      },
      setErrors: setErrors,
      setSuccessCallback: (data) => {
        const successMessageColor = "#4285F4";
        navigate(`/AuditUniverse/auditEntities/${data.id}/`, {
          state: {
            successMessage: "Successfully Created!",
            successMessageColor,
          },
        });
        window.location.reload();
      },
    };

    updateReturnResponse(parameter);
  };

  const onDelete = (e) => {
    e.preventDefault();

    const parameter = {
      url: `/api/audit_universe/audit-entities/${Id}`,
      navigate: navigate,
      setErrors: setErrors,
      navUrl: `/AuditUniverse/auditEntities`,
    };

    deleteDataV2(parameter);
  };
  const onDeleteQuestionnsire = (e) => {
    e.preventDefault();

    const parameter = {
      url: `/api/audit_universe/questionnaire-templates/${Id}/delete`,
      navigate: navigate,
      setErrors: setErrors,
      navUrl: `/AuditUniverse/auditEntities/${Id}`,
    };

    deleteDataV2(parameter);
  };

  const handleChange = (event) => {
    const newDescription = event.target.value;
    setDescription(newDescription);
    setDescriptionError(false);
    event.target.style.height = "auto";
    event.target.style.height = event.target.scrollHeight + "px";
  };

  const [selectedUnits, setSelectedUnits] = useState([]);
  const [business_unit_id, setBusiness_unit_id] = useState([]);
  const [business_units_id, setBusinessUnit_id] = useState([]);

  const [selectedOtherKey, setSelectedOtherKey] = useState([]);
  const [other_stakeholders_ids, setOther_stakeholders_id] = useState([]);
  const [other_stakeholders_id, setOther_stakeholdersId] = useState([]);

  const [departments, setDepartments] = useState([]);
  const [selectedKeys, setSelectedDepts] = useState([]);
  const [key_stakeholder_id, setKey_stakeholder_id] = useState("");

  const handleRemoveSelectedKey = (unitToRemove) => {
    setSelectedKeyError(true);
    setSelectedDepts(selectedKeys.filter((unit) => unit !== unitToRemove));
  };

  const handleFileChange = (event) => {
    // Get the selected file
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFiles((prevSelectedFiles) => {
        if (!Array.isArray(prevSelectedFiles)) {
          console.error("selectedFiles is not an array", prevSelectedFiles);
          return [file];
        }
        return [...prevSelectedFiles, file];
      });
      setShowSelectFileButton(false);
    }
  };

  const handleUpload = () => {
    if (selectedFiles.length === 0) {
      setErrors("Please select a file to upload.");
      return;
    }

    const parameter = {
      url: "/api/audit_universe/questions/upload/",
      files: selectedFiles,
      data: {
        audit_entity_id: APIData?.id,
      },
      setErrors: setErrors,
      setSuccessMessage: setSuccessMessage,
    };

    postDataWithFile(parameter);
    setUploadStatus("success");
    setSelectedFiles(null); // Reset properly
    setShowSelectFileButton(true);
  };

  console.log(APIData);
  const handlePopUpYes = () => {
    const parameter = {
      url: `/api/audit_universe/rcm-template/`,
      payload: {
        audit_entity_id: APIData.id,
      },

      navigate: navigate,
      setErrors: setErrors,
      navUrl: `/auditUniverse/auditEntities/${id}/${rcmId}/`,
      setSuccessCallback: (data) => {
        console.log("data : ", data);
        const successMessageColor = "#4285F4";
        navigate(`/auditUniverse/auditEntities/${id}/${data.id}/`, {
          state: {
            successMessage: "Successfully Created!",
            successMessageColor,
          },
        });
      },
    };

    postDataReturnId(parameter);
    setShowRiskCMPopup(false);
  };
  console.log(rcmId);
  const toggleRiskCMPopup = () => {
    if (!rcmId) {
      setShowRiskCMPopup(!showRiskCMPopup);
    } else {
      navigate(`/auditUniverse/auditEntities/${id}/${rcmId}`);
    }
  };

  const createQuestionnaire = (quid) => {
    navigate(`/auditUniverse/auditEntities/${id}/${quid}`, {
      // state: { data: data },
    });
  };

  const SelectedKey = ({ name, onRemove }) => (
    <div
      className={
        editMode ? "selected-unit-button" : "selected-unit-button-View"
      }
    >
      {editMode ? (
        <>
          <span>{name}</span>
          <button
            disabled={!editMode}
            onClick={() => {
              onRemove(name);
              setKey_stakeholder_id("");
              setSelectedKeyError(key_stakeholder_id ? true : false);
            }}
          >
            <img src={cut} />
          </button>
        </>
      ) : (
        <>
          <Avataraudit
            style={{ height: "0.6vh", width: "0.6vw" }}
            firstName={name}
          />
          <span>{name}</span>
        </>
      )}
    </div>
  );
  const handleCancelClick = () => {
    setEditMode(false);
    window.location.reload();
  };

  const handlePopUp = () => {
    setShowRiskCMPopup(false);
  };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessageFileUpload && (
        <p className="successmessage">File Uploaded SuccessFully</p>
      )}
      {showRiskCMPopup && (
        <div
          className="popupRCM"
          style={{
            position: "absolute",
            top: "10vh",
            left: "30vw",
            height: "auto",
            zIndex: "1000",
            backgroundColor: "#14172A",
            padding: "2vw 6vw",
            borderRadius: "10px",
          }}
        >
          <div
            className="popup-innerRCM"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <p style={{ fontFamily: "intersemibold", color: "#fff" }}>
              There is NO Template Do You want to Create ?
            </p>
            <div style={{ display: "flex", gap: "1vw" }}>
              <button
                onClick={handlePopUp}
                style={{
                  width: "10vw",
                  padding: ".5vw 3vw",
                  fontFamily: "intersemibold",
                  textAlign: "center",
                  margin: "1vw 0vw",
                }}
              >
                close
              </button>
              <button
                onClick={handlePopUpYes}
                style={{
                  width: "10vw",
                  padding: ".5vw 3vw",
                  fontFamily: "intersemibold",
                  textAlign: "center",
                  margin: "1vw 0vw",
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      {uploadStatus === "uploading" && (
        <div className="progressmessageRCM">
          <p>Uploading: {uploadProgress}%</p>
        </div>
      )}
      {uploadStatus === "success" && (
        <div className="successmessageRCM">
          <p>File uploaded successfully!</p>
        </div>
      )}
      {uploadStatus === "error" && (
        <div className="errormessageRCMbadRequest">
          <p>Failed to upload file: {errors}</p>
        </div>
      )}

      <section className="navroutes">
        <RenderIcons homeRoutes={auditRoutes} />
        <NavRow name={editMode ? "Edit " + name : name} />

        {loading ? (
          <div className="spinner-bounce-loader">
            <BounceLoader color="#a6fafa" />
          </div>
        ) : APIData.id === 0 ? (
          <p className="data-error-text">No data found</p>
        ) : (
          <div
            className="allofauditentity"
            style={{ width: tableSize ? "83.5vw" : "90.5vw" }}
          >
            <form onDoubleClick={handleEditClick}>
              <div className="lbl_inpt">
                <label>Name</label>
                <input
                  style={{
                    border: firstNameError ? "1px solid #FF4646" : "",
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  }}
                  type="text"
                  placeholder="Jhon"
                  value={name}
                  id="firstName"
                  onChange={handleFirstNameChange}
                  readOnly={!editMode}
                />
              </div>

              <SingleOptionSelector
                apiUrl="/api/audit_universe/admin-business-stakeholders/"
                setSelectorId={setKey_stakeholder_id}
                setSelectorError={setSelectedKeyError}
                selectorError={selectedKeyError}
                Name="name"
                title="Key Stakeholder"
                initialId={auditSelectedKeysid}
                initialName={auditSelectedKeysname}
                editMode={editMode}
              />

              <div className="lbl_txa">
                <label>Description</label>
                <textarea
                  style={{
                    border: descriptionError ? "1px solid #FF4646" : "",
                    backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                  }}
                  placeholder="Senior Developer"
                  value={description}
                  onChange={handleChange}
                  readOnly={!editMode}
                />
              </div>

              <LongMultiOptionSelector
                apiUrl="/api/system/business-units/"
                setSelectorId={setBusinessUnit_id}
                Name="name"
                title="Business Unit "
                initialIds={business_unit_id}
                initialNames={selectedUnits}
                editMode={editMode}
              />

              <LongMultiOptionSelector
                apiUrl="/api/audit_universe/business-stakeholders/"
                setSelectorId={setOther_stakeholdersId}
                Name="name"
                title="Other Key Stakeholders"
                initialIds={other_stakeholders_ids}
                initialNames={selectedOtherKey}
                editMode={editMode}
              />
            </form>

            <div className="button_container">
              <button
                className="cmn_btn"
                style={{ backgroundColor: editMode ? "#65CC5C" : "#7A7A7A" }}
                disabled={!editMode}
                onClick={updateAPIData}
              >
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 10 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 4.72621L3.18182 6.90803L9 1.08984"
                    stroke="white"
                    stroke-width="1.45455"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                Save
              </button>

              {editMode && (
                <button
                  onClick={handleCancelClick}
                  className="cmn_btn"
                  style={{ backgroundColor: "#d6d6d6", color: "#000" }}
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L10.9989 10.9989"
                      stroke="#292D32"
                      stroke-width="1.76758"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M1.00107 10.9989L11 1"
                      stroke="#292D32"
                      stroke-width="1.76758"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Cancel Edit
                </button>
              )}

              <WindowComponent onDelete={onDelete} text="Delete Audit Entity" />
            </div>

            <div
              className="questionnairesection"
              style={{ display: Roles ? "" : "" }}
            >
              <h6>Questionnaire</h6>

              <div
                className="quecrud"
                style={{ width: tableSize ? "80vw" : "90.5vw" }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  {/* <div className="uploadfile">
                      {uploadStatus === "idle" && (
                        <>
                          <input type="file" onChange={handleFileChange} />
                          <button
                            onClick={handleUpload}
                            disabled={!file}
                            className="tempuploadBtn"
                            title="Only Accepts Excel Files."
                          >
                            Upload
                          </button>
                        </>
                      )}
                    </div> */}

                  <div className="questionnaire_file_name">
                    {selectedFiles?.map((file, index) => (
                      <>
                        <h6 key={index}>{file.name}</h6>

                        <svg
                          onClick={() => {
                            setSelectedFiles(
                              selectedFiles.filter((_, i) => i !== index)
                            );
                          }}
                          width="10"
                          height="10"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 1L10.9989 10.9989"
                            stroke="#292D32"
                            stroke-width="1.76758"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M1.00107 10.9989L11 1"
                            stroke="#292D32"
                            stroke-width="1.76758"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </>
                    ))}
                  </div>

                  {showSelectFileButton ? (
                    <button
                      className="select-file-button"
                      onClick={() =>
                        document.getElementById("fileInput").click()
                      }
                    >
                      <img src={createBtnfile} alt="" />
                      <input
                        type="file"
                        id="fileInput"
                        style={{ display: "none" }}
                        onChange={handleFileSelect}
                      />
                    </button>
                  ) : (
                    <button
                      className="cmn_btn"
                      style={{ backgroundColor: "#65CC5C" }}
                      onClick={handleUpload}
                    >
                      Upload Files
                    </button>
                  )}
                </div>
                <div className="button_container">
                  <button
                    className="cmn_btn"
                    style={{ backgroundColor: APIDatacount.question_count===0?'grey':"#5771df" }}
                    onClick={() =>
                      createQuestionnaire(APIData.questionnaire_template.id)
                    }
                    disabled={APIDatacount.question_count===0}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.0109 14.5717L18.0109 5.98745L9.42661 5.98745"
                        stroke="#fff"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.99002 18.01L17.8906 6.10938"
                        stroke="#fff"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    View
                  </button>
                  <WindowComponent
                    onDelete={onDeleteQuestionnsire}
                    text="Delete Questtionaire"
                    disabled={APIDatacount.question_count===0}
                    style={{backgroundColor:APIDatacount.question_count===0?'grey':'#FE2768'}}
                  />
                </div>

                <div className="view_update_qestionnnaire">
                  <h6>Risk Assessment Questionnaire Template</h6>

                  <div className="view_edit_upload">
                    <button>View</button>
                    <button>Edit</button>
                    <button>Upload</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="historysection">
              <h3>Risk Assessment History</h3>

              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Year</th>
                    <th>Total Impact</th>
                    <th>Total Likelihood</th>
                    <th>Risk Score</th>
                  </tr>
                </thead>

                <tbody>
                  {historyData.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <span>{item.assessment_year}</span>
                      </td>
                      <td>
                        <span>{item.total_impact}</span>
                      </td>
                      <td>
                        <span>{item.total_likelihood}</span>
                      </td>
                      <td>
                        <span>{item.risk_score}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default ViewAudit;
