import React, { useState, navigate, useEffect } from "react";
import {
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  updateDataV2,
  deleteDataV2,
  justUpdateDataV2,
} from "../../../apiUtils";
import { auditExecution } from "../../../subroutes";
import IconButton from "../../../iconbutton/IconButton";
import SingleOptionSelector from "../../../SingleOptionSelector";
import { fetchDataV2, fetchDataObjectV2 } from "../../../apiUtils";
import WindowComponent from "../../../WindowComponent";
import "../viewTask/viewtask.css";
import RenderIcons from "../../../RenderIcons";
import NavRow from "../../../NavRow";

const Viewauditmemo = ({ tableSize, APIData }) => {
  const [errors, setErrors] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [objective, setObjective] = useState(APIData.objective);
  const [audit_scope, setScope] = useState("");
  const [audit_phases, setPhases] = useState("");
  const [other_relevant_information, setRelevant] = useState("");
  const [scopeError, setScopeError] = useState(false);
  const [durationError, setDurationError] = useState(false);
  const [relevantError, setRelevantError] = useState(false);
  const [preparer_id, setPreparerId] = useState("");
  const [PreparerError, setPreparerError] = useState(false);
  const [reviewer_id, setReviewerId] = useState("");
  const [reviewerError, setReviewerError] = useState(false);
  const [assign_to_id, setAssign_To] = useState("");
  const [assignError, setAssignError] = useState(false);
  const [scheduled_start_date, setStartDate] = useState(
    APIData.scheduled_start_date
  );
  const [start_dateError, setStart_dateError] = useState(false);
  const [scheduled_end_date, setEndDate] = useState(APIData.scheduled_end_date);
  const [end_dateError, setEnd_dateError] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { id, planid } = useParams();
  const navigate = useNavigate();
  const [APIFileData, setAPIFileData] = useState([]);
  const [formData, setFormData] = useState([]);
  

  useEffect (()=>{
    if(editMode){
      if(!preparer_id){
        setPreparerError(!preparer_id ? true : false);
        setErrors("Please Fill the Field");
        return
      }
      const parameter={
        url:`/api/audit_engagement/plan/preparer/${planid}/`,
        payload:{
        preparer_id:preparer_id,
        },
        setErrors:setErrors,
      }
    
        justUpdateDataV2(parameter);
    }
  },[preparer_id])

  useEffect (()=>{
    if(editMode){
      if(!reviewer_id){
        setReviewerError(!reviewer_id ? true : false);
        setErrors("Please Fill the Field");
        return
      }
      const parameter={
        url:`/api/audit_engagement/plan/reviewer/${planid}/`,
        payload:{
          reviewer_id:reviewer_id,
        },
        setErrors:setErrors,
      }
        justUpdateDataV2(parameter);
    }
  },[reviewer_id])

  useEffect (()=>{
    if(editMode){
      if(!assign_to_id){
        setAssignError(!assign_to_id ? true : false);
        setErrors("Please Fill the Field");
        return
      }
      const parameter={
        url:`/api/audit_engagement/plan/assign/${planid}/`,
        payload:{
        assign_to_id:assign_to_id
        },
        setErrors:setErrors,
      }
        justUpdateDataV2(parameter);
    }
  },[assign_to_id])

  const postApi = (e) => {
    e.preventDefault();
    if (
      !preparer_id ||
      !reviewer_id ||
      !assign_to_id ||
      !scheduled_start_date ||
      !scheduled_end_date ||
      !id
    ) {
      setPreparerError(!preparer_id ? true : false);
      setReviewerError(!reviewer_id ? true : false);
      setAssignError(!assign_to_id ? true : false);
      setStart_dateError(!scheduled_start_date ? true : false);
      setEnd_dateError(!scheduled_end_date ? true : false);
      setScopeError(!audit_scope ? true : false);
      setDurationError(!audit_phases ? true : false);
      setRelevantError(!other_relevant_information ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    if (new Date(scheduled_end_date) < new Date(scheduled_start_date)) {
      setErrors("End Date should be greater than or equal to Start Date.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    const Parameter ={
      url :   `/api/audit_engagement/plan/${planid}`,
      payload : {
        objective,
        preparer_id,
        reviewer_id,
        assign_to_id,
        scheduled_start_date,
        scheduled_end_date,
        plan_type: "Prepare Audit Memo",
        audit_id: id,
        data: {
          memo: {
            toName: formData?.toName,
            toTitle: formData?.toTitle,
            from: formData?.from,
            subject: formData?.subject,
            date: formData?.date,
            introduction: formData?.introduction,
            planning: formData?.planning,
            fieldwork: formData?.fieldwork,
            report: formData?.report,
          },
        },
      },
      setErrors : setErrors,
      navigate : navigate,
      navUrl :  `/AuditExecution/${id}/Planning`
  }

    updateDataV2(Parameter);

   
  };

  useEffect(() => {
    const parameter={
      url:`/api/audit_engagement/audit/${id}/`,
      setterFunction:(APIData) => {
        console.log(APIData);
        setFormData({
          toName: APIData?.assign_to?.name || "",
          toTitle: APIData?.assign_to?.designation || "",
          from: "Internal Audit Department",
          subject: APIData?.audit_entity?.name || "",
          date: APIData?.phase_planning_start_date || "",
          introduction: APIData?.introduction || "",
          auditScope: APIData?.audit_scope || "",
          planning: APIData?.audit_phases?.planning || "10 Working Days",
          fieldwork: APIData?.audit_phases?.fieldwork || "40 Working Days",
          report: APIData?.audit_phases?.report || "09 Working Days",
          keyphases: APIData?.audit_phases || "",
          otherkeyphases: APIData?.other_relevant_information || "",
        });
      },
      setErrors:setErrors,
  }
    fetchDataV2(parameter);
  }, [id]);

  // useEffect(() => {
  //   const parameter={
  //     url:`/api/audit_engagement/audit/${id}/memo`,
  //     setterFunction:setAPIFileData,
  //     setErrors:setErrors,
  // }
  //   fetchDataV2(parameter);
  // }, []);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const onDelete = (e) => {
    e.preventDefault();
    const parameter = {
      url: `/api/audit_engagement/plan/${planid}`,
      navigate: navigate,
      setErrors: setErrors,
      navUrl:`/AuditExecution/${id}/Planning`
    };

    deleteDataV2(parameter);
  
  };
  const handleCancelClick = () => {
    setEditMode(false);
    window.location.reload();
  };

  function convertDate(inputDate) {
    const date = new Date(inputDate);

    date.setHours(date.getHours() - 5);
    date.setMinutes(date.getMinutes() - 30);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  function handleStartDateChange(e) {
    const localDate = new Date(e.target.value);
    const utcDate = new Date(
      localDate.getTime() + 5 * 60 * 60 * 1000 + 30 * 60 * 1000
    );
    setStartDate(utcDate.toISOString());
  }

  function handleEndDateChange(e) {
    const localDate = new Date(e.target.value);
    const utcDate = new Date(
      localDate.getTime() + 5 * 60 * 60 * 1000 + 30 * 60 * 1000
    );
    setEndDate(utcDate.toISOString());
    if (new Date(e.target.value) < new Date(scheduled_start_date)) {
      setErrors("End Date should be greater than or equal to Start Date.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
    }
  }

 



  const [APIaudit, setAudit] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {

    const parameter ={
      url :  `/api/audit_engagement/audit/${id}/`,
      setterFunction : setAudit,
      setErrors : setErrors,
      setLoading : setLoading
     }
    fetchDataObjectV2(parameter);
  
  }, [id]);

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        <RenderIcons homeRoutes={auditExecution} id={id} />

        <NavRow
          name={editMode ? "Edit Audit Memo " : "Audit Memo"}
          parentName={APIaudit?.name}
        />

        <div
          className="createauditmemo"
          style={{
            width: tableSize ? "79.5vw" : "90vw",
          }}
        >
          <form onDoubleClick={handleEditClick}>
         
            <div className="lng_lbl_inpt">
              <label>Objective</label>
              <input
                type="text"
                style={{
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                placeholder="Objective"
                value={objective}
                onChange={(e) => {
                  setObjective(e.target.value);
                }}
                readOnly={!editMode}
              />
            </div>

            <div className="lbl_inpt">
              <label> Start Date: </label>{" "}
              <input
                style={{
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                type="datetime-local"
                value={
                  scheduled_start_date ? convertDate(scheduled_start_date) : ""
                }
                onChange={handleStartDateChange}
                readOnly={!editMode}
              />
            </div>

            <div className="lbl_inpt">
              <label> End Date: </label>
              <input
                type="datetime-local"
                style={{
                  backgroundColor: editMode ? "#EEF1FC" : "#e6e6e6",
                }}
                value={
                  scheduled_end_date ? convertDate(scheduled_end_date) : ""
                }
                onChange={handleEndDateChange}
                readOnly={!editMode}
              />
            </div>

            <SingleOptionSelector
              apiUrl="/api/audit_universe/audit-team/"
              setSelectorId={setPreparerId}
              setSelectorError={setPreparerError}
              selectorError={PreparerError}
              Name="name"
              title="Preparer"
              initialId={APIData.preparer ? APIData.preparer.id : ""}
              initialName={APIData.preparer ? APIData.preparer.name : ""}
              editMode={editMode}
            />

            <SingleOptionSelector
              apiUrl="/api/audit_universe/admin-audit-team/"
              setSelectorId={setReviewerId}
              setSelectorError={setReviewerError}
              selectorError={reviewerError}
              Name="name"
              title="Reviewer"
              initialId={APIData.reviewer ? APIData.reviewer.id : ""}
              initialName={APIData.reviewer ? APIData.reviewer.name : ""}
              editMode={editMode}
            />

            <SingleOptionSelector
              apiUrl="/api/audit_universe/business-stakeholders/"
              setSelectorId={setAssign_To}
              setSelectorError={setAssignError}
              selectorError={assignError}
              Name="name"
              title="Assign To"
              initialId={APIData.assign_to ? APIData.assign_to.id : ""}
              initialName={APIData.assign_to ? APIData.assign_to.name : ""}
              editMode={editMode}
            />
          </form>
          <div className="button_container">
            <button
              className="cmn_btn"
              style={{ backgroundColor: editMode ? "#65CC5C" : "#7A7A7A" }}
              disabled={!editMode}
              onClick={postApi}
            >
              <svg
                width="12"
                height="10"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.72621L3.18182 6.90803L9 1.08984"
                  stroke="white"
                  stroke-width="1.45455"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Save
            </button>

            {editMode && 
              <button onClick={handleCancelClick} className="cmn_btn" style={{backgroundColor:"#d6d6d6",color:"#000"}}>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 1L10.9989 10.9989" stroke="#292D32" stroke-width="1.76758" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M1.00107 10.9989L11 1" stroke="#292D32" stroke-width="1.76758" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
Cancel Edit
                </button>}

            <WindowComponent onDelete={onDelete} text="Delete Audit Memo"/>
            <button
              className="cmn_btn"
              style={{ backgroundColor: '#3608ffea' }}
            >
              <svg
                width="12"
                height="10"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.72621L3.18182 6.90803L9 1.08984"
                  stroke="white"
                  stroke-width="1.45455"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              View As Pdf
            </button>

          </div>
        </div>
      </section>
    </>
  );
};

export default Viewauditmemo;
