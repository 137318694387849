import React, { useState, useEffect,useMemo } from "react";
import { Link, useNavigate, NavLink, useLocation } from "react-router-dom";
import SidebarMenu from "../SidebarMenu";
import Breadcrumb from "../Breadcrumb";
import create from "../../images/createbutton.svg";
import openarrow from "../../images/openarrow.svg";
import { justFetchDataV2 } from "../apiUtils";
import { homeRoutes } from "../subroutes";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../RenderIcons";
import NavRow from "../NavRow";
const Department = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [departmentnameFilter, setDepartmentnameFilter] = useState("");
  const [searchNameFilter, setSearchnameFilter] = useState("");
  const [keystakeholderFilter, setKeystakeholderFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const successMessage = location.state?.successMessage;
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, []);
  useEffect(() => {

    const parameter ={
      url : `/api/system/departments/`,
      setterFunction :(data) => {setAPIData(data)},
      setErrors : (error) => {setErrors(error)},
      setLoading: setLoading,
    }
    
    justFetchDataV2(parameter);
  }, []);
  const Viewuser = (data) => {
    const id = data.id;
    navigate(`/Administration/departments/${id}/`);
  };
  const handleDepartmentFilterChange = (e) => {
    setDepartmentnameFilter(e.target.value);
  };
  const handleSearchFilterChange = (e) => {
    setSearchnameFilter(e.target.value);
  };
  const handleKeystakeholderFilterChange = (e) => {
    setKeystakeholderFilter(e.target.value);
  };

  const [filters, setFilters] = useState({
    department: "",
    unit_head: "",
  });
  const handleFilterChange = (filterKey, e) => {
    const value = e.target.value.toLowerCase();
    setFilters({ ...filters, [filterKey]: value });
  };

  const filteredData =  useMemo(() => {
  return APIData.filter((data) => {
    const departmentName = data.name ? data.name.toLowerCase() : "";
    const departmentMatch = departmentName.includes(filters.department);
    const unitHeadName = data.department_head
      ? data.department_head.name.toLowerCase()
      : "";
    const unitHeadMatch = unitHeadName.includes(filters.unit_head);
    return departmentMatch && unitHeadMatch;
  });
},[APIData,filters])

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}
      <section className="navroutes">
      <RenderIcons homeRoutes={homeRoutes} />
      <NavRow name={'Department'} Create ={'Create'} link={`/Administration/departments/createDepartment`}  idtrue={true}/>
{/* 
        <div className="filter-container">
          <div className="filter">
            <div className="views">
              <h3 className="h3_interextrabold">Departments</h3>
              
            </div>
            <div className="searchncbtn">
              <input
                type="text"
                placeholder="Search"
                className="searchbar"
                onChange={handleSearchFilterChange}
                value={searchNameFilter}
              />
              <div className="createuser">
                <Link to="/Administration/departments/createDepartment">
                  <button className="createbutton">
                    <img src={create} alt="" />
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div> */}
        <div className="tablecontainer">
        <table style={{ width: tableSize ? "80vw" : "90.5vw" }}>
            <thead
              className="tablehead"
              style={{ position: "sticky", top: "0", zIndex: 1000 }}
            >
              <tr>
                <th>
                  <h4 className="thhead">Name</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("department", e)}
                    value={filters.department}
                  />
                </th>
                <th>
                  <h4 className="thhead">Head</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("unit_head", e)}
                    value={filters.unit_head}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
        {loading ? (
          <div className="spinner-bounce-loader">
          <BounceLoader color="#a6fafa"/> 
          </div>
            
          ): filteredData.length === 0 ? (
            <p className="data-error-text">No data found</p> 
          ) : (
          
             filteredData
              .sort((a, b) => a?.name?.localeCompare(b?.name))
              .map((user) => (
                <tr className="tabledata" onClick={() => Viewuser(user)} key={user.id}>
                  <td>{user.name}</td>
                  <td>{user.department_head ? user.department_head.name : " "}</td>
                </tr>
              ))
             
           
          )
        }
        
         </tbody>
         </table>
        </div>

        <div className="cardscontainer">
          {filteredData
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((data) => (
              <div
                className="deptcard"
                onClick={() => Viewuser(data)}
                key={data.id}
              >
                <h2>
                  {data.name ? (
                    data.name
                  ) : (
                    <p className="deptplacehlder">Department Name</p>
                  )}
                </h2>
                <h6>
                  {data.department_head ? (
                    data.department_head.name
                  ) : (
                    <p className="deptplacehlder">Keystakeholder</p>
                  )}
                </h6>
                <img src={openarrow} alt="" />
              </div>
            ))}
        </div>
      </section>
    </>
  );
};
export default Department;
