import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../../../css/viewuser.css";
import "../../rcm/listofrcap.css";
import { fetchDataV2 } from "../../../apiUtils";
import { auditRoutes } from "../../../subroutes";
import corporate from "../../../../images/corporate.svg";
import star from "../../../../images/star.svg";
import risk from "../../../../images/risk.svg";
import auditPlan from "../../../../images/auditPlan.svg";
import audit_icon from "../../../../images/audit_icon.svg";
import planning_icon from "../../../../images/planning_icon.svg";
import fieldwork_icon from "../../../../images/fieldwork_icon.svg";
import reporting_icon from "../../../../images/reporting_icon.svg";
import wrapupqa_icon from "../../../../images/wrapup_icon.svg";
import "./departmentRCM.css";
import RenderIcons from "../../../RenderIcons";
import NavRow from "../../../NavRow";

const DepartmentRCMPage = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [showDone, setShowDone] = useState(false);
  const [APIData, setAPIData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const successMessage = location.state?.successMessage;
  const { id } = useParams();
  const [businessUnitOptions, setBusinessUnitOptions] = useState([]);
  const segments = location.pathname.split("/");
  const options = businessUnitOptions;

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const parameter={
      url: `/api/audit_universe/rcap/`,
      setterFunction: setAPIData,
      setErrors:setErrors,
    }
    fetchDataV2(parameter);
  }, []);

  useEffect(() => {
    const parameter={
      url: `/api/system/departments/`,
      setterFunction: setDepartmentData,
      setErrors:setErrors,
    }
    fetchDataV2(parameter);
  }, []);

  useEffect(() => {
    if (APIData.length > 0) {
      setShowDone(true);
    }
  }, [APIData]);

  const Viewuser = (data) => {
    const departmentId = data.id;
    navigate(`/AuditUniverse/Rcm/${departmentId}`);
  };

  const handleFilterChange = (filterKey, e) => {
    const value = e.target.value.toLowerCase();
    setFilters({ ...filters, [filterKey]: value });
  };

  const [filters, setFilters] = useState({
    departmentname: "",
    departmenthead: "",
    departmentdesigantion: "",
  });

  const filteredData = useMemo(() => {
    return departmentData.filter((data) => {
      const deptname = data?.name?.toLowerCase() || "--";
      const name = data?.department_head?.name?.toLowerCase() || "";
      const designation =
        data?.department_head?.designation.toLowerCase() || "";

      return (
        (!filters.departmentname ||
          deptname.includes(filters.departmentname)) &&
        (!filters.departmenthead || name.includes(filters.departmenthead)) &&
        (!filters.departmentdesigantion ||
          designation.includes(filters.departmentdesigantion))
      );
    });
  }, [departmentData, filters]);

  const [selectedValue, setSelectedValue] = useState(
    options.length > 0 ? options[0].business_unit_id : ""
  );

  useEffect(() => {
    if (options.length > 0) {
      setSelectedValue(options[0].business_unit_id);
    }
  }, [options]);

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        <RenderIcons homeRoutes={auditRoutes} />

        <NavRow name={"Risk Control Matrix"} Create={"Create"} idtrue={true} />

        <div className="tablecontainer">
          <table style={{ width: tableSize ? "80vw" : "90.5vw" }}>
            <thead
              className="tablehead"
              style={{ position: "sticky", top: "0", zIndex: 1000 }}
            >
              <tr>
                <th>
                  <h4>Department Name</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("departmentname", e)}
                    value={filters.departmentname}
                  />
                </th>
                <th>
                  <h4>Department Head</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) => handleFilterChange("departmenthead", e)}
                    value={filters.departmenthead}
                  />
                </th>
                <th>
                  <h4>Department Designation</h4>
                  <input
                    type="text"
                    placeholder="--"
                    className="thfilter"
                    onChange={(e) =>
                      handleFilterChange("departmentdesigantion", e)
                    }
                    value={filters.departmentdesigantion}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((data, index) => (
                <tr onClick={() => Viewuser(data)} key={data.id}>
                  <td>{data.name}</td>
                  <td>
                    {data.department_head ? data.department_head.name : ""}
                  </td>
                  <td>
                    {data.department_head
                      ? data.department_head.designation
                      : ""}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="cardscontainer">
          {filteredData.length === 0 ? (
            <p className="data-error-text">No data found</p>
          ) : (
            filteredData.sort((u, v) => u.id - v.id).map((data, index) => (
              <div
                className="deptcard"
                onClick={() => Viewuser(data)}
                key={data.id} // Dynamic ID used as key
              >
                {/* Department Name */}
                <h2>
                  {data.name ? data.name : <p className="deptplacehlder">Department Name</p>}
                </h2>

                {/* Department Head Name */}
                <h6>
                  {data.department_head?.name ? (
                    data.department_head.name
                  ) : (
                    <p className="deptplacehlder">Keystakeholder</p>
                  )}
                </h6>

                {/* Department Head Designation */}
                <p>
                  {data.department_head?.designation ? (
                    data.department_head.designation
                  ) : (
                    <span className="deptplacehlder">Designation Not Available</span>
                  )}
                </p>


              </div>
            ))
          )}
        </div>

      </section>
    </>
  );
};

export default DepartmentRCMPage;
