import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deleteDataWithFetchV2,
  fetchDataV2,
  postDataWithFetchV2,
  updatewithFetchDataV2,
} from "../apiUtils";
import {
  auditExecutionQA,
  auditExecutionTop,
  auditBusiness,
} from "../subroutes";
import openarrow from "../../images/openarrow.svg";
import SaveRow from "../../images/saveBtn.svg";
import closeBtn from "../../images/closeicon.svg";
import EditRow from "../../images/edit_pencil.svg";
import deleteBtn from "../../images/deleteBtn.svg";
import add_button from "../../images/add_button.svg";
import NavRow from "../NavRow";
import "../auditExecution/auditplan/auditplan.css";
import "../auditExecution/auditWrapUp/QAChecklistFolder/qachecklist.css";
import "../../css/viewuser.css";
import RenderIcons from "../RenderIcons";
import { templates, templatesQA } from "../subroutes";
const QAChecklist = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [checklistData, setChecklistData] = useState([]);
  const [step, setSteptaken] = useState("");
  const [stepError, setSteptakenError] = useState(false);
  const [phaseError, setPhaseError] = useState(false);
  const [phase, setPhase] = useState("");
  const [questions, setQuestions] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const successMessage = location.state?.successMessage;
  const [showRow, setShowRow] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [successMessageQuestion, setSuccessMessage] = useState(false);
  const [editValues, setEditValues] = useState({});
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRef = useRef(null);
    const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  const is_Admin = localStorage.getItem("is_Admin");
  var routes = [];

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
      setSuccessMessage(false);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  if (is_Admin) {
    routes = auditExecutionTop;
  } else if (Object.keys(userRoles).some(key => key.includes("Business"))) {
    routes = auditBusiness;
  } else if (Object.keys(userRoles).some(key => key.includes("Quality Assurance"))) {
    routes = templatesQA;
  } else {
    console.log("No matching role found");
  }

  useEffect(() => {
    const parameter ={
      url :`/api/audit_universe/audit-entities/`, 
      setterFunction :setAPIData,
      setErrors : setErrors
     }
    fetchDataV2(parameter);
  }, []);
  useEffect(() => {
    const parameter ={
      url :`/api/audit_engagement/qa-checklist/template/`, 
      setterFunction :setChecklistData,
      setErrors : setErrors
     }
    fetchDataV2(parameter);
  }, []);

  const [filters, setFilters] = useState({
    name: "",
    key_stakeholder: "",
  });

  const handleCreateClick = () => {
    setQuestions([...questions, { step: "", phase: "" }]);
    setShowRow(true);
  };

  const handleInputChange = (key, value) => {
    setEditValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleEditClick = (index, question) => {
    setEditingIndex(index);
    setEditValues({ ...question });
    console.log(editValues);
  };

  const handleSaveClick = () => {
    if (!step || !phase) {
      setSteptakenError(!step ? true : false);
      setPhaseError(!phase ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    const parameter = {
      url: `/api/audit_engagement/qa-checklist/template/`,
      payload: {
        phase: phase,
        step: step,
      },
      tofetch: {
        items: [
          {
            fetchurl: "/api/audit_engagement/qa-checklist/template/",
            dataset: setChecklistData,
          },
        ],
        setSuccessMessage: setSuccessMessage,
        setErrors: setErrors,
      },
    };
    postDataWithFetchV2(parameter);
    setShowRow(false);
    setPhase("");
    setSteptaken("");
  };

  const handleCancelClickRow = () => {
    setShowRow(false);
  };

  const handleCancelClick = () => {
    setEditingIndex(null);
    setEditValues({});
  };

  const handleDeleteClick = (index, id) => {
    const parameter = {
      url:  `/api/audit_engagement/qa-checklist/template/${id}`,
      navigate: navigate,
      setErrors: setErrors,
      tofetch: {
        items: [
          {
            fetchurl: `/api/audit_engagement/qa-checklist/template/`,
            dataset: setChecklistData,
          },
        ],
        setSuccessMessage: setSuccessMessage,
        setErrors: setErrors,
      },
    };

    deleteDataWithFetchV2(parameter);


  };

  const handleUpdateClick = (id) => {
    if (!editValues.step) {
      setSteptakenError(!editValues.step ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    const parameter = {
      url:  `/api/audit_engagement/qa-checklist/template/${id}`,
      payload: {
        phase: editValues.phase,
        step: editValues.step,
      },
      tofetch: {
        items: [
          {
            fetchurl: `/api/audit_engagement/qa-checklist/template/`,
            dataset: setChecklistData,
          },
        ],
        setSuccessMessage: setSuccessMessage,
        setErrors: setErrors,
      },
    };

    updatewithFetchDataV2(parameter);
    setEditingIndex(null);
    setEditValues({});
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredData = APIData.filter((data) => {
    const fullName = data.name.toLowerCase();
    const NameMatch = filters.name
      ? fullName.includes(filters.name.toLowerCase())
      : true;
    const keyStakeholderName = "";
    const KeyStakeholder = filters.key_stakeholder
      ? keyStakeholderName.includes(filters.key_stakeholder.toLowerCase())
      : true;

    const businessUnitMatch = filters.business_unit
      ? data.business_units.some((unit) => {
          const unitNameLower = unit.name.toLowerCase();
          const filterUnitLower = filters.business_unit.toLowerCase();
          return unitNameLower.includes(filterUnitLower);
        })
      : true;

    return NameMatch && KeyStakeholder && businessUnitMatch;
  });

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      {successMessage && <p className="successmessage">{successMessage}</p>}
      {successMessageQuestion && (
        <p className="successmessage">Question Added SuccessFully</p>
      )}

      <section className="navroutes">
        <RenderIcons homeRoutes={routes} />
        <NavRow name={"Create Q.A Checklist"} Create={"Create"} idtrue={true} />

        <div
          className="qachecklisttempcon"
          style={{ width: tableSize ? "79.5vw" : "90vw" }}
        >
          {checklistData.map((question, index) => (
            <div key={index} className="aecard">
              {editingIndex === index ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "1vw",
                      backgroundColor: "#fff",
                      padding: "12px",
                      borderRadius: "8px",
                    }}
                  >
                    <div
                      className="lng_lbl_inpt"
                      style={{ position: "relative", width: "100%" }}
                    >
                      <input
                        style={{
                          width: "100%",
                          padding: "0 60px 0 10px",
                          border: stepError ? "1px solid #FF4646" : "",
                          height: "50px",
                          borderRadius: "8px",
                        }}
                        type="text"
                        value={editValues.step}
                        onChange={(e) => {
                          handleInputChange("step", e.target.value);
                          setSteptakenError(false);
                        }}
                      />

                      <div
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          display: "flex",
                          gap: "12px",
                        }}
                      >
                        <button
                          onClick={() => handleUpdateClick(question.id)}
                          style={{
                            border: "none",
                            background: "transparent",
                            cursor: "pointer",
                            padding: "0",
                          }}
                        >
                          <img
                            src={SaveRow}
                            alt="Save"
                            style={{ width: "24px", height: "24px" }}
                          />
                        </button>

                        <button
                          onClick={handleCancelClick}
                          style={{
                            border: "none",
                            background: "transparent",
                            cursor: "pointer",
                            padding: "0",
                          }}
                        >
                          <img
                            src={closeBtn}
                            alt="Cancel"
                            style={{ width: "24px", height: "24px" }}
                          />
                        </button>
                      </div>
                    </div>

                    <div className="questionCQ1">
                      <select
                        className="custom-select"
                        style={{
                          height: "5vh",
                          border: phaseError ? "1px solid #FF4646" : "",
                        }}
                        value={editValues.phase}
                        onChange={(e) => {
                          handleInputChange("phase", e.target.value);
                          setPhaseError(false);
                        }}
                      >
                        <option value="" disabled>
                          --Select a phase--
                        </option>
                        <option value="Planning">Planning</option>
                        <option value="Fieldwork">Fieldwork</option>
                        <option value="Reporting">Reporting</option>
                        <option value="Follow Up">Follow Up</option>
                      </select>
                    </div>
                  </div>
                </>
              ) : (
                <div
                  className="checklist_question"
                  style={{
                    backgroundColor: "#fff",
                    padding: " .8vw 1vw",
                    borderRadius: "10px",
                    maxHeight: "12vh",
                    overflowY: "scroll",
                  }}
                >
                  <h4 style={{ maxWidth: tableSize ? "50vw" : "" }}>
                    {question.step}
                  </h4>
                  <div
                    style={{
                      display: "flex",
                      gap: "2.5vw",
                      alignItems: "center",
                    }}
                  >
                    <span className="checklist_phase">{question.phase}</span>
                    <div>
                      <button
                        onClick={() => handleEditClick(index, question)}
                        style={{
                          marginRight: "1vw",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <img
                          src={EditRow}
                          alt="Edit"
                          style={{ width: "2vw" }}
                        />
                      </button>
                      <button
                        onClick={() => handleDeleteClick(index, question.id)}
                        style={{
                          marginRight: "1vw",
                          border: "none",
                          background: "transparent",
                        }}
                      >
                        <img
                          src={deleteBtn}
                          style={{ width: "2vw" }}
                          alt="Delete"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}

          {showRow && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "1vw",
                  backgroundColor: "#fff",
                  padding: ".2vw .8vw",
                  borderRadius: "8px",
                }}
              >
                <div
                  className="lng_lbl_inpt"
                  style={{ position: "relative", width: "100%" }}
                >
                  <input
                    style={{
                      backgroundColor: "#eef1fc",
                      width: "100%",
                      border: stepError ? "1px solid #FF4646" : "",
                    }}
                    type="text"
                    autoFocus
                    value={step}
                    onChange={(e) => {
                      setSteptaken(e.target.value);
                      setSteptakenError(false);
                    }}
                  />

                  <div
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      display: "flex",
                      gap: "12px",
                    }}
                  >
                    <button
                      onClick={handleSaveClick}
                      style={{
                        border: "none",
                        marginRight: "1vw",
                        background: "transparent",
                      }}
                    >
                      <img src={SaveRow} alt="Save" style={{ width: "2vw" }} />
                    </button>

                    <button
                      onClick={handleCancelClickRow}
                      style={{ border: "none", background: "transparent" }}
                    >
                      <img
                        src={closeBtn}
                        alt="Cancel"
                        style={{ width: "2vw" }}
                      />
                    </button>
                  </div>
                </div>
                <div className="questionCQ1">
                  <select
                    className="custom-select"
                    style={{
                      height: "5vh",
                      border: phaseError ? "1px solid #FF4646" : "",
                    }}
                    value={phase}
                    onChange={(e) => {
                      setPhase(e.target.value);
                      setPhaseError(false);
                    }}
                  >
                    <option value="none">--Select a phase--</option>
                    <option value="Planning">Planning</option>
                    <option value="Fieldwork">Fieldwork</option>
                    <option value="Reporting">Reporting</option>
                    <option value="Follow Up">Follow Up</option>
                  </select>
                </div>
              </div>
            </>
          )}
          <div className="checklistcr_btn" style={{ height: "8vh" }}>
            <button onClick={handleCreateClick} style={{ float: "right" }}>
              <img src={add_button} alt="Add" /> Add Question
            </button>
          </div>
        </div>

        <div className="cardscontainer">
          {filteredData.map((data) => (
            <div className="aecard">
              <h1>{data.name} </h1>

              <img src={openarrow} />
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default QAChecklist;
