import React, { useState, useEffect } from 'react';
import { justPostDataV2, justUpdateDataV2 } from '../../apiUtils';
import closeBtn from "../../../images/cutbutton.svg";
import "../../../css/global.css"


const Timeline = ({ APIData, timelineData, setTimelineData, handleSendClick, endyear, startyear, audit_entity, auditplanid, auditentityid, audit, risk_assessment_id, audit_event_id, auditStatus }) => {

  const [showForm, setShowForm] = useState(false);
  const [startDates, setStartDates] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [endDates, setEndDates] = useState('');
  const [timelineStyle, setTimelineStyle] = useState({ width: 0, position: 0 });
  const [key, setKey] = useState(0);
  const [errors, setErrors] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);

  const handleChange = (id, startValue, endValue) => {
    const updatedTimelineData = timelineData.map(timeline =>
      timeline.id === id ? { ...timeline } : timeline
    );
    setTimelineData(updatedTimelineData);

    if (startValue) {
      setStartDate(startValue);
      getPositionAndWidth(startValue, endDate);
    } else if (endValue) {
      setEndDate(endValue);
      getPositionAndWidth(startDate, endValue);
    }
  };

  const handleChangeDate = (id, property, value) => {
    const formattedValue = value;

    if (property === 'startDate') {
      setStartDates(formattedValue);
    } else if (property === 'endDate') {
      setEndDates(formattedValue);
    }
  };

  const getPositionAndWidth = (startDate, endDate) => {
    try {
      const start = new Date(startDate);
      const end = new Date(endDate);

      const startMonthIndex = start.getMonth();
      const endMonthIndex = end.getMonth();

      const startYear = start.getFullYear();
      const endYear = end.getFullYear();

      const monthDiff = (endYear - startYear) * 12 + (endMonthIndex - startMonthIndex);
      const width = (monthDiff + 1) * 5.75;
      const position = startMonthIndex * 5.75;

      setTimelineStyle({ width, position });
    } catch (error) {
      console.error(error.message);
      setTimelineStyle({ width: 0, position: 0 });
    }
  };

  const handleDivClick = () => {
    setShowForm(!showForm);
    // console.log(audit_entity);
  };

  const handleClose = () => {
    setShowForm(false);
  };

  const createAudit = () => {
    const start_date = startDates ? startDates : formatDateString(APIData.startDate);
    const end_date = endDates ? endDates : formatDateString(APIData.endDate);

    const parameter = {
      url: `/api/audit_engagement/audit/`,
      payload: {
        audit_entity,
        auditplanid,
        audit_event_id,
        audit_entity_id: auditentityid,
        risk_assessment_id,
        start_date: start_date,
        end_date: end_date
      },
      setErrors: setErrors,
    }
    justPostDataV2(parameter);





    // Update timelineData state
    handleChange(APIData.id, start_date, end_date);
    setShowForm(false);
    setShowPopUp(true);
    setTimeout(() => setShowPopUp(false), 3000);
  }

  const handleCreateAudit = () => {
    const idParts = APIData.id.split('-');
    const id = idParts[0];
    const parameter = {
      url: `/api/audit_universe/audit-events/date/${id}/`,
      payload: {
        "start_date": startDates ? startDates : formatDateString(APIData.startDate),
        "end_date": endDates ? endDates : formatDateString(APIData.endDate)
      },
      setErrors: setErrors,
    }

    justUpdateDataV2(parameter,
      () => {
        setShowPopUp(true);
        setTimeout(() => setShowPopUp(false), 3000);
      }
    );
  };

  useEffect(() => {
    if (APIData.startDate && APIData.endDate) {
      getPositionAndWidth(startDate ? startDate : APIData.startDate, endDate ? endDate : APIData.endDate);
    }
  }, [APIData.startDate, APIData.endDate,]);

  const formatDateString = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toISOString().split('T')[0];
    return formattedDate;
  };

  const backgroundColor = APIData.id % 2 === 0 ? 'lightblue' : 'grey';
  const ifEndDate = formatDateString(endyear);
  const ifStartDate = formatDateString(startyear);

  let statusColor;
  if (auditStatus === "Open") {
    statusColor = '#215bf9';
  } else if (auditStatus === "In Progress") {
    statusColor = 'orange';
  } else if (auditStatus === "Completed") {
    statusColor = 'rgb(23, 194, 71)';
  } else {
    statusColor = 'lightgrey';
  }

  return (
    <div key={key} style={{ width: '75vw' }}>
      <div className="parent-div"
        style={{
          position: 'relative',
          width: '74vw',
          height: '3vh',
          margin: '12px 0px',
          borderRadius: '0.2vw',
        }}
        onClick={handleDivClick}
      >
        <div title={`Start Date: ${startDate ? formatDateString(startDate) : formatDateString(APIData.startDate)}, End Date: ${endDate ? formatDateString(endDate) : formatDateString(APIData.endDate)}`}
          className="timelinerow"
          style={{
            height: 'inherit',
            position: 'absolute',
            backgroundColor: statusColor,
            borderRadius: '4px',
            width: `${timelineStyle.width}vw`,
            left: `${timelineStyle.position}vw`,
            margin: '0vw 1vw',
            cursor:"pointer",

            display:'flex',
            alignItems:"center",
            justifyContent:"center"
          }}
        >
          <div
            style={{
              height: "auto",
              color: 'white',
              fontSize:"12px",
              fontFamily:"intersemibold"
            }}
          >
            <p>{auditStatus}</p>
          </div>
        </div>
      </div>


      {showForm && (

        <div className="timelineSetterDiv">

          <button onClick={handleClose} style={{ position: "absolute", right: "1vw", top: "1vw", backgroundColor: "transparent", border: "none" }}>
            <img src={closeBtn} className="closeSetter" />
          </button>

          <h2>{audit_entity}</h2>

          <div className="lbl_inpt">
            <label>
              Start Date:
            </label>
            <input
              type="date"
              value={startDates ? formatDateString(startDates) : ifStartDate}
              onChange={(e) => handleChangeDate(APIData.id, 'startDate', e.target.value)}
            />
          </div>

          <div className="lbl_inpt">
            <label>
              End Date:
            </label>
            <input
              type="date"
              value={endDates ? formatDateString(endDates) : ifEndDate}
              onChange={(e) => handleChangeDate(APIData.id, 'endDate', e.target.value)}

            />
          </div>

          <div className="buttonsAPDiv">

            <button className="cmn_btn" 
            disabled={audit ? true : false} 
            style={{ backgroundColor: "#5771DF", cursor: audit ? "not-allowed" : "pointer" }} 
            onClick={() => { handleSendClick(); handleClose(); handleCreateAudit() }}>
              Set Date
            </button>

            <button className="cmn_btn" 
            onClick={() => {
              createAudit()
              handleChange(APIData.id, startDates ? formatDateString(startDates) : ifStartDate, endDates ? formatDateString(endDates) : ifEndDate);
              handleSendClick();
              handleClose();
              handleCreateAudit();
            }}
            style={{ backgroundColor: "#5771DF", cursor: audit ? "not-allowed" : "pointer" }} 
            >
              Create Audit
            </button>
          </div>

        </div>

      )}
      {showPopUp && (
        <div className="popupAP">
          Audit Plan Created
        </div>
      )}
    </div>
  );
};

export default Timeline;
