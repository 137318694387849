import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const AllIssuePieChart = ({ data }) => {
  // Destructure the data prop to get status counts
  const { total, open, closed, overdue, extended, closedUnderMonitoring } = data;

  // Prepare the pie chart data based on the passed data
  const pieData = [
    { name: 'Closed', value: closed },
    { name: 'Open', value: open },
    { name: 'Overdue', value: overdue },
    { name: 'Extended', value: extended },
    { name: 'Closed Under Monitoring', value: closedUnderMonitoring }
  ];

  const isAllZero = pieData.every(entry => entry.value === 0);
  if (isAllZero) {
    return <h3 style={{textAlign:'center'}}>There is no chart to show</h3>;
  }

  // Colors for the Pie segments
  const COLORS = ['#8884d8', '#82ca9d', '#ff8042', '#ffbb28', '#ffc0cb'];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          data={pieData}
          dataKey="value"
          nameKey="name"
          outerRadius={80}
          fill="#8884d8"
          label
        >
          {pieData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default AllIssuePieChart;
