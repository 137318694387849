import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../App.css";
import "../../css/corporate.css";
import { NavLink } from "react-router-dom";
import { justFetchDataV2 } from "../apiUtils";
import SidebarMenu from "../SidebarMenu";
import Breadcrumb from "../Breadcrumb";
import { auditRoutes } from "../subroutes";
import OrganizationalChart from "./CorporateStructureChart";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../RenderIcons";
import zoomIn from "../../images/createbutton.svg";
import zoomOut from "../../images/subBtn.svg";
import NavRow from "../NavRow";

const CorporateStructure = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [departmentData, setDepartmentData] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state


  useEffect(() => {

    const parameter = {
      url: `/api/system/corporate-structure/`,
      setterFunction: (data) => {
        // Sort the fetched data
        const sortedData = data.sort((a, b) => {
          const firstBuName = a.business_units[0]?.name || "";
          const secondBuName = b.business_units[0]?.name || "";
          return firstBuName.localeCompare(secondBuName);
        });
        setDepartmentData(sortedData); // Set the sorted data
      },
      setErrors: (error) => { setErrors(error) },
      setLoading: setLoading,
    }

    justFetchDataV2(parameter);
  }, []);


  if (errors) {
    return <div>{errors}</div>;
  }

  const renderCorporateStructure = () => {
    return
  }

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      <section className="navroutes">
        <RenderIcons homeRoutes={auditRoutes} />
        <NavRow name={'Corporate Structure'} Create={'Create'} idtrue={true} />




        <div className="corporateContainer" style={{ width: tableSize ? "82vw" : "96.5vw" }}>

          {loading ? (
            <div className="spinner-bounce-loader">
              <BounceLoader color="#a6fafa" />
            </div>

          ) : departmentData.length === 0 ? (
            <p className="data-error-text">No data found</p>
          ) : (
            <div
              className="corporateContent"
              style={{
                width: '100%',
                height: 'auto',
                margin: '1vw',
                padding: '1vw',
                transform: 'scale(1)',
                transformOrigin: '25% 25%'
              }}
            >
              <OrganizationalChart data={departmentData} />
            </div>
          )}

        </div>
      </section>
    </>
  );
};

export default CorporateStructure;
