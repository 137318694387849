import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "../../../css/viewuser.css";
import "../issuespage/issues.css";
import {
  fetchDataV2,
  
  postDataV2,
} from "../../apiUtils";
import { issueTracking } from "../../subroutes";
import SingleOptionSelector from "../../SingleOptionSelector";
import AuditReportingSeclector from "../../auditExecution/auditReporting/AuditReportingSelector";
import savesubObv from "../../../images/saveBtn.svg";
import remove_button from "../../../images/remove_button.svg";
import blue_add_button from "../../../images/blue_plus_button.svg";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";
const Issuedetailpage = ({ tableSize }) => {
  const [APIQData, setAPIQData] = useState("");
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [selectedObservationId, setSelectedObservationId] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [assign_to_1_id, setAssign_to_id] = useState("");
  const [assign_to_idError, setAssign_to_idError] = useState(false);
  const [selectAuditId, setSelectAuditId] = useState("");
  const [selectAuditIdError, setSelectAuditIdError] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const successMessage = location.state?.successMessage;
  const { id } = useParams();
  const [sub_observation, setSubObservations] = useState([]);
  const [startdate, setStartdate] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [summary, setSummary] = useState("");
  const [objective, setTitle] = useState("");
  const [auditData, setAuditData] = useState([]);
  const [rating, setObservationRating] = useState("");
  const [recommendation, setRecommendation] = useState("");
  const [management_response, setActionPlan] = useState("");
  const [owner_id, setOwner] = useState("");
  const [level_1_ownerError, setLevel1OwnerError] = useState("");
  const [assign_to_2_id, setLevel2Owner] = useState("");
  const [level_2_ownerError, setLevel2OwnerError] = useState("");
  const [management_response_due_date, setActionDueDate] = useState("");
  const [response_audit, setAuditResponse] = useState("");
  const [response_audit_due_date, setResponseActionDueDate] = useState("");
  const [short_description, setShortDescription] = useState("");
  const [observationDetails, setObservationDetails] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [taskErrors, setTaskErrors] = useState("");
  const [inputList, setInputList] = useState([]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    setEditMode(false);
    console.log(selectAuditId);
    if (selectAuditId) {
      const parameter={
        url:`/api/audit_engagement/observation/?audit_id=${selectAuditId}&status=Published`,
        setterFunction: setAPIData,
        setErrors:setErrors,
      }
      fetchDataV2(parameter);
    }
  }, [selectAuditId]);

  useEffect(() => {
    if (selectedObservationId) {
      const parameter={
        url:`/api/audit_engagement/observation/${selectedObservationId}/`,
        setterFunction: setObservationDetails,
        setErrors:setErrors,
      }
      fetchDataV2(parameter);
    }
  }, [selectedObservationId]);

  useEffect(() => {
    const parameter={
      url:`/api/audit_engagement/audit/`, 
      setterFunction: setAuditData,
      setErrors:setErrors,
    }
    fetchDataV2(parameter);
  }, [id]);

  useEffect(() => {
    if (observationDetails) {
      setTitle(observationDetails?.objective);

      setObservationRating(observationDetails?.rating);
      setShortDescription(observationDetails?.short_description);

      setAPIQData(observationDetails?.task?.id);
      setStartdate(observationDetails.due_date);
      setActionPlan(observationDetails?.action_plan);
      setActionDueDate(observationDetails?.management_response_due_date);
      setSummary(observationDetails?.summary);
      setRecommendation(observationDetails.recommendation);
      setAuditResponse(observationDetails?.response_audit);
      setResponseActionDueDate(observationDetails?.response_audit_due_date);
      setSubObservations(observationDetails?.data?.data?.sub_observations);
      setSubObservations(observationDetails?.data?.subObservations || []);
      if (
        APIData.task?.status === "review" ||
        APIData.task?.status === "done" ||
        APIData.task?.status === "todo" ||
        APIData.task?.status === "rejected"
      ) {
        setEditMode(false);
      }
    }
  }, [observationDetails]);

  const [taskTitle, setTaskTitle] = useState("");

  useEffect(() => {
    if (APIData) {
      setAPIQData(APIData?.task?.id);
      setTaskTitle(APIData?.task?.title);
    }
  }, [APIData]);

  const [filters, setFilters] = useState({
    name: "",
    key_stakeholder: "",
  });
  const handleSelectionChange = (id) => {
    setSelectedObservationId(id);
  };

  const CreateIssue = () => {

    const papameter ={
      url : `/api/issues/`,
      payload : {
        objective,
        short_description,
        owner_id,
        assign_to_1_id,
        management_response_due_date,
        response_audit,
        response_audit_due_date,
        sub_observation,
        management_response,
        assign_to_2_id,
        observation_id: parseInt(selectedObservationId),
        rating,
        summary,
      },
      navigate : navigate ,
      setErrors : setErrors ,
      navUrl :  `/issuetracking/issues`
     }
    postDataV2(papameter);
  };

  useEffect(() => {
    if (sub_observation && sub_observation.length > 0) {
      const initialInputs = sub_observation.map((observation) => ({
        value: observation,
        isEditing: false,
      }));
      setInputList(initialInputs);
    }
  }, [sub_observation]);

  const handleAdd = () => {
    setInputList([...inputList, { value: "", isEditing: true }]);
  };

  const handleSave = (index) => {
    const updatedList = [...inputList];
    updatedList[index].isEditing = false;
    setInputList(updatedList);
    const updatedObservations = updatedList.map((input) => input.value);
    setSubObservations(updatedObservations);
  };

  const handleCancel = (index) => {
    const updatedList = inputList.filter((_, i) => i !== index);
    setInputList(updatedList);

    const updatedObservations = updatedList.map((input) => input.value);
    setSubObservations(updatedObservations);
  };

  const handleRemove = (index) => {
    const updatedList = inputList.filter((_, i) => i !== index);
    setInputList(updatedList);

    const updatedObservations = sub_observation.filter((_, i) => i !== index);
    setSubObservations(updatedObservations);
  };

  const handleChange = (e, index) => {
    const updatedList = [...inputList];
    updatedList[index].value = e.target.value;
    setInputList(updatedList);
  };

  function convertDate(dateString) {
    if (dateString) {
      const date = new Date(dateString);
      date.setDate(date.getDate() - 15);
      const formattedDate = date.toISOString().split("T")[0];
      return formattedDate;
    }
  }

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (id) => {
    handleSelectionChange(id);
    setIsOpen(false);
  };

  const selectedObservation = APIData.find(
    (obs) => obs.id === selectedObservationId
  );

  const saveBack = {
    Back: `/issuetracking/issues`,
    CreateIssue: CreateIssue,
  };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        <RenderIcons homeRoutes={issueTracking} />
        <NavRow name={'Create Issue'} saveBack={saveBack} idtrue={true} />

        <div className="issues_container" style={{ width: tableSize ? "79.5vw" : "90vw" }}>

          <div className="issue_grid_container">

            <div className="issue_grid_item">

              <h4>Overview</h4>
              
              <div className="lng_lbl_inpt">
                <label>Title</label>
                <input
                  type="text"
                  placeholder="Title"
                  value={objective}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>{" "}

              <SingleOptionSelector apiUrl="/api/audit_engagement/audit/"
                setSelectorId={setSelectAuditId}
                setSelectorError={setSelectAuditIdError}
                Name="name"
                title="Select Audit"
                editMode={!editMode}
              />

          <div>
            <div
              className="dropdown-container"
              onChange={handleSelectionChange}
              value={selectedObservationId}
            >
              <div className="dropdown-selected" onClick={handleDropdownClick}>
                {selectedObservation
                  ? `(${selectedObservation.id}): ${selectedObservation.summary}`
                  : "Select an observation"}
                <span className="dropdown-arrow">{isOpen ? "▲" : "▼"}</span>
              </div>

                {isOpen && (
                  <div className="dropdown-options">
                    {APIData.map((observation) => (
                      <div
                        key={observation.id}
                        className="dropdown-option"
                        onClick={() => handleOptionClick(observation.id)}
                      >
                        ({observation.id}): {observation.summary}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <AuditReportingSeclector apiUrl="/api/audit_universe/admin-audit-team/"
                setSelectorId={setOwner}
                setSelectorError={setLevel1OwnerError}
                selectorError={level_1_ownerError}
                Name="name"
                title="Owner"
                editMode={!editMode}
                initialId={
                  observationDetails?.preparer ? observationDetails?.preparer?.id : ""
                }
                initialName={
                  observationDetails?.preparer ? observationDetails?.preparer?.name : ""
                }
              />

              <AuditReportingSeclector apiUrl="/api/audit_universe/business-stakeholders/"
                setSelectorId={setAssign_to_id}
                setSelectorError={setAssign_to_idError}
                selectorError={assign_to_idError}
                Name="name"
                title="Assign To"
                editMode={!editMode}
                initialId={
                  observationDetails?.assign_to
                    ? observationDetails?.assign_to.id
                    : ""
                }
                initialName={
                  observationDetails?.assign_to
                    ? observationDetails?.assign_to.name
                    : ""
                }
              />
            </div>

            {/* <div className="issue_grid_item">

              <div className="lbl_txa">

                <label>Description</label>


                  <textarea
                    type="text"
                    placeholder="Description"
                    value={short_description}
                    onChange={(e) => setShortDescription(e.target.value)}
                    style={{
                      backgroundColor: "#EEF1FC",
                    }}
                  />
                </div>

                <div className="subSummaryDiv">
                  <div className="subSummaryDiv_head">
                    <h5>Sub Observation</h5>

                    <div className="addObv">
                      <button
                        type="button"
                        className="addSubObv"
                        onClick={handleAdd}
                      >
                        <img src={blue_add_button} />
                      </button>
                    </div>
                  </div>

                  {inputList.map((input, index) => (
                    <div key={index}>
                      {input.isEditing ? (
                        <>
                          <div className="addsubObvDiv" style={{ gap: "1vw" }}>
                            <textarea
                              type="text"
                              value={input.value}
                              onChange={(e) => handleChange(e, index)}
                              style={{
                                backgroundColor: editMode
                                  ? "#EEF1FC"
                                  : "#e6e6e6",
                              }}
                            />
                            <div className="addsubBtnDiv">
                              <button
                                type="button"
                                className="saveSubObv"
                                onClick={() => handleSave(index)}
                                style={{
                                  border: "none",
                                  background: "transparent",
                                }}
                              >
                                <img src={savesubObv} />
                              </button>
                              <button
                                type="button"
                                className="cancelSubObv"
                                onClick={() => handleCancel(index)}
                              >
                                <img src={remove_button} />
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="remove_sunobspara">
                          <p>{input.value}</p>

                        <button
                          type="button"
                          
                          onClick={() => handleRemove(index)}
                        >
                          <img src={remove_button} />
                        </button>
                      </div>
                    )}
                  </div>
                ))}

              </div>

            </div> */}

          </div>




          <div className="issue_grid_item_span_2">
            <h5>Issue Detail</h5>

            <div className="lbl_txa">
              <label>Issue description</label>
              <textarea
                type="text"
                placeholder="Issue description"
                value={summary}
                // disabled={!editMode}
                onChange={(e) => setSummary(e.target.value)}
              
              />
            </div>

            <div className="lbl_inpt">
              <label>Issue Rating</label>
              <input
                type="text"
                value={rating}
                // disabled={!editMode}
                onChange={(e) => setObservationRating(e.target.value)}
                style={{
                  width: '22vw',
                  backgroundColor: "#EEF1FC", borderRadius: "5px",
                }}
              />
            </div>
          </div>

          <div className="issue_grid_item_span_2">

            <h5>Management Response</h5>

            <div className="lbl_txa">
              <label>Action Plan</label>
              <textarea
                style={{
                  backgroundColor: "#EEF1FC",
                }}
                type="text"
                placeholder="Action Plan"
                value={management_response}
                
                onChange={(e) => setActionPlan(e.target.value)}
              />
            </div>

            <div
              className="lbl_inpt"
            >
              <label>Due Date</label>
              <input
                type="date"

                value={convertDate(management_response_due_date)}
              
                onChange={(e) => setActionDueDate(e.target.value)}
                style={{
                  backgroundColor: "#EEF1FC", borderRadius: "5px",
                }}
              />
            </div>

            

            <AuditReportingSeclector apiUrl="/api/audit_universe/business-stakeholders/"
              setSelectorId={setLevel2Owner}
              setSelectorError={setLevel2OwnerError}
              selectorError={level_2_ownerError}
              Name="name"
              title="Level 2 Owner"
              editMode={!editMode}
              initialId={
                observationDetails?.secondary_assign_to
                  ? observationDetails?.secondary_assign_to?.id
                  : ""
              }
              initialName={
                observationDetails?.secondary_assign_to
                  ? observationDetails?.secondary_assign_to?.name
                  : ""
              }

            />

          </div>

          {/* <div className="issue_grid_item_span_2">
            <h5>Internal Audit Response</h5>

            <div className="lbl_txa">
              <label>Audit Response</label>
              <textarea
                type="text"
                placeholder="Internal Audit Response"
                value={response_audit}
                
                onChange={(e) => setAuditResponse(e.target.value)}
                style={{
                  backgroundColor: "#EEF1FC",
                }}
              />
            </div>

            <div className="lbl_inpt">
              <label>Due Date</label>
              <input
                type="date"
                value={convertDate(response_audit_due_date)}
              
                onChange={(e) => setResponseActionDueDate(e.target.value)}
                style={{
                  backgroundColor: "#EEF1FC", borderRadius: "5px",
                }}
              />
            </div>
          </div> */}





        </div>

        <div className="cardscontainer"></div>
        </div>
      </section>
    </>
  );
};

export default Issuedetailpage;
