import React, { useState, useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import "../../../../css/viewuser.css";
import { fetchDataV2, updateDataV2 } from "../../../apiUtils";
import { auditExecution, auditExecutionInnerBU,auditExecutionInnerQA } from "../../../subroutes";
import "../../auditplan/auditplan.css";
import "./../auditreporting.css";
import "./reportingtaskspage.css";
import ProgressBar from "../../progressbar/ProgressBar";
import RenderIcons from "../../../RenderIcons";
import NavRow from "../../../NavRow";

const ReportingTasksPage = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [areAllTasksDone, setAreAllTasksDone] = useState();

  const location = useLocation();
  const navigate = useNavigate();
  const successMessage = location.state?.successMessage;
  const { id, ObservationUpdateId, rid } = useParams();
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [task, setTaskDetails] = useState();
  const [showPopup, setShowPopup] = useState(false);
  const is_Admin = localStorage.getItem("is_Admin");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);





  useEffect(() => {
    const parameter = {
      url: `/api/audit_engagement/audit/${id}/`,
      setterFunction: setAuditData,
      setErrors: setErrors,
    }
    fetchDataV2(parameter);
  }, [id]);

  const userRolesCheck = JSON.parse(localStorage.getItem("userRoles"));


  var routes = [];
  if (is_Admin || Object.keys(userRolesCheck).some(key => key.includes("Audit Team"))) {
    routes = auditExecution;
  } else if (Object.keys(userRolesCheck).some(key => key.includes("Business"))) {
    routes = auditExecutionInnerBU;
  }
  else if (Object.keys(userRolesCheck).some(key => key.includes("Quality Assurance"))) {
    console.log("i am here t")
    routes = auditExecutionInnerQA;
  }
  else {
    console.log("No matching role found");
  }


  useEffect(() => {
    setAreAllTasksDone(APIData.every((task) => task.task.status === "done"));
  }, [APIData]);

  const [filters, setFilters] = useState({
    name: "",
    key_stakeholder: "",
  });







  const updateWithPhase = (e) => {
    e.preventDefault();
    const currentDate = new Date().toISOString().split("T")[0];
    const Parameter = {
      url: `/api/audit_engagement/audit/date/${id}`,
      payload: {
        date_of: 'wrapup_qa',
        start_date: currentDate,
      },
      setErrors: setErrors,
      navigate: navigate,
      navUrl: `/AuditExecution/${id}/Reporting/`,
    };

    updateDataV2(Parameter);

  };



  const handlePopupClose = (task) => {
    const Parameter = {
      url: `/api/system/task/${task?.task?.id}`,
      payload: {
        title: task.objective,
        status: "done",
      },
      setErrors: setErrors,
      navigate: navigate,
      navUrl: `/AuditExecution/${id}/Reporting`
    };

    updateDataV2(Parameter);

    setAPIData((prevAPIData) =>
      prevAPIData.map((task) => {
        if (task.id === selectedTaskId) {
          return {
            ...task,
            task: {
              ...task.task,
              status: "Done",
            },
          };
        }
        return task;
      })
    );
    window.location.reload();

    setShowPopup(false);
  };

  const handlePopupCloseNo = () => {
    setShowPopup(false);
  };



  const navToReportPage = (navTo) => {
    switch (navTo) {
      case "Initial Observation":
        navigate(`/AuditExecution/${id}/Reporting/InitialObservation`)
        break;

      case "QA Approval For Initial Observation":
        navigate(`/AuditExecution/${id}/Reporting/QAApprovalInitialObservation`)
        break;

      case "Schedule Closing Meeting":
        navigate(`/AuditExecution/${id}/Reporting/ScheduleClosingMeeting`)
        break;

      case "Business Owner Approval":
        navigate(`/AuditExecution/${id}/Reporting/BusinessOwnerApproval`)
        break;

      case "Finalize Observation":
        navigate(`/AuditExecution/${id}/Reporting/FinalizeObservation`)
        break;

      case "Generate Report":
        navigate(`/AuditExecution/${id}/Reporting/GenerateReportList`)
        break;

      case "QA Approval For Finalize  Observation":
        navigate(`/AuditExecution/${id}/Reporting/qaAPprovalFinalizeObservation`)
        break;

      case "Audit Director Approval For Finalize Observation":
        navigate(`/AuditExecution/${id}/Reporting/AuditDirectorApproval`)
        break;

      case "Publish Report":
        navigate(`/AuditExecution/${id}/Reporting/PublishReport`)
        break;

      default:
        return "Invalid Task";
    }
  };

  const pwa_report_data = [
    {
      id: 1,
      objective: "Create Initial Observation and Approval",
      risk_title: null,
      unit_head: { name: "John Doe" },
      department: { name: "Audit" },
      navTo: "Initial Observation",
    },
    {
      id: 2,
      objective: "Initial Draft for QA Approval",
      risk_title: null,
      unit_head: { name: "Jane Smith" },
      department: { name: "Compliance" },
      navTo: "QA Approval For Initial Observation",
    },
    {
      id: 3,
      objective: "Schedule Closing Meeting & Send Initial Draft",
      risk_title: null,
      unit_head: { name: "Robert Brown" },
      department: { name: "Finance" },
      navTo: "Schedule Closing Meeting",
    },
    {
      id: 4,
      objective: "Drafts for Business Approval",
      risk_title: null,
      unit_head: { name: "Emily White" },
      department: { name: "Operations" },
      navTo: "Business Owner Approval",
    },
    {
      id: 5,
      objective: "Draft Finalization",
      risk_title: null,
      unit_head: { name: "Michael Green" },
      department: { name: "HR" },
      navTo: "Finalize Observation",
    },
    {
      id: 6,
      objective: "Generate Report",
      risk_title: null,
      unit_head: { name: "Sara Black" },
      department: { name: "IT" },
      navTo: "Generate Report",
    },
    {
      id: 7,
      objective: "Final Report (QA Approval)",
      risk_title: null,
      unit_head: { name: "David Lee" },
      department: { name: "Legal" },
      navTo: "QA Approval For Finalize Observation",
    },
    {
      id: 8,
      objective: "Final Report (Audit Director Approval)",
      risk_title: null,
      unit_head: { name: "Sophia Wilson" },
      department: { name: "Marketing" },
      navTo: "Audit Director Approval For Finalize Observation",
    },
    {
      id: 9,
      objective: "Publish Final Report",
      risk_title: null,
      unit_head: { name: "Liam Davis" },
      department: { name: "Sales" },
      navTo: "Publish Report",
    }
  ];


  const renderCards = () => {
    return pwa_report_data.map((item) => (

      <div className="deptcard" onClick={() => navToReportPage(item?.navTo)} key={item.id}>

        <div className="bunstkhlder">
          <h2>
            {item.objective ? item.objective : (<p className="buplacehldr">Objective</p>)}
          </h2>

          <h5>
            {item.unit_head ? item.unit_head.name : <p className="buplacehldr">Keystakeholder</p>}
          </h5>
        </div>
        
        {/* <div className="deptnauthnbtn">
          <h5>
            <p className="buplacehldr">
              {item.department ? item.department.name : "Department"}
            </p>
          </h5>
          <h6>
            <p className="buplacehldr">Bu ID - </p>
            <p className="buplacehldr">{item.id ? item.id : "Bu ID"}</p>
          </h6>
        </div> */}
      </div>


    ));
  };

  const userRole = JSON.parse(localStorage.getItem("userRoles"));

  // Function to determine the highest priority role
  const getHighestPriorityRole = () => {
    if (is_Admin) return "Admin";
    if (Object.keys(userRole).some(key => key.includes("Quality Assurance"))) return "Quality Assurance";
    if (Object.keys(userRole).some(key => key.includes("Business"))) return "Business";
    return null;
  };

  const highestRole = getHighestPriorityRole();


  const phaseTrue = {
    phase: auditData.phase
  }
  const name = auditData.name;


  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      {successMessage && <p className="successmessage">{successMessage}</p>}
      {showPopup && (
        <div className="popupATP">
          <div className="subpopupATP">
            <p>Do you want the task to be done?</p>
            <div className="subpopupBtnDiv">
              <button onClick={() => handlePopupClose(task)}>Yes</button>
              <button onClick={handlePopupCloseNo}>No</button>
            </div>
          </div>
        </div>
      )}
      <section className="navroutes">
        <RenderIcons homeRoutes={routes} id={id} />
        <NavRow parentName={name} name={name} trueback={true} phaseTrue={phaseTrue} backToExecution={`/AuditExecution`} />

        <div className="reportingTaskTableDiv" style={{ width: tableSize ? "79.5vw" : "90vw" }}>

          <table>
            <thead style={{ position: "sticky", top: "0", zIndex: 1000 }}>
              <tr className="tablehead">
                <th style={{ maxWidth: "1vw" }}>
                  <h4 className="thhead">Action</h4>
                </th>
                <th>
                  <h4 className="thhead"> Phase </h4>
                </th>
                <th>
                  <h4 className="thhead"> Task </h4>
                </th>
                <th>
                  <h4 className="thhead"> Progress </h4>
                </th>

              </tr>
            </thead>

            <tbody>
              <tr
                onClick={() => navToReportPage("Initial Observation")}
                style={{ display: is_Admin ? "" : "none" }}
              >
                <td><input type="checkbox" /></td>
                <td><span className="status_todo">Initial Observation</span></td>
                <td><span>Create Initial Observation and Approval</span></td>
                <td>
                  <ProgressBar
                    percentage={auditData.report_status?.CREATE_INITIAL_OBSERVATION || 0}
                  />
                </td>
              </tr>

              <tr
                onClick={() => navToReportPage("QA Approval For Initial Observation")}
                style={{ display: is_Admin || Object.keys(userRole).some(key => key.includes("Quality Assurance")) ? "" : "none" }}
              >
                <td><input type="checkbox" /></td>
                <td><span className="status_todo">Initial Observation</span></td>
                <td><span>Initial Draft for QA Approval</span></td>
                <td>
                  <ProgressBar
                    percentage={auditData.report_status?.APPROVE_QA_INITIAL_OBSERVATION || 0}
                  />
                </td>
              </tr>

              <tr
                onClick={() => navToReportPage("Schedule Closing Meeting")}
                style={{ display: is_Admin ? "" : "none" }}
              >
                <td><input type="checkbox" /></td>
                <td><span className="status_inprogress">Draft Report</span></td>
                <td><span>Schedule Closing Meeting & Send Initial Draft</span></td>
                <td>
                  <ProgressBar
                    percentage={auditData.report_status?.SEND_CLOSING_MEETING ? 100 : 0}
                  />
                </td>
              </tr>

              <tr
                onClick={() => navToReportPage("Business Owner Approval")}
                style={{ display: is_Admin || Object.keys(userRole).some(key => key.includes("Business")) ? "" : "none" }}
              >
                <td><input type="checkbox" /></td>
                <td><span className="status_inprogress">Draft Report</span></td>
                <td><span>Drafts for Business Approval</span></td>
                <td>
                  <ProgressBar
                    percentage={auditData.report_status?.BUSINESS_APPROVAL || 0}
                  />
                </td>
              </tr>

              <tr
                onClick={() => navToReportPage("Finalize Observation")}
                style={{ display: is_Admin ? "" : "none" }}
              >
                <td><input type="checkbox" /></td>
                <td><span className="status_inprogress">Draft Report</span></td>
                <td><span>Draft Finalization</span></td>
                <td>
                  <ProgressBar
                    percentage={auditData.report_status?.FINALIZED_OBSERVATION || 0}
                  />
                </td>
              </tr>

              <tr
                onClick={() => navToReportPage("Generate Report")}
                style={{ display: is_Admin ? "" : "none" }}
              >
                <td><input type="checkbox" /></td>
                <td><span className="status_complete">Final Report</span></td>
                <td><span>Generate Report</span></td>
                <td>
                  <ProgressBar
                    percentage={auditData.report_status?.REPORT_GENERATED ? 100 : 0}
                  />
                </td>
              </tr>

              <tr
                onClick={() => navToReportPage("QA Approval For Finalize  Observation")}
                style={{ display: is_Admin || Object.keys(userRole).some(key => key.includes("Quality Assurance")) ? "" : "none" }}
              >
                <td><input type="checkbox" /></td>
                <td><span className="status_complete">Final Report</span></td>
                <td><span>Final Report (QA Approval)</span></td>
                <td>
                  <ProgressBar
                    percentage={auditData.report_status?.APPROVE_QA_FINAL_OBSERVATION || 0}
                  />
                </td>
              </tr>

              <tr
                onClick={() => navToReportPage("Audit Director Approval For Finalize Observation")}
                style={{ display: is_Admin ? "" : "none" }}
              >
                <td><input type="checkbox" /></td>
                <td><span className="status_complete">Final Report</span></td>
                <td><span>Final Report (Audit Director Approval)</span></td>
                <td>
                  <ProgressBar
                    percentage={auditData.report_status?.APPROVE_ADMIN_FINAL_OBSERVATION || 0}
                  />
                </td>
              </tr>

              <tr
                onClick={() => navToReportPage("Publish Report")}
                style={{ display: is_Admin ? "" : "none" }}
              >
                <td><input type="checkbox" /></td>
                <td><span className="status_complete">Final Report</span></td>
                <td><span>Publish Final Report</span></td>
                <td>
                  <ProgressBar
                    percentage={auditData.report_status?.PUBLISHED ? 100 : 0}
                  />
                </td>
              </tr>
            </tbody>

          </table>

        </div>

        <div className="cardscontainer">
          {renderCards()}
        </div>

        <div style={{ marginTop: '2vh' }}>
          {auditData.phase === "Fieldwork Complete" && (
            <button onClick={(e) => updateWithPhase(e)}
              className="cmn_btn"
              style={{ backgroundColor: auditData?.report_status?.PUBLISHED === false ? 'grey' : "#5757df" }}
              disabled={auditData?.report_status?.PUBLISHED === false}
            >
              Start WrapupQA</button>
          )}
        </div>

      </section>
    </>
  );
};

export default ReportingTasksPage;
