
import axios from 'axios';


const token = localStorage.getItem("token");


function convertDate(dateString) {
  if (dateString) {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-GB', options);
  }
  return '';
}



const postDataV2 = (parameter) => {
  if (!token) {
    console.error("JWT token not found in local storage");
    return;
  }

  axios
    .post(`${process.env.REACT_APP_API_URL}${parameter?.url}`, parameter?.payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const successMessageColor = "#4285F4";
      const { status } = response;
      if (parameter?.navigate) {
        parameter?.navigate(`${parameter?.navUrl}`, {
          state: {
            successMessage: "Successfully Created!",
            successMessageColor,
          },
        });
      }
      else {
        window.location.reload();
      }
    })
    .catch((error) => {
  console.log("error:", error.response?.data);

  if (error.response) {
    const { status, data } = error.response;

    if (status === 400 || status >= 500 || status===403) {
      if (data) {
        console.log("Handling error response data...");

        // Process and collect error messages
        const errors = Object.entries(data).reduce((acc, [field, messages]) => {
          if (Array.isArray(messages)) {
            // If messages is an array, join them and remove index prefixes like "0:"
            const cleanedMessages = messages.map((msg) => msg.replace(/^\d+:\s*/, ""));
            acc.push(` ${cleanedMessages.join(", ")}`);
          } else if (typeof messages === "string") {
            // If messages is a string, remove index prefix if present
            acc.push(` ${messages.replace(/^\d+:\s*/, "")}`);
          } else {
            // Handle unexpected data types
            acc.push(`${field}: Unknown error format`);
          }
          return acc;
        }, []);

        // Set errors in the state
        if (errors.length) {
          parameter?.setErrors(errors.join("\n")); // Pass errors as a joined string
        } else {
          parameter?.setErrors("Something went wrong. Please try again later.");
        }

        // Clear the errors after a delay (optional)
        setTimeout(() => {
          parameter?.setErrors(null);
        }, 5000);
      }
    } else if (status === 401) {
      alert("Unauthorized access. Redirecting to login...");
      localStorage.clear();
      window.location.href = "/login";
    }

    console.error("Response Status:", status);
    console.error("Response Data:", data);
  } else if (error.request) {
    console.error("No response received:", error.request);
    parameter?.setErrors("No response received from the server. Please try again.");
  } else {
    console.error("Error setting up the request:", error.message);
    parameter?.setErrors(`An error occurred: ${error.message}`);
  }
});





};
const postDataWithFetchV2 = (parameter) => {
  if (!token) {
    console.error("JWT token not found in local storage");
    return;
  }

  axios
    .post(`${process.env.REACT_APP_API_URL}${parameter?.url}`, parameter?.payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const successMessageColor = "#4285F4";
      const { status } = response;
      if (parameter?.tofetch) {
        if (status === 201 || status === 200) {
          parameter?.tofetch?.setSuccessMessage(true)
          const timeoutId = setTimeout(() => {
            parameter?.tofetch?.setSuccessMessage(false);
          }, 5000);
          if (parameter?.tofetch?.items && Array.isArray(parameter?.tofetch.items)) {
            parameter?.tofetch.items.forEach((item) => {
              if (item.fetchurl && item.dataset) {
                const parameter ={
                  url : item.fetchurl,
                  setterFunction : item.dataset
                }
                fetchDataV2(parameter);
              }
            });
          }
          if (parameter?.tofetch?.setSuccessCallback) {
            parameter?.tofetch?.setSuccessCallback(true);
          }
          parameter?.tofetch?.navigate(`${parameter?.tofetch?.navUrl}`, {
            state: {
              successMessage: "Successfully Created!",
              successMessageColor,
            },
          });
        }
      }
    })
    .catch((error) => {
  console.log("error:", error.response?.data);

  if (error.response) {
    const { status, data } = error.response;

    if (status === 400 || status >= 500) {
      if (data) {
        console.log("Handling error response data...");

        // Process and collect error messages
        const errors = Object.entries(data).reduce((acc, [field, messages]) => {
          if (Array.isArray(messages)) {
            // If messages is an array, join them and remove index prefixes like "0:"
            const cleanedMessages = messages.map((msg) => msg.replace(/^\d+:\s*/, ""));
            acc.push(` ${cleanedMessages.join(", ")}`);
          } else if (typeof messages === "string") {
            // If messages is a string, remove index prefix if present
            acc.push(` ${messages.replace(/^\d+:\s*/, "")}`);
          } else {
            // Handle unexpected data types
            acc.push(`${field}: Unknown error format`);
          }
          return acc;
        }, []);

        // Set errors in the state
        if (errors.length) {
          parameter?.setErrors(errors.join("\n")); // Pass errors as a joined string
        } else {
          parameter?.setErrors("Something went wrong. Please try again later.");
        }

        // Clear the errors after a delay (optional)
        setTimeout(() => {
          parameter?.setErrors(null);
        }, 5000);
      }
    } else if (status === 401) {
      alert("Unauthorized access. Redirecting to login...");
      localStorage.clear();
      window.location.href = "/login";
    }

    console.error("Response Status:", status);
    console.error("Response Data:", data);
  } else if (error.request) {
    console.error("No response received:", error.request);
    parameter?.setErrors("No response received from the server. Please try again.");
  } else {
    console.error("Error setting up the request:", error.message);
    parameter?.setErrors(`An error occurred: ${error.message}`);
  }
});

};
const postDataReturnId = (parameter) => {
  if (!token) {
    console.error("JWT token not found in local storage");
    return;
  }

  axios
    .post(`${process.env.REACT_APP_API_URL}${parameter?.url}`, parameter?.payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const successMessageColor = "#4285F4";
      if (parameter?.setSuccessCallback != undefined) {
        parameter?.setSuccessCallback(response.data);
      } else {
        parameter?.navigate(`${parameter?.navUrl}`, {
          state: {
            successMessage: "Successfully Created!",
            successMessageColor,
          },

        });
      }
    })
    .catch((error) => {

  if (error.response) {
    const { status, data } = error.response;

    if (status === 400 || status >= 500) {
      if (data) {
        console.log("Handling error response data...");

        // Process and collect error messages
        const errors = Object.entries(data).reduce((acc, [field, messages]) => {
          if (Array.isArray(messages)) {
            // If messages is an array, join them and remove index prefixes like "0:"
            const cleanedMessages = messages.map((msg) => msg.replace(/^\d+:\s*/, ""));
            acc.push(` ${cleanedMessages.join(", ")}`);
          } else if (typeof messages === "string") {
            // If messages is a string, remove index prefix if present
            acc.push(` ${messages.replace(/^\d+:\s*/, "")}`);
          } else {
            // Handle unexpected data types
            acc.push(`${field}: Unknown error format`);
          }
          return acc;
        }, []);

        // Set errors in the state
        if (errors.length) {
          parameter?.setErrors(errors.join("\n")); // Pass errors as a joined string
        } else {
          parameter?.setErrors("Something went wrong. Please try again later.");
        }

        // Clear the errors after a delay (optional)
        setTimeout(() => {
          parameter?.setErrors(null);
        }, 5000);
      }
    } else if (status === 401) {
      alert("Unauthorized access. Redirecting to login...");
      localStorage.clear();
      window.location.href = "/login";
    }

    console.error("Response Status:", status);
    console.error("Response Data:", data);
  } else if (error.request) {
    console.error("No response received:", error.request);
    parameter?.setErrors("No response received from the server. Please try again.");
  } else {
    console.error("Error setting up the request:", error.message);
    parameter?.setErrors(`An error occurred: ${error.message}`);
  }
});
};
const justPostDataV2 = (parameter) => {
  if (!token) {
    console.error("JWT token not found in local storage");
    return;
  }

  axios
    .post(`${process.env.REACT_APP_API_URL}${parameter?.url}`, parameter?.payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (parameter?.setSuccessCallback) {
        if (response.status == 200) {
          if (parameter?.setshowResponse) {
            parameter?.setshowResponse(true)
          }
          parameter?.setSuccessCallback(true);
        }


      } else {
        return response.status;

      }

    })
    .catch((error) => {
  console.log("error:", error.response?.data);

  if (error.response) {
    const { status, data } = error.response;

    if (status === 400 || status >= 500) {
      if (data) {
        console.log("Handling error response data...");

        // Process and collect error messages
        const errors = Object.entries(data).reduce((acc, [field, messages]) => {
          if (Array.isArray(messages)) {
            // If messages is an array, join them and remove index prefixes like "0:"
            const cleanedMessages = messages.map((msg) => msg.replace(/^\d+:\s*/, ""));
            acc.push(` ${cleanedMessages.join(", ")}`);
          } else if (typeof messages === "string") {
            // If messages is a string, remove index prefix if present
            acc.push(` ${messages.replace(/^\d+:\s*/, "")}`);
          } else {
            // Handle unexpected data types
            acc.push(`${field}: Unknown error format`);
          }
          return acc;
        }, []);

        // Set errors in the state
        if (errors.length) {
          parameter?.setErrors(errors.join("\n")); // Pass errors as a joined string
        } else {
          parameter?.setErrors("Something went wrong. Please try again later.");
        }

        // Clear the errors after a delay (optional)
        setTimeout(() => {
          parameter?.setErrors(null);
        }, 5000);
      }
    } else if (status === 401) {
      alert("Unauthorized access. Redirecting to login...");
      localStorage.clear();
      window.location.href = "/login";
    }

    console.error("Response Status:", status);
    console.error("Response Data:", data);
  } else if (error.request) {
    console.error("No response received:", error.request);
    parameter?.setErrors("No response received from the server. Please try again.");
  } else {
    console.error("Error setting up the request:", error.message);
    parameter?.setErrors(`An error occurred: ${error.message}`);
  }
});
};


const updateDataV2 = (parameter) => {
  const token = localStorage.getItem("token");
  if (!token) {
    console.error("JWT token not found in local storage");
    return;
  }

  axios
    .put(`${process.env.REACT_APP_API_URL}${parameter?.url}/`, parameter?.payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(() => {
      // window.location.reload();
    })
    .catch((error) => {
  console.log("error:", error.response?.data);

  if (error.response) {
    const { status, data } = error.response;

    if (status === 400 || status >= 500 || status===403) {
      if (data) {
        console.log("Handling error response data...");

        // Process and collect error messages
        const errors = Object.entries(data).reduce((acc, [field, messages]) => {
          if (Array.isArray(messages)) {
            // If messages is an array, join them and remove index prefixes like "0:"
            const cleanedMessages = messages.map((msg) => msg.replace(/^\d+:\s*/, ""));
            acc.push(` ${cleanedMessages.join(", ")}`);
          } else if (typeof messages === "string") {
            // If messages is a string, remove index prefix if present
            acc.push(` ${messages.replace(/^\d+:\s*/, "")}`);
          } else {
            // Handle unexpected data types
            acc.push(`${field}: Unknown error format`);
          }
          return acc;
        }, []);

        // Set errors in the state
        if (errors.length) {
          parameter?.setErrors(errors.join("\n")); // Pass errors as a joined string
        } else {
          parameter?.setErrors("Something went wrong. Please try again later.");
        }

        // Clear the errors after a delay (optional)
        setTimeout(() => {
          parameter?.setErrors(null);
        }, 5000);
      }
    } else if (status === 401) {
      alert("Unauthorized access. Redirecting to login...");
      localStorage.clear();
      window.location.href = "/login";
    }

    console.error("Response Status:", status);
    console.error("Response Data:", data);
  } else if (error.request) {
    console.error("No response received:", error.request);
    parameter?.setErrors("No response received from the server. Please try again.");
  } else {
    console.error("Error setting up the request:", error.message);
    parameter?.setErrors(`An error occurred: ${error.message}`);
  }
});

};
const updateDataNavigate = (parameter) => {
  const token = localStorage.getItem("token");
  if (!token) {
    console.error("JWT token not found in local storage");
    return;
  }

  axios
    .put(`${process.env.REACT_APP_API_URL}${parameter?.url}/`, parameter?.payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(() => {
      const successMessageColor = "#4285F4";

      if (parameter?.navUrl) {
        parameter?.navigate(`${parameter?.navUrl}`, {
          state: {
            successMessage: "Successfully updated!",
            successMessageColor,
          },
        });
      }
    })
    .catch((error) => {
  console.log("error:", error.response?.data);

  if (error.response) {
    const { status, data } = error.response;

    if (status === 400 || status >= 500) {
      if (data) {
        console.log("Handling error response data...");

        // Process and collect error messages
        const errors = Object.entries(data).reduce((acc, [field, messages]) => {
          if (Array.isArray(messages)) {
            // If messages is an array, join them and remove index prefixes like "0:"
            const cleanedMessages = messages.map((msg) => msg.replace(/^\d+:\s*/, ""));
            acc.push(` ${cleanedMessages.join(", ")}`);
          } else if (typeof messages === "string") {
            // If messages is a string, remove index prefix if present
            acc.push(` ${messages.replace(/^\d+:\s*/, "")}`);
          } else {
            // Handle unexpected data types
            acc.push(`${field}: Unknown error format`);
          }
          return acc;
        }, []);

        // Set errors in the state
        if (errors.length) {
          parameter?.setErrors(errors.join("\n")); // Pass errors as a joined string
        } else {
          parameter?.setErrors("Something went wrong. Please try again later.");
        }

        // Clear the errors after a delay (optional)
        setTimeout(() => {
          parameter?.setErrors(null);
        }, 5000);
      }
    } else if (status === 401) {
      alert("Unauthorized access. Redirecting to login...");
      localStorage.clear();
      window.location.href = "/login";
    }

    console.error("Response Status:", status);
    console.error("Response Data:", data);
  } else if (error.request) {
    console.error("No response received:", error.request);
    parameter?.setErrors("No response received from the server. Please try again.");
  } else {
    console.error("Error setting up the request:", error.message);
    parameter?.setErrors(`An error occurred: ${error.message}`);
  }
});
};
const updatewithFetchDataV2 = (parameter) => {
  const token = localStorage.getItem("token");
  if (!token) {
    console.error("JWT token not found in local storage");
    return;
  }

  axios
    .put(`${process.env.REACT_APP_API_URL}${parameter?.url}/`, parameter?.payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
      const successMessageColor = "#4285F4";

      const { status } = response
      if (status == 200) {
        if (parameter?.tofetch) {
          if (status === 201 || status === 200) {
            parameter?.tofetch?.setSuccessMessage(true)
            const timeoutId = setTimeout(() => {
              parameter?.tofetch?.setSuccessMessage(false);
            }, 5000);
            if (parameter?.tofetch?.items && Array.isArray(parameter?.tofetch.items)) {
              parameter?.tofetch.items.forEach((item) => {
                if (item.fetchurl && item.dataset) {
                  const parameter ={
                    url : item.fetchurl,
                    setterFunction : item.dataset
                  }
                  fetchDataV2(parameter);
                }
              });
            }
            if (parameter?.tofetch?.setSuccessCallback) {
              parameter?.tofetch?.setSuccessCallback(true);
            }
            parameter?.tofetch?.navigate(`${parameter?.tofetch?.navUrl}`, {
              state: {
                successMessage: "Successfully Created!",
                successMessageColor,
              },
            });
          }
        }
      }

    })
    .catch((error) => {
  console.log("error:", error.response?.data);

  if (error.response) {
    const { status, data } = error.response;

    if (status === 400 || status >= 500) {
      if (data) {
        console.log("Handling error response data...");

        // Process and collect error messages
        const errors = Object.entries(data).reduce((acc, [field, messages]) => {
          if (Array.isArray(messages)) {
            // If messages is an array, join them and remove index prefixes like "0:"
            const cleanedMessages = messages.map((msg) => msg.replace(/^\d+:\s*/, ""));
            acc.push(` ${cleanedMessages.join(", ")}`);
          } else if (typeof messages === "string") {
            // If messages is a string, remove index prefix if present
            acc.push(` ${messages.replace(/^\d+:\s*/, "")}`);
          } else {
            // Handle unexpected data types
            acc.push(`${field}: Unknown error format`);
          }
          return acc;
        }, []);

        // Set errors in the state
        if (errors.length) {
          parameter?.setErrors(errors.join("\n")); // Pass errors as a joined string
        } else {
          parameter?.setErrors("Something went wrong. Please try again later.");
        }

        // Clear the errors after a delay (optional)
        setTimeout(() => {
          parameter?.setErrors(null);
        }, 5000);
      }
    } else if (status === 401) {
      alert("Unauthorized access. Redirecting to login...");
      localStorage.clear();
      window.location.href = "/login";
    }

    console.error("Response Status:", status);
    console.error("Response Data:", data);
  } else if (error.request) {
    console.error("No response received:", error.request);
    parameter?.setErrors("No response received from the server. Please try again.");
  } else {
    console.error("Error setting up the request:", error.message);
    parameter?.setErrors(`An error occurred: ${error.message}`);
  }
});
};
const justUpdateDataV2 = (parameter) => {

  if (!token) {
    console.error("JWT token not found in local storage");
    return;
  }
  axios
    .put(`${process.env.REACT_APP_API_URL}${parameter?.url}`, parameter?.payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
      if (response.status === 200 && parameter?.setshowResponse) {
        parameter?.setshowResponse(parameter?.message)
      }
    })
    .catch((error) => {
  console.log("error:", error.response?.data);

  if (error.response) {
    const { status, data } = error.response;

    if (status === 400 || status >= 500) {
      if (data) {
        console.log("Handling error response data...");

        // Process and collect error messages
        const errors = Object.entries(data).reduce((acc, [field, messages]) => {
          if (Array.isArray(messages)) {
            // If messages is an array, join them and remove index prefixes like "0:"
            const cleanedMessages = messages.map((msg) => msg.replace(/^\d+:\s*/, ""));
            acc.push(` ${cleanedMessages.join(", ")}`);
          } else if (typeof messages === "string") {
            // If messages is a string, remove index prefix if present
            acc.push(` ${messages.replace(/^\d+:\s*/, "")}`);
          } else {
            // Handle unexpected data types
            acc.push(`${field}: Unknown error format`);
          }
          return acc;
        }, []);

        // Set errors in the state
        if (errors.length) {
          parameter?.setErrors(errors.join("\n")); // Pass errors as a joined string
        } else {
          parameter?.setErrors("Something went wrong. Please try again later.");
        }

        // Clear the errors after a delay (optional)
        setTimeout(() => {
          parameter?.setErrors(null);
        }, 5000);
      }
    } else if (status === 401) {
      alert("Unauthorized access. Redirecting to login...");
      localStorage.clear();
      window.location.href = "/login";
    }

    console.error("Response Status:", status);
    console.error("Response Data:", data);
  } else if (error.request) {
    console.error("No response received:", error.request);
    parameter?.setErrors("No response received from the server. Please try again.");
  } else {
    console.error("Error setting up the request:", error.message);
    parameter?.setErrors(`An error occurred: ${error.message}`);
  }
});

};
const updateReturnResponse = (parameter) => {
  if (!token) {
    console.error("JWT token not found in local storage");
    return;
  }

  axios
    .put(`${process.env.REACT_APP_API_URL}${parameter?.url}`, parameter?.payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const successMessageColor = "#4285F4";
      if (parameter?.setSuccessCallback != undefined) {

        parameter?.setSuccessCallback(response.data);
      }
    })
    .catch((error) => {
  console.log("error:", error.response?.data);

  if (error.response) {
    const { status, data } = error.response;

    if (status === 400 || status >= 500) {
      if (data) {
        console.log("Handling error response data...");

        // Process and collect error messages
        const errors = Object.entries(data).reduce((acc, [field, messages]) => {
          if (Array.isArray(messages)) {
            // If messages is an array, join them and remove index prefixes like "0:"
            const cleanedMessages = messages.map((msg) => msg.replace(/^\d+:\s*/, ""));
            acc.push(` ${cleanedMessages.join(", ")}`);
          } else if (typeof messages === "string") {
            // If messages is a string, remove index prefix if present
            acc.push(` ${messages.replace(/^\d+:\s*/, "")}`);
          } else {
            // Handle unexpected data types
            acc.push(`${field}: Unknown error format`);
          }
          return acc;
        }, []);

        // Set errors in the state
        if (errors.length) {
          parameter?.setErrors(errors.join("\n")); // Pass errors as a joined string
        } else {
          parameter?.setErrors("Something went wrong. Please try again later.");
        }

        // Clear the errors after a delay (optional)
        setTimeout(() => {
          parameter?.setErrors(null);
        }, 5000);
      }
    } else if (status === 401) {
      alert("Unauthorized access. Redirecting to login...");
      localStorage.clear();
      window.location.href = "/login";
    }

    console.error("Response Status:", status);
    console.error("Response Data:", data);
  } else if (error.request) {
    console.error("No response received:", error.request);
    parameter?.setErrors("No response received from the server. Please try again.");
  } else {
    console.error("Error setting up the request:", error.message);
    parameter?.setErrors(`An error occurred: ${error.message}`);
  }
});
};


const postDataWithFile = (parameter) => {
  const token = localStorage.getItem("token");

  if (!token) {
    console.error("JWT token not found in local storage");
    return;
  }

  const { url, data, files, navigate, setErrors, tofetch, navUrl, setSuccessCallback, setSuccessMessage } = parameter;

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const uploadFile = (file) => {
    const requestData = new FormData();
    if (data) {
      Object.keys(data).forEach((key) => {
        requestData.append(key, data[key]);
      });
    }
    requestData.append("file", file);

    axios
      .post(`${process.env.REACT_APP_API_URL}${url}`, requestData, { headers })
      .then((response) => {
        const { status } = response;
        const successMessageColor = "#4285F4";

        if (status === 201) {
          if (parameter?.tofetch) {
            if (status === 201 || status === 200) {
              parameter?.tofetch?.setSuccessMessage(true)
              const timeoutId = setTimeout(() => {
                parameter?.tofetch?.setSuccessMessage(false);
              }, 5000);
              if (parameter?.tofetch?.items && Array.isArray(parameter?.tofetch.items)) {
                parameter?.tofetch.items.forEach((item) => {
                  if (item.fetchurl && item.dataset) {
                    const parameter ={
                      url : item.fetchurl,
                      setterFunction : item.dataset
                    }
                    fetchDataV2(parameter);
                  }
                });
              }
              if (parameter?.tofetch?.setSuccessCallback) {
                parameter?.tofetch?.setSuccessCallback(true);
              }
              parameter?.tofetch?.navigate(`${parameter?.tofetch?.navUrl}`, {
                state: {
                  successMessage: "Successfully Created!",
                  successMessageColor,
                },
              });
            }
          }
        }
        if(setSuccessMessage){
          setSuccessMessage(true)
        }

        if (setSuccessCallback) {
          setSuccessCallback(response.data);
        } else if (navigate) {
          navigate(`${navUrl}`, {
            state: {
              successMessage: "Successfully Created!",
              successMessageColor,
            },
          });
        }
      })
      // .catch((error) => handleErrors(error, parameter));
      .catch((error) => {
  console.log("error:", error.response?.data);

  if (error.response) {
    const { status, data } = error.response;

    if (status === 400 || status >= 500) {
      if (data) {
        console.log("Handling error response data...");

        // Process and collect error messages
        const errors = Object.entries(data).reduce((acc, [field, messages]) => {
          if (Array.isArray(messages)) {
            // If messages is an array, join them and remove index prefixes like "0:"
            const cleanedMessages = messages.map((msg) => msg.replace(/^\d+:\s*/, ""));
            acc.push(` ${cleanedMessages.join(", ")}`);
          } else if (typeof messages === "string") {
            // If messages is a string, remove index prefix if present
            acc.push(` ${messages.replace(/^\d+:\s*/, "")}`);
          } else {
            // Handle unexpected data types
            acc.push(`${field}: Unknown error format`);
          }
          return acc;
        }, []);

        // Set errors in the state
        if (errors.length) {
          parameter?.setErrors(errors.join("\n")); // Pass errors as a joined string
        } else {
          parameter?.setErrors("Something went wrong. Please try again later.");
        }

        // Clear the errors after a delay (optional)
        setTimeout(() => {
          parameter?.setErrors(null);
        }, 5000);
      }
    } else if (status === 401) {
      alert("Unauthorized access. Redirecting to login...");
      localStorage.clear();
      window.location.href = "/login";
    }

    console.error("Response Status:", status);
    console.error("Response Data:", data);
  } else if (error.request) {
    console.error("No response received:", error.request);
    parameter?.setErrors("No response received from the server. Please try again.");
  } else {
    console.error("Error setting up the request:", error.message);
    parameter?.setErrors(`An error occurred: ${error.message}`);
  }
});
  };

  if (Array.isArray(files) && files.length > 0) {
    files.forEach((file) => uploadFile(file));
  } else {
    const requestData = JSON.stringify(data); // If no files, send JSON
    headers["Content-Type"] = "application/json";

    axios
      .post(`${process.env.REACT_APP_API_URL}${url}`, requestData, { headers })
      .then((response) => {
        const successMessageColor = "#4285F4";

        if (setSuccessCallback) {
          setSuccessCallback(response.data);
        } else if (navigate) {
          navigate(`${navUrl}`, {
            state: {
              successMessage: "Successfully Created!",
              successMessageColor,
            },
          });
        }
      })
      // .catch((error) => handleErrors(error, parameter));
      .catch((error) => {
  console.log("error:", error.response?.data);

  if (error.response) {
    const { status, data } = error.response;

    if (status === 400 || status >= 500) {
      if (data) {
        console.log("Handling error response data...");

        // Process and collect error messages
        const errors = Object.entries(data).reduce((acc, [field, messages]) => {
          if (Array.isArray(messages)) {
            // If messages is an array, join them and remove index prefixes like "0:"
            const cleanedMessages = messages.map((msg) => msg.replace(/^\d+:\s*/, ""));
            acc.push(` ${cleanedMessages.join(", ")}`);
          } else if (typeof messages === "string") {
            // If messages is a string, remove index prefix if present
            acc.push(` ${messages.replace(/^\d+:\s*/, "")}`);
          } else {
            // Handle unexpected data types
            acc.push(`${field}: Unknown error format`);
          }
          return acc;
        }, []);

        // Set errors in the state
        if (errors.length) {
          parameter?.setErrors(errors.join("\n")); // Pass errors as a joined string
        } else {
          parameter?.setErrors("Something went wrong. Please try again later.");
        }

        // Clear the errors after a delay (optional)
        setTimeout(() => {
          parameter?.setErrors(null);
        }, 5000);
      }
    } else if (status === 401) {
      alert("Unauthorized access. Redirecting to login...");
      localStorage.clear();
      window.location.href = "/login";
    }

    console.error("Response Status:", status);
    console.error("Response Data:", data);
  } else if (error.request) {
    console.error("No response received:", error.request);
    parameter?.setErrors("No response received from the server. Please try again.");
  } else {
    console.error("Error setting up the request:", error.message);
    parameter?.setErrors(`An error occurred: ${error.message}`);
  }
});
  }
};

const handleErrors = (error, parameter) => {
  const { setErrors, navigate, navUrl } = parameter;

  if (error.response) {
    if (error.response.status === 400 || error.response.status > 500) {
      const errorMessages = error.response.data
        ? Object.entries(error.response.data)
            .map(([field, messages]) => `${field}: ${messages.join(", ")}`)
            .join("\n")
        : "Something went wrong. Please try again later.";

      setErrors(errorMessages);

      // Clear errors after 5 seconds
      setTimeout(() => {
        setErrors(null);
      }, 5000);
    } else if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/login";
    }

    console.error("Response Status:", error.response.status);
    console.error("Response Data:", error.response.data);
  } else if (error.request) {
    console.error("No response received:", error.request);
  } else {
    console.error("Error setting up the request:", error.message);
  }
};


const deleteDataV2 = (parameter) => {
  if (!token) {
    console.error("JWT token not found in local storage");
    return Promise.resolve();
  }

  return axios
    .delete(`${process.env.REACT_APP_API_URL}${parameter?.url}/`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((response) => {
   
      const successMessageColor = "#FFA2A2";
      parameter?.navigate(`${parameter?.navUrl}`, {
        state: {
          successMessage: "Successfully Deleted!",
          successMessageColor,
        },

      });
      
      if (response.status === 204) {
        return response.status;

      }
    })
    .catch((error) => {
  console.log("error:", error.response?.data);

  if (error.response) {
    const { status, data } = error.response;

    if (status === 400 || status >= 500) {
      if (data) {
        console.log("Handling error response data...");

        // Process and collect error messages
        const errors = Object.entries(data).reduce((acc, [field, messages]) => {
          if (Array.isArray(messages)) {
            // If messages is an array, join them and remove index prefixes like "0:"
            const cleanedMessages = messages.map((msg) => msg.replace(/^\d+:\s*/, ""));
            acc.push(` ${cleanedMessages.join(", ")}`);
          } else if (typeof messages === "string") {
            // If messages is a string, remove index prefix if present
            acc.push(` ${messages.replace(/^\d+:\s*/, "")}`);
          } else {
            // Handle unexpected data types
            acc.push(`${field}: Unknown error format`);
          }
          return acc;
        }, []);

        // Set errors in the state
        if (errors.length) {
          parameter?.setErrors(errors.join("\n")); // Pass errors as a joined string
        } else {
          parameter?.setErrors("Something went wrong. Please try again later.");
        }

        // Clear the errors after a delay (optional)
        setTimeout(() => {
          parameter?.setErrors(null);
        }, 5000);
      }
    } else if (status === 401) {
      alert("Unauthorized access. Redirecting to login...");
      localStorage.clear();
      window.location.href = "/login";
    }

    console.error("Response Status:", status);
    console.error("Response Data:", data);
  } else if (error.request) {
    console.error("No response received:", error.request);
    parameter?.setErrors("No response received from the server. Please try again.");
  } else {
    console.error("Error setting up the request:", error.message);
    parameter?.setErrors(`An error occurred: ${error.message}`);
  }
});
};
const deleteDataWithFetchV2 = (parameter) => {
  const token = localStorage.getItem("token");

  if (!token) {
    console.error("JWT token not found in local storage");
    return Promise.resolve(); // Resolve the promise immediately if token is missing
  }

  return axios
    .delete(`${process.env.REACT_APP_API_URL}${parameter?.url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const successMessageColor = "#FFA2A2";
      const { status } = response;
      if (parameter?.tofetch) {
        if (status === 201 || status === 200 || status === 204) {
          parameter?.tofetch?.setSuccessMessage(true);
          const timeoutId = setTimeout(() => {
            parameter?.tofetch?.setSuccessMessage(false);
          }, 5000);

          if (parameter?.tofetch?.items && Array.isArray(parameter?.tofetch.items)) {
            parameter?.tofetch.items.forEach((item) => {
              if (item.fetchurl && item.dataset) {
                const parameter = {
                  url: item.fetchurl,
                  setterFunction: item.dataset,
                };
                fetchDataV2(parameter);
              }
            });
          }

          if (parameter?.tofetch?.setSuccessCallback) {
            parameter?.tofetch?.setSuccessCallback(true);
          }

          if (parameter?.tofetch?.navigate) {
            parameter.tofetch.navigate(`${parameter.tofetch.navUrl}`, {
              state: {
                successMessage: "Successfully Created!",
                successMessageColor,
              },
            });
          }
        }
      }
    })
    .catch((error) => {
      console.log("error:", error.response?.data);

      if (error.response) {
        const { status, data } = error.response;

        if (status === 400 || status >= 500) {
          if (data) {
            console.log("Handling error response data...");

            // Process and collect error messages
            const errors = Object.entries(data).reduce((acc, [field, messages]) => {
              if (Array.isArray(messages)) {
                // If messages is an array, join them and remove index prefixes like "0:"
                const cleanedMessages = messages.map((msg) => msg.replace(/^\d+:\s*/, ""));
                acc.push(` ${cleanedMessages.join(", ")}`);
              } else if (typeof messages === "string") {
                // If messages is a string, remove index prefix if present
                acc.push(` ${messages.replace(/^\d+:\s*/, "")}`);
              } else {
                // Handle unexpected data types
                acc.push(`${field}: Unknown error format`);
              }
              return acc;
            }, []);

            // Set errors in the state
            if (errors.length) {
              parameter?.setErrors(errors.join("\n")); // Pass errors as a joined string
            } else {
              parameter?.setErrors("Something went wrong. Please try again later.");
            }

            // Clear the errors after a delay (optional)
            setTimeout(() => {
              parameter?.setErrors(null);
            }, 5000);
          }
        } else if (status === 401) {
          alert("Unauthorized access. Redirecting to login...");
          localStorage.clear();
          window.location.href = "/login";
        }

        console.error("Response Status:", status);
        console.error("Response Data:", data);
      } else if (error.request) {
        console.error("No response received:", error.request);
        parameter?.setErrors("No response received from the server. Please try again.");
      } else {
        console.error("Error setting up the request:", error.message);
        parameter?.setErrors(`An error occurred: ${error.message}`);
      }
    });
};

const justDeleteDataV2 = (parameter) => {
  if (!token) {
    console.error("JWT token not found in local storage");
    return Promise.resolve(); // Resolve the promise immediately if token is missing
  }

  return axios
    .delete(`${process.env.REACT_APP_API_URL}${parameter?.url}/`,{
      headers: {
        Authorization: `Bearer ${token}`
      },
      data: parameter?.payload
       // Pass data in the config object
    })
    .then((response) => {
      if (response.status === 204) {
        return response.status; 
      }
      window.location.reload();
    })
    .catch((error) => {
  console.log("error:", error.response?.data);

  if (error.response) {
    const { status, data } = error.response;

    if (status === 400 || status >= 500) {
      if (data) {
        console.log("Handling error response data...");

        // Process and collect error messages
        const errors = Object.entries(data).reduce((acc, [field, messages]) => {
          if (Array.isArray(messages)) {
            // If messages is an array, join them and remove index prefixes like "0:"
            const cleanedMessages = messages.map((msg) => msg.replace(/^\d+:\s*/, ""));
            acc.push(` ${cleanedMessages.join(", ")}`);
          } else if (typeof messages === "string") {
            // If messages is a string, remove index prefix if present
            acc.push(` ${messages.replace(/^\d+:\s*/, "")}`);
          } else {
            // Handle unexpected data types
            acc.push(`${field}: Unknown error format`);
          }
          return acc;
        }, []);

        // Set errors in the state
        if (errors.length) {
          parameter?.setErrors(errors.join("\n")); // Pass errors as a joined string
        } else {
          parameter?.setErrors("Something went wrong. Please try again later.");
        }

        // Clear the errors after a delay (optional)
        setTimeout(() => {
          parameter?.setErrors(null);
        }, 5000);
      }
    } else if (status === 401) {
      alert("Unauthorized access. Redirecting to login...");
      localStorage.clear();
      window.location.href = "/login";
    }

    console.error("Response Status:", status);
    console.error("Response Data:", data);
  } else if (error.request) {
    console.error("No response received:", error.request);
    parameter?.setErrors("No response received from the server. Please try again.");
  } else {
    console.error("Error setting up the request:", error.message);
    parameter?.setErrors(`An error occurred: ${error.message}`);
  }
});
};



const fetchDataV2 = (parameter) => {
  if (!token) return;
  axios
    .get(`${process.env.REACT_APP_API_URL}${parameter?.url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (Array.isArray(response.data)) {
        parameter?.setterFunction(response.data);
      } else {
        parameter?.setterFunction(response.data); 
      }
      
      if (response.status === 200) {
        return response.status; 
      } 
    })
    .catch((error) => {
  console.log("error:", error.response?.data);

  if (error.response) {
    const { status, data } = error.response;

    if (status === 400 || status >= 500) {
      if (data) {
        console.log("Handling error response data...");

        // Process and collect error messages
        const errors = Object.entries(data).reduce((acc, [field, messages]) => {
          if (Array.isArray(messages)) {
            // If messages is an array, join them and remove index prefixes like "0:"
            const cleanedMessages = messages.map((msg) => msg.replace(/^\d+:\s*/, ""));
            acc.push(` ${cleanedMessages.join(", ")}`);
          } else if (typeof messages === "string") {
            // If messages is a string, remove index prefix if present
            acc.push(` ${messages.replace(/^\d+:\s*/, "")}`);
          } else {
            // Handle unexpected data types
            acc.push(`${field}: Unknown error format`);
          }
          return acc;
        }, []);

        // Set errors in the state
        if (errors.length) {
          parameter?.setErrors(errors.join("\n")); // Pass errors as a joined string
        } else {
          parameter?.setErrors("Something went wrong. Please try again later.");
        }

        // Clear the errors after a delay (optional)
        setTimeout(() => {
          parameter?.setErrors(null);
        }, 5000);
      }
    } else if (status === 401) {
      alert("Unauthorized access. Redirecting to login...");
      localStorage.clear();
      window.location.href = "/login";
    }

    console.error("Response Status:", status);
    console.error("Response Data:", data);
  } else if (error.request) {
    console.error("No response received:", error.request);
    parameter?.setErrors("No response received from the server. Please try again.");
  } else {
    console.error("Error setting up the request:", error.message);
    parameter?.setErrors(`An error occurred: ${error.message}`);
  }
});
};



const justFetchDataV2 = (parameter) => {
  if (!token) return; 

  parameter?.setLoading(true); 

  axios
    .get(`${process.env.REACT_APP_API_URL}${parameter?.url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (Array.isArray(response.data)) {
        parameter?.setterFunction(response.data);
      } else {
        parameter?.setterFunction([response.data]); 
      }

      if (response.status === 200) {
        return response.status; 
      } 

    })
    .catch((error) => {
  console.log("error:", error.response?.data);

  if (error.response) {
    const { status, data } = error.response;

    if (status === 400 || status >= 500) {
      if (data) {
        console.log("Handling error response data...");

        // Process and collect error messages
        const errors = Object.entries(data).reduce((acc, [field, messages]) => {
          if (Array.isArray(messages)) {
            // If messages is an array, join them and remove index prefixes like "0:"
            const cleanedMessages = messages.map((msg) => msg.replace(/^\d+:\s*/, ""));
            acc.push(` ${cleanedMessages.join(", ")}`);
          } else if (typeof messages === "string") {
            // If messages is a string, remove index prefix if present
            acc.push(` ${messages.replace(/^\d+:\s*/, "")}`);
          } else {
            // Handle unexpected data types
            acc.push(`${field}: Unknown error format`);
          }
          return acc;
        }, []);

        // Set errors in the state
        if (errors.length) {
          parameter?.setErrors(errors.join("\n")); // Pass errors as a joined string
        } else {
          parameter?.setErrors("Something went wrong. Please try again later.");
        }

        // Clear the errors after a delay (optional)
        setTimeout(() => {
          parameter?.setErrors(null);
        }, 5000);
      }
    } else if (status === 401) {
      alert("Unauthorized access. Redirecting to login...");
      localStorage.clear();
      window.location.href = "/login";
    }

    console.error("Response Status:", status);
    console.error("Response Data:", data);
  } else if (error.request) {
    console.error("No response received:", error.request);
    parameter?.setErrors("No response received from the server. Please try again.");
  } else {
    console.error("Error setting up the request:", error.message);
    parameter?.setErrors(`An error occurred: ${error.message}`);
  }
})
    .finally(() => {
      parameter?.setLoading(false); 
    });
};

const fetchDataObjectV2 = (parameter) => {
  if (!token) return; // If token doesn't exist, exit function
if (parameter?.setLoading){
  parameter?.setLoading(true); // Set loading to true before API call
}
  axios
    .get(`${process.env.REACT_APP_API_URL}${parameter?.url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      // Check if response.data is an object
      if (typeof response.data === "object" && !Array.isArray(response.data)) {
        parameter?.setterFunction(response.data); // Directly set the object
      } else {
        parameter?.setErrors("Unexpected response data format.");
      }

      if (response.status === 200) {
        return response.status; // Return the status code
      }
    })
    .catch((error) => {
  console.log("error:", error.response?.data);

  if (error.response) {
    const { status, data } = error.response;

    if (status === 400 || status >= 500) {
      if (data) {
        console.log("Handling error response data...");

        // Process and collect error messages
        const errors = Object.entries(data).reduce((acc, [field, messages]) => {
          if (Array.isArray(messages)) {
            // If messages is an array, join them and remove index prefixes like "0:"
            const cleanedMessages = messages.map((msg) => msg.replace(/^\d+:\s*/, ""));
            acc.push(` ${cleanedMessages.join(", ")}`);
          } else if (typeof messages === "string") {
            // If messages is a string, remove index prefix if present
            acc.push(` ${messages.replace(/^\d+:\s*/, "")}`);
          } else {
            // Handle unexpected data types
            acc.push(`${field}: Unknown error format`);
          }
          return acc;
        }, []);

        // Set errors in the state
        if (errors.length) {
          parameter?.setErrors(errors.join("\n")); // Pass errors as a joined string
        } else {
          parameter?.setErrors("Something went wrong. Please try again later.");
        }

        // Clear the errors after a delay (optional)
        setTimeout(() => {
          parameter?.setErrors(null);
        }, 5000);
      }
    } else if (status === 401) {
      alert("Unauthorized access. Redirecting to login...");
      localStorage.clear();
      window.location.href = "/login";
    }

    console.error("Response Status:", status);
    console.error("Response Data:", data);
  } else if (error.request) {
    console.error("No response received:", error.request);
    parameter?.setErrors("No response received from the server. Please try again.");
  } else {
    console.error("Error setting up the request:", error.message);
    parameter?.setErrors(`An error occurred: ${error.message}`);
  }
})
    .finally(() => {
      if (parameter?.setLoading){
      parameter?.setLoading(false);
      } // Set loading to false after API call (both success and error cases)
    });
    
};



const fetchFile = async (url) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}${url}`, {
      responseType: 'blob',
    });
    const file = new Blob([response.data], { type: response.data.type });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, '_blank');
  } catch (error) {
    console.error('Error fetching file:', error);
  }
};





export { fetchDataV2,justFetchDataV2, convertDate, fetchDataObjectV2,postDataWithFile, postDataV2, postDataReturnId, justPostDataV2 , postDataWithFetchV2,updatewithFetchDataV2, updateDataV2, updateDataNavigate, updateReturnResponse, deleteDataWithFetchV2,deleteDataV2,justDeleteDataV2,justUpdateDataV2, fetchFile };
