import React, { useState, useEffect } from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../../Breadcrumb";
import SidebarMenu from "../../../SidebarMenu";
import create from "../../../../images/createbutton.svg";
import star from "../../../../images/star.svg";
import risk from "../../../../images/risk.svg";
import auditPlan from "../../../../images/auditPlan.svg";
import "../../../../css/viewuser.css";
import openarrow from "../../../../images/openarrow.svg";
import "../createRiskAssessment/createriskassessment.css";
import edit_pencil from "../../../../images/edit_pencil.svg";
import { fetchDataV2 } from "../../../apiUtils";

const routes = [
  { path: "/AuditUniverse/AuditEntities", name: "Audit Universe", icon: star },
  { path: "/AuditUniverse/riskAssesment", name: "Risk Assesment", icon: risk },
  {
    path: "/AuditUniverse/auditPlanning",
    name: "Audit Planning",
    icon: auditPlan,
  },
];

const CreateRiskAssessment = ({ tableSize }) => {
  const [APIData, setAPIData] = useState([]);
  const [errors, setErrors] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const successMessage = location.state?.successMessage;

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate(".", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const parameter={
      url:`/api/audit_universe/audit-entities/`,
      setterFunction: setAPIData,
      setErrors:setErrors,
    }
    fetchDataV2(parameter);
  }, []);

  const renderIcons = () => {
    return routes.map((route, index) => {
      if (route.subRoutes) {
        return <SidebarMenu route={route} key={index} />;
      }

      return (
        <NavLink
          to={route.path}
          key={index}
          className="navlink"
          activeclassname="active1"
        >
          <div className="navicon">
            {<img src={route.icon} alt="Dashboard Icon" />}
          </div>
          <div className="navicon">{route.name}</div>
        </NavLink>
      );
    });
  };

  // State for checked checkboxes
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');

  const removeAll = () => {
    setCheckedItems([]);
  };
  const selectAll = () => {
    const allIds = APIData.map((data) => data.id);
    setCheckedItems(allIds);
  };

  const handleCheckboxChange = (e) => {
    const itemId = parseInt(e.target.id);
    if (e.target.checked) {
      setCheckedItems([...checkedItems, itemId]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== itemId));
    }
  };
    const handleDateChange = (e) => {
      setSelectedDate(e.target.value);
    };



  return (
    <>
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        <div className="bumenucontainer">{renderIcons()}</div>

        <hr className="breadcrumbabv" />
        <Breadcrumb />

        <div className="filter-container">
          <div className="filter">
            <div className="views">
              <input type="text" />
              <img src={edit_pencil} />
            </div>
          </div>
        </div>

        <div
          className="createriskassessment_con"
          style={{ width: tableSize ? "80vw" : "90.5vw" }}
        >
          <form>
            <div className="entiteslist">
              <div className="heading_select">
                <h4>Add Entities</h4>

                <div className="select_con">
                  <h6 onClick={removeAll}>Select None</h6>
                  <h6 onClick={selectAll}>Select All</h6>
                </div>
              </div>

              <div className="entites_list">
                {APIData.map((data) => (
                  <div className="entity" key={data.id}>
                    <input
                      type="checkbox"
                      id={data.id}
                      checked={checkedItems.includes(data.id)}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor={data.id}>{data.name}</label>
                  </div>
                ))}
              </div>
            </div>

            <div className="date_section">
              <h5>Date</h5>

              <div className="formDate">
              <input
            type="date"
            id="date-picker"
            name="date-picker"
            placeholder="--"
            value={selectedDate}
            onChange={handleDateChange}
          />
              </div>
            </div>
          </form>
        </div>

      </section>
    </>
  );
};

export default CreateRiskAssessment;
