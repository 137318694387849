import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams,useLocation } from "react-router-dom";
import "../../../css/viewuser.css";
import "../riskAssesment/riskassessment.css";
import { justFetchDataV2} from "../../apiUtils";
import { auditRoutes } from "../../subroutes";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";
import BounceLoader from "react-spinners/BounceLoader";


const AuditPlanTable = ({ tableSize }) => {
    const { id } = useParams();
    const [APIData, setAPIData] = useState([]);
    const [errors, setErrors] = useState("");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const successMessage = location.state?.successMessage;

    useEffect(() => {
        const timeoutId = setTimeout(() => {
          navigate(".", { replace: true });
        }, 5000);
    
        return () => clearTimeout(timeoutId);
      }, [navigate]);

    const Viewuser = (data) => {
        console.log(data);
        navigate(`/AuditUniverse/auditPlanning/${data.id}`);
    };

    useEffect(() => {
        const parameter ={
            url : `/api/audit_universe/audit-plan/`,
            setterFunction :(data) => {setAPIData(data)},
            setErrors : (error) => {setErrors(error)},
            setLoading: setLoading,
          }
          
          justFetchDataV2(parameter);
    }, [id]);

    const [filters, setFilters] = useState({
        AuditTitle: "",
        audit_entityName: "",
        status: "",
    });

    const handleFilterChange = (filterKey, e) => {
        const value = e.target.value.toLowerCase();
        setFilters({ ...filters, [filterKey]: value });
    };

    const filteredData = useMemo(() => {
        return APIData.filter((data) => {
            const title = data?.risk_assessment?.title?.toLowerCase() || "";
            const status = data?.status?.toLowerCase() || "--";
            const auditEntityMatch = data?.audit_events?.some((event) =>
                event?.audit_entity?.name?.toLowerCase().includes(filters.audit_entityName)
            );

            return (
                (!filters.AuditTitle || title.includes(filters.AuditTitle)) &&
                (!filters.status || status.includes(filters.status)) &&
                (!filters.audit_entityName || auditEntityMatch)
            );
        });
    }, [APIData, filters]);

    const PlanStatus = (status) => {
        if (status === "Open") {
            return <span className="status_todo">{status}</span>;
        } else if (status === "In Progress") {
            return <span className="status_inprogress">{status}</span>;
        } else if (status === "Completed") {
            return <span className="status_complete">{status}</span>;
        } else {
            return <span className="status_default">{status}</span>; // Default case
        }
    };

    return (
        <>
        {successMessage && <p className="successmessage">{successMessage}</p>}
            <RenderIcons homeRoutes={auditRoutes} />

            <NavRow name={"Audit Planning"} Create={'Create'} idtrue={true} />

            <div className="tablecontainer">
                <table style={{ width: tableSize ? "80vw" : "90.5vw" }}>
                    <thead style={{ position: "sticky", top: "0", zIndex: 1000 }}>
                        <tr className="tablehead">
                            <th>
                                <h4 className="thhead">Name</h4>
                                <input
                                    type="text"
                                    placeholder="--"
                                    className="thfilter"
                                    onChange={(e) => handleFilterChange("audit_entityName", e)}
                                    value={filters.audit_entityName}
                                />
                            </th>
                            <th>
                                <h4 className="thhead">Year</h4>
                                <input
                                    type="text"
                                    placeholder="--"
                                    className="thfilter"
                                    onChange={(e) => handleFilterChange("AuditTitle", e)}
                                    value={filters.AuditTitle}
                                />
                            </th>
                            <th>
                                <h4 className="thhead">Status</h4>
                                <input
                                    type="text"
                                    placeholder="--"
                                    className="thfilter"
                                    onChange={(e) => handleFilterChange("status", e)}
                                    value={filters.status}
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <div className="spinner-bounce-loader">
                                <BounceLoader color="#a6fafa" />
                            </div>

                        ) : filteredData.length === 0 ? (
                            <p className="data-error-text">No data found</p>
                        ) : (
                            filteredData.map((data, index) => (
                                <tr onClick={() => Viewuser(data)} key={index}>
                                    <td>
                                        <div className="tagscontainer" style={{ display: 'flex', gap: '.5vw' }}>
                                            {data?.audit_events
                                                .slice(0, 3)
                                                .map((event, eventIndex) => (
                                                    <div
                                                        key={eventIndex}
                                                        style={{
                                                            backgroundColor: '#12b4bf',
                                                            borderRadius: '15px',
                                                            padding: '.4vw 1.2vw',
                                                            color: '#fff'
                                                        }}
                                                    >
                                                        <span>{event.audit_entity.name}</span>
                                                    </div>
                                                ))
                                                .concat(data?.audit_events?.length > 3 ? (
                                                    <div
                                                        key="ellipsis"
                                                        style={{
                                                            backgroundColor: '#12b4bf',
                                                            borderRadius: '15px',
                                                            padding: '.4vw 1.2vw',
                                                            color: '#fff'
                                                        }}
                                                    >
                                                        <span>...</span>
                                                    </div>
                                                ) : null)}
                                        </div>
                                    </td>
                                    <td>{data?.title}</td>
                                    <td>{PlanStatus(data?.status)}</td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>

            <div className="cardscontainer">

                {loading ? (
                    <div className="spinner-bounce-loader">
                        <BounceLoader color="#a6fafa" />
                    </div>
                ) : filteredData.length === 0 ? (
                    <p className="data-error-text">No data found</p>
                ) : (
                    filteredData.map((data, index) => (

                        <div
                            className="deptcard"
                            onClick={() => Viewuser(data)} // Pass the data to Viewuser function
                            key={index}
                        >

                            {/* Department Name */}
                            <h2>
                                {data.title ? (
                                    data.title
                                ) : (
                                    <p className="deptplacehlder">Department Name</p>
                                )}
                            </h2>

                            {/* Tags */}
                            <div className="tagscontainer" style={{ display: 'flex', gap: '.5vw', marginTop:"12px" }}>
                                {data?.audit_events?.slice(0, 3).map((event, eventIndex) => (
                                    <div
                                        key={eventIndex}
                                        style={{
                                            backgroundColor: '#12b4bf',
                                            borderRadius: '2px',
                                            padding: '4px 8px',
                                            color: '#fff',
                                            fontSize : "12px",
                                        }}
                                    >
                                        <span>{event.audit_entity.name}</span>
                                    </div>
                                ))}
                                {data?.audit_events?.length > 3 && (
                                    <div
                                        style={{
                                            backgroundColor: '#12b4bf',
                                            borderRadius: '15px',
                                            padding: '.4vw 1.2vw',
                                            color: '#fff'
                                        }}
                                    >
                                        <span>...</span>
                                    </div>
                                )}
                            </div>

                        </div>

                    ))
                )}


            </div>
        </>
    );
}




export default AuditPlanTable
