import React, { useState, navigate } from "react";
// import Breadcrumb from "../../../Breadcrumb";
import {
  NavLink,
  useLocation,
  Link,
  useNavigate,
  useParams,
} from "react-router-dom";
import SidebarMenu from "../../../SidebarMenu";
import { postDataV2 } from "../../../apiUtils";
import { auditExecution } from "../../../subroutes";
import backlogo from "../../../../images/backLogo.svg"
import AuditMemo from "../../../AuditMemo";
import TaskDetails from "../../../TaskDetails"
import IconButton from "../../../iconbutton/IconButton";
import SingleOptionSelector from "../../../SingleOptionSelector";
import "../viewTask/viewtask.css"

const CreateInitialReqList = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [successMessage, setSuccessMessage] = useState(""); 
  const [objective, setObjective] = useState("");
  const [list, setInitialReq] = useState("");
  const [initialReqError, setInitialReqError] = useState(false);
  const [scope, setScope] = useState("");
  const [duration, setDuration] = useState("");
  const [relevant , setRelevant ] = useState("");
  const [scopeError, setScopeError] = useState(false);
  const [durationError, setDurationError] = useState(false);
  const [relevantError , setRelevantError ] = useState(false);
  const [objectiveError , setObjectiveError ] = useState(false);
  const [preparer_id, setPreparerId] = useState("");
  const [PreparerError, setPreparerError] = useState(false);
  const [reviewer_id, setReviewerId] = useState("");
  const [reviewerError, setReviewerError] = useState(false);
  const [assign_to_id, setAssign_To] = useState("");
  const [assignError, setAssignError] = useState(false);
  const [participants_id, setParticipants] = useState([]);
  const [scheduled_start_date, setStartDate] = useState("");
  const [start_dateError, setStart_dateError] = useState(false);
  const [scheduled_end_date, setEndDate] = useState("");
  const [end_dateError, setEnd_dateError] = useState(false);
  const [editMode, setEditMode] = useState(true);

  const { id } = useParams();
  const navigate=useNavigate()


  const postApi = (e) => {
    e.preventDefault();
    if (!preparer_id || !reviewer_id || !assign_to_id || !scheduled_start_date || !scheduled_end_date || !id || !list ||!objective ) {
      setPreparerError(!preparer_id ? true : false);
      setObjectiveError(!objective ? true :false)
      setReviewerError(!reviewer_id ? true : false);
      setAssignError(!assign_to_id ? true : false);
      setStart_dateError(!scheduled_start_date ? true : false)
      setEnd_dateError(!scheduled_end_date ? true : false)
      setInitialReqError(!list ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }
    const parameter ={
      url : `/api/audit_engagement/plan/`,
      payload : { 
        objective,
      preparer_id,
      reviewer_id,
      assign_to_id,
      scheduled_start_date,
      scheduled_end_date,
      type :"Send Initial List Of Requirements",
      audit_id :id,
      list
      },
      navigate : navigate,
      setErrors : setErrors,
      navUrl :  `/AuditExecution/${id}/Planning`
    
    }
    postDataV2(parameter);
  };


  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}
      {successMessage && <p className="successmessage">{successMessage}</p>}

      <section className="navroutes">
        <div className="filter-container">
          <div className="filter">
            <div className="views">
              <div
                style={{
                  width: tableSize ? "79.5vw" : "90vw",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h3 className="h3_interextrabold" >View List of Initial Requirement</h3>
                <Link to={`/AuditExecution/${id}/Planning`}>
                  <div
                    className="backBtnDiv"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "1vw",
                      backgroundColor: "#FFF3CC",
                      padding: ".3vw 1.2vw",
                      borderRadius: "2.5vw",
                    }}
                  >
                    <img src={backlogo} style={{ width: "1.2vw" }} />
                    <p
                      style={{
                        fontFamily: "intersemibold",
                        fontSize: ".8vw",
                        color: "#775F0F",
                      }}
                    >
                      Back to all tasks
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div
          className="createauditmemo"
          style={{
            width: tableSize ? "79.5vw" : "90vw"
          }}
        >

          <form>
          <div
            className="lng_lbl_inpt"
          >
            <label>Objective</label>
            <input
              type="text"
              style={{ border : objectiveError ? "1px solid #FF4646" : "", }}
              placeholder="Objective"
              value={objective}
              onChange={(e) => {
                  setObjective(e.target.value);
                  setObjectiveError(false)
                }}
            />
          </div>
          
          
            <div
              className="lbl_inpt"
            >
              <label> Start Date: </label>{" "}
              <input
               style={{border : start_dateError ? "1px solid #FF4646" : "", }}
                type="datetime-local"
                value={scheduled_start_date}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  setStart_dateError(false)
                }}
              />
            </div>

            <div
              className="lbl_inpt"
            >
              <label> End Date: </label> 
              <input type="datetime-local"
               style={{ border : end_dateError ? "1px solid #FF4646" : "", }}
                value={scheduled_end_date}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  setEnd_dateError(false)
                }}
               />
            </div>
         

         
            
              <SingleOptionSelector
                apiUrl="/api/audit_universe/audit-team/"
                setSelectorId={setPreparerId}
                setSelectorError={setPreparerError}
                selectorError={PreparerError}
                Name="name"
                title="Preparer"
                editMode={editMode}
              />
          

            
              <SingleOptionSelector
                apiUrl="/api/audit_universe/admin-audit-team/"
                setSelectorId={setReviewerId}
                setSelectorError={setReviewerError}
                selectorError={reviewerError}
                Name="name"
                title="Reviewer"
                editMode={editMode}
              />
           
        

              <SingleOptionSelector
                apiUrl="/api/audit_universe/business-stakeholders/"
                setSelectorId={setAssign_To}
                setSelectorError={setAssignError}
                selectorError={assignError}
                Name="name"
                title="Assign To"
                editMode={editMode}
              />
           

         
        
          
             
            <div className="lbl_txa">
              <label>List down the initial requirements </label>

              <textarea
                style={{
                  width: tableSize ? "70vw" : "80vw",
                  border : initialReqError ? "1px solid #FF4646" : "",
                }}
                placeholder="Lists"
                value={list}
                onChange={(e) => {
                  setInitialReq(e.target.value);
                  setInitialReqError(false)
                }}
              />
            </div>
          
            
            </form>

            <div className="button_container">
            <button
              className="cmn_btn"
              style={{ backgroundColor: "#65CC5C" }}
              onClick={postApi}
            >
              <svg
                width="12"
                height="10"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.72621L3.18182 6.90803L9 1.08984"
                  stroke="white"
                  stroke-width="1.45455"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Create Initial Requirement Lists
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateInitialReqList;
