import React, { useState, useEffect } from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Breadcrumb from "../../components/Breadcrumb";
import SidebarMenu from "../../components/SidebarMenu";
import "../../css/viewuser.css";
import { fetchDataV2 } from "../apiUtils";
import { issueTracking } from "../subroutes";
import create from "../../images/createbutton.svg"
import openarrow from "../../images/openarrow.svg"
import pie_chart from "../../images/pie_chart.png"
import "./issuetracking.css"
import RenderIcons from "../RenderIcons";
import NavRow from "../NavRow";
import Piechart from "../../components/issueTracking/Charts/PieChart"
import AllIssuePieChart from "./Charts/AllIssuePieChart"
import PriorityBarChart from "./Charts/PriorityBarChart";

const Issuetracking = ({ tableSize }) => {
    const [errors, setErrors] = useState("")
    const [APIData, setAPIData] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const successMessageColor = location.state?.successMessageColor;
    const successMessage = location.state?.successMessage;
    const [showMessage, setShowMessage] = useState(!!successMessage);
    const originalUrl = location.state?.from;
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            navigate(".", { replace: true });
        }, 5000);

        return () => clearTimeout(timeoutId);
    }, []);

    useEffect(() => {
        const parameter={
            url:`/api/issues/count/`,
            setterFunction: setAPIData,
            setErrors:setErrors,
          }
        fetchDataV2(parameter);

    }, []);

    // const Viewuser = (data) => {



    //     const { name, SelectedKeysOther, description, BusinessUnit } = data;

    //     const localStorageData = {
    //         name,
    //         SelectedKeysOther,
    //         description,
    //         BusinessUnit,
    //     };
    //     localStorage.setItem(
    //         "key_stakeholder_name",
    //         data.key_stakeholder ? data.key_stakeholder.name.split(" ")[0] : 'No Units'
    //     );

    //     localStorage.setItem(
    //         "key_stakeholder_id",
    //         data.key_stakeholder ? data.key_stakeholder.id : ''
    //     );
    //     localStorage.setItem("ID", data.id);

    //     localStorage.setItem("buUnits", JSON.stringify(data.business_units));
    //     localStorage.setItem("otherUnits", JSON.stringify(data.other_stakeholders));



    //     Object.entries(localStorageData).forEach(([key, value]) => {
    //         localStorage.setItem(key, value);
    //     });

    //     navigate("/AuditUniverse/auditEntities/ViewAuditEntity");
    // };

    // const [filters, setFilters] = useState({
    //     name: "",
    //     key_stakeholder: "",
    // });

    // const handleFilterChange = (filterKey, e) => {
    //     setFilters({ ...filters, [filterKey]: e.target.value });
    // };




    // const filteredData = APIData.filter((data) => {

    //     const fullName = data.name.toLowerCase();
    //     const NameMatch = filters.name ? fullName.includes(filters.name.toLowerCase()) : true;
    //     const keyStakeholderName = "";
    //     const KeyStakeholder = filters.key_stakeholder ? keyStakeholderName.includes(filters.key_stakeholder.toLowerCase()) : true;

    //     const businessUnitMatch = filters.business_unit ? data.business_units.some(unit => {
    //         const unitNameLower = unit.name.toLowerCase();
    //         const filterUnitLower = filters.business_unit.toLowerCase();
    //         return unitNameLower.includes(filterUnitLower);
    //     }) : true;

    //     return NameMatch && KeyStakeholder && businessUnitMatch;
    // });

    const auditData = [
        {
            "auditName": "Human Resource & Admin",
            "auditYear": 2022,
            "total": 53,
            "open": 1,
            "closed": 45,
            "overdue": 8,
            "extended": 2,
            "closedUnderMonitoring": 1,
            "issues": [
                {
                    "name": "3.3(2)",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "This sub-observation highlights inconsistencies in HR documentation."
                        }
                    ],
                    "priority": "High",
                    "quarter": "Q1",
                    "status": {
                        "open": 1,
                        "closed": 0,
                        "overdue": 0,
                        "extended": 0,
                        "closedUnderMonitoring": 0
                    }
                },
                {
                    "name": "3.4",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "Sub-observation related to employee performance reviews, with gaps in reporting."
                        },
                        {
                            "name": "SubObs 2",
                            "description": "Evaluation of employee training sessions is found to be incomplete."
                        }
                    ],
                    "priority": "Medium",
                    "quarter": "Q1",
                    "status": {
                        "open": 0,
                        "closed": 1,
                        "overdue": 0,
                        "extended": 0,
                        "closedUnderMonitoring": 0
                    }
                },
                {
                    "name": "3.7",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "Sub-observation identifies missing files in HR records."
                        },
                        {
                            "name": "SubObs 2",
                            "description": "Additional steps are required to ensure all documents are filed correctly."
                        },
                        {
                            "name": "SubObs 3",
                            "description": "Some records are stored in unorganized manner, affecting accessibility."
                        }
                    ],
                    "priority": "Low",
                    "quarter": "Q1",
                    "status": {
                        "open": 0,
                        "closed": 0,
                        "overdue": 1,
                        "extended": 0,
                        "closedUnderMonitoring": 0
                    }
                },
                {
                    "name": "3.8",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "Sub-observation notes the need for better training programs for staff."
                        },
                        {
                            "name": "SubObs 2",
                            "description": "Current programs are deemed insufficient for meeting organizational goals."
                        }
                    ],
                    "priority": "High",
                    "quarter": "Q1",
                    "status": {
                        "open": 0,
                        "closed": 0,
                        "overdue": 0,
                        "extended": 1,
                        "closedUnderMonitoring": 0
                    }
                },
                {
                    "name": "3.9",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "Observation highlights communication failures within HR teams."
                        },
                        {
                            "name": "SubObs 2",
                            "description": "Further training on inter-team communication is needed."
                        },
                        {
                            "name": "SubObs 3",
                            "description": "Teams need to collaborate more effectively to meet organizational objectives."
                        },
                        {
                            "name": "SubObs 4",
                            "description": "Sub-observation suggests setting up clearer communication channels."
                        }
                    ],
                    "priority": "Medium",
                    "quarter": "Q1",
                    "status": {
                        "open": 0,
                        "closed": 1,
                        "overdue": 0,
                        "extended": 0,
                        "closedUnderMonitoring": 0
                    }
                },
                {
                    "name": "3.10",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "Sub-observation identifies issues with tracking HR policy changes."
                        },
                        {
                            "name": "SubObs 2",
                            "description": "Tracking system needs to be more accurate and updated regularly."
                        }
                    ],
                    "priority": "Low",
                    "quarter": "Q1",
                    "status": {
                        "open": 0,
                        "closed": 0,
                        "overdue": 0,
                        "extended": 0,
                        "closedUnderMonitoring": 1
                    }
                },
                {
                    "name": "3.12",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "This sub-observation identifies a lack of clear guidelines for employee exit processes."
                        }
                    ],
                    "priority": "High",
                    "quarter": "Q1",
                    "status": {
                        "open": 1,
                        "closed": 0,
                        "overdue": 0,
                        "extended": 0,
                        "closedUnderMonitoring": 0
                    }
                }
            ]
        },
        {
            "auditName": "Finance",
            "auditYear": 2022,
            "total": 21,
            "open": 1,
            "closed": 11,
            "overdue": 6,
            "extended": 2,
            "closedUnderMonitoring": 1,
            "issues": [
                {
                    "name": "3.22",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "Sub-observation related to financial audit trails, showing inconsistencies."
                        }
                    ],
                    "priority": "High",
                    "quarter": "Q1",
                    "status": {
                        "open": 1,
                        "closed": 0,
                        "overdue": 0,
                        "extended": 0,
                        "closedUnderMonitoring": 0
                    }
                },
                {
                    "name": "3.23",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "Sub-observation regarding incorrect categorization of financial transactions."
                        },
                        {
                            "name": "SubObs 2",
                            "description": "The finance team needs to align categories with current standards."
                        },
                        {
                            "name": "SubObs 3",
                            "description": "Additional checks are needed to improve transaction classification."
                        },
                        {
                            "name": "SubObs 4",
                            "description": "Recommendations include conducting a quarterly review of categories."
                        },
                        {
                            "name": "SubObs 5",
                            "description": "Internal controls need to be strengthened to ensure accurate classification."
                        }
                    ],
                    "priority": "Medium",
                    "quarter": "Q1",
                    "status": {
                        "open": 0,
                        "closed": 1,
                        "overdue": 0,
                        "extended": 0,
                        "closedUnderMonitoring": 0
                    }
                },
                {
                    "name": "3.24",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "Sub-observation on finance reporting errors affecting monthly summaries."
                        },
                        {
                            "name": "SubObs 2",
                            "description": "Reports require further validation and reconciliation for accuracy."
                        },
                        {
                            "name": "SubObs 3",
                            "description": "Improper categorization of expenses needs to be corrected."
                        }
                    ],
                    "priority": "Low",
                    "quarter": "Q1",
                    "status": {
                        "open": 0,
                        "closed": 0,
                        "overdue": 1,
                        "extended": 0,
                        "closedUnderMonitoring": 0
                    }
                },
                {
                    "name": "3.25",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "Sub-observation on gaps in audit trail for financial transactions."
                        },
                        {
                            "name": "SubObs 2",
                            "description": "Reconciliations need to be performed more frequently for transparency."
                        },
                        {
                            "name": "SubObs 3",
                            "description": "Further investigation required into transactions flagged by previous audits."
                        },
                        {
                            "name": "SubObs 4",
                            "description": "Internal checks need to be more rigorous and proactive."
                        },
                        {
                            "name": "SubObs 5",
                            "description": "Recommendations for a dedicated audit review team to investigate anomalies."
                        }
                    ],
                    "priority": "High",
                    "quarter": "Q1",
                    "status": {
                        "open": 0,
                        "closed": 0,
                        "overdue": 0,
                        "extended": 1,
                        "closedUnderMonitoring": 0
                    }
                }
            ]
        },
        {
            "auditName": "IT Security",
            "auditYear": 2022,
            "total": 15,
            "open": 5,
            "closed": 8,
            "overdue": 2,
            "extended": 0,
            "closedUnderMonitoring": 0,
            "issues": [
                {
                    "name": "3.1",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "Security patches are outdated and need to be applied."
                        }
                    ],
                    "priority": "High",
                    "quarter": "Q1",
                    "status": {
                        "open": 1,
                        "closed": 0,
                        "overdue": 0,
                        "extended": 0,
                        "closedUnderMonitoring": 0
                    }
                },
                {
                    "name": "3.2",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "Weak password policies in place, which can lead to unauthorized access."
                        },
                        {
                            "name": "SubObs 2",
                            "description": "Recommendation to strengthen password complexity and expiration policies."
                        }
                    ],
                    "priority": "Medium",
                    "quarter": "Q1",
                    "status": {
                        "open": 0,
                        "closed": 1,
                        "overdue": 0,
                        "extended": 0,
                        "closedUnderMonitoring": 0
                    }
                }
            ]
        },
        {
            "auditName": "Operations",
            "auditYear": 2023,
            "total": 32,
            "open": 3,
            "closed": 25,
            "overdue": 4,
            "extended": 0,
            "closedUnderMonitoring": 0,
            "issues": [
                {
                    "name": "3.8",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "Delay in shipment of raw materials for manufacturing."
                        }
                    ],
                    "priority": "High",
                    "quarter": "Q1",
                    "status": {
                        "open": 1,
                        "closed": 0,
                        "overdue": 0,
                        "extended": 0,
                        "closedUnderMonitoring": 0
                    }
                }
            ]
        },
        {
            "auditName": "Sales & Marketing",
            "auditYear": 2023,
            "total": 28,
            "open": 4,
            "closed": 18,
            "overdue": 4,
            "extended": 0,
            "closedUnderMonitoring": 2,
            "issues": [
                {
                    "name": "2.1",
                    "subObs": [
                        {
                            "name": "SubObs 1",
                            "description": "Inaccurate tracking of sales leads."
                        }
                    ],
                    "priority": "Medium",
                    "quarter": "Q1",
                    "status": {
                        "open": 1,
                        "closed": 0,
                        "overdue": 0,
                        "extended": 0,
                        "closedUnderMonitoring": 0
                    }
                }
            ]
        }
    ]

    // function calculateAuditStatus(data) {

    //     let statusCounts = {
    //         open: 0,
    //         closed: 0,
    //         overdue: 0,
    //         extended: 0,
    //         closedUnderMonitoring: 0
    //     };

    //     data.forEach(audit => {
    //         audit.issues.forEach(issue => {
    //             statusCounts.open += issue.status.open || 0;
    //             statusCounts.closed += issue.status.closed || 0;
    //             statusCounts.overdue += issue.status.overdue || 0;
    //             statusCounts.extended += issue.status.extended || 0;
    //             statusCounts.closedUnderMonitoring += issue.status.closedUnderMonitoring || 0;
    //         });
    //     });

    //     return statusCounts;
    // }

    // const result = calculateAuditStatus(auditData);

    // const getStatus = (status) => {
    //     if (status.open > 0) return "Open";
    //     if (status.closed > 0) return "Closed";
    //     if (status.overdue > 0) return "Overdue";
    //     if (status.extended > 0) return "Extended";
    //     return "No Status";
    // };


    return (
        <>
            {errors && <p className="errormessage">{errors}</p>}
            {successMessage && <p className="successmessage">{successMessage}</p>}

            <section className="navroutes">
                <RenderIcons homeRoutes={issueTracking} />
                <NavRow name={'Analytics'} Create={'Create'} idtrue={true} />

                <div className="analytics_container" style={{ width: tableSize ? "100%" : "100%" }} >

                    <div className="analytics_grid_container">

                        <div className="analytic_grid_item_span_2">

                            <h5> Issue's status of All Audits </h5>
                            <AllIssuePieChart
                                data={{
                                    // total: result.open + result.closed + result.overdue + result.extended + result.closedUnderMonitoring,
                                    open: APIData.status_open,
                                    closed: APIData.closed_under_monitoring,
                                    overdue: APIData.overdue,
                                    extended: APIData.extended_date,
                                    closedUnderMonitoring: APIData.closed_under_monitoring,
                                }}
                            />

                        </div>


                        <div className="analytic_grid_table_span_2">
                            <table>
                                <thead>
                                    <tr className="tablehead">
                                        <th>
                                            <h4 className="thhead">Audit ID</h4>
                                            <input type="text" placeholder="--" className="thfilter" disabled />
                                        </th>
                                        <th>
                                            <h4 className="thhead">Audit Name</h4>
                                            <input type="text" placeholder="--" className="thfilter" disabled />
                                        </th>
                                        <th>
                                            <h4 className="thhead">Analytics</h4>

                                        </th>
                                        <th>
                                            <h4 className="thhead">Details</h4>

                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {auditData.map((audit, index) => (
                                        <tr key={index}>
                                            <td>
                                                <span>{`AUD-${index + 1}`}</span> {/* Hardcoded Audit ID */}
                                            </td>
                                            <td>
                                                <span>{audit.auditName}</span> {/* Audit Name */}
                                            </td>
                                            <td>
                                                {/* Action Buttons */}
                                                <button
                                                    onClick={() => navigate("/issuetracking/Analytics/auditanalytics")}
                                                    className="cmn_btn"
                                                    style={{ backgroundColor: "#5771DF" }}
                                                >
                                                    View Analytics
                                                </button>
                                            </td>
                                            <td>
                                                <button
                                                    onClick={() => navigate("/issuetracking/issues/1")}
                                                    className="cmn_btn"
                                                    style={{ backgroundColor: "#5771DF" }}
                                                >
                                                    View Details
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>



                        </div>

                    </div>

                </div>

                {/* <div className="cardscontainer">
                    {filteredData.map((data) => (
                        <div className="aecard" onClick={() => Viewuser(data)}>
                            <h1>{data.name} </h1>

                            <img src={openarrow} />
                        </div>
                    ))}
                </div> */}

            </section >
        </>
    );
};

export default Issuetracking;
