import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
  LabelList,
} from "recharts";

// Priority Mapping and Colors
const priorityMapping = {
  High: { value: 3, color: "#FE2768" }, // Red for High
  Medium: { value: 2, color: "#D7AE27" }, // Yellow for Medium
  Low: { value: 1, color: "#65CC5C" }, // Green for Low
};

// Function to group, sort, and include colors, sub-observation count
const groupAndSortByPriority = (auditData) => {
  return auditData
    .flatMap((audit) =>
      audit.issues.map((issue) => ({
        name: issue.name,
        priority: priorityMapping[issue.priority].value, // Map priority to numeric value
        color: priorityMapping[issue.priority].color, // Map color to priority
        subObsCount: issue.subObs.length, // Count of sub-observations
        priorityLabel: issue.priority, // Store the priority label for the tooltip
      }))
    )
    .sort((a, b) => b.priority - a.priority); // Sort by descending priority
};

const PriorityBarChart = ({ data }) => {
  // Prepare and sort data
  const chartData = groupAndSortByPriority(data);

  // Find the maximum number of sub-observations for scaling the bar height
  const maxSubObsCount = Math.max(...chartData.map((item) => item.subObsCount));

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={chartData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 35,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis domain={[0, maxSubObsCount + 1]} /> {/* Adjust Y-axis based on the max subObsCount */}
        <Tooltip
          formatter={(value, name, props) => {
            // Access the priority label from the payload
            const priorityLabel = props.payload.priorityLabel;
            return [
              `${priorityLabel} Priority`, // Display the priority label
              `SubObs Count: ${props.payload.subObsCount}`, // Show number of subObs
            ];
          }}
        />
        <Legend />
        <Bar
          dataKey="subObsCount" // Now, we display subObsCount for the bars
          isAnimationActive={true}
          shape={(props) => {
            const { x, y, width, height, fill } = props;
            return (
              <rect
                x={x}
                y={y}
                width={width}
                height={height} // This height will be based on subObsCount
                fill={props.payload.color} // Use color from data payload
              />
            );
          }}
        >
          <LabelList
            dataKey="subObsCount"
            position="top"
            formatter={(value) => ` ${value}`} // Show subObs count above bars
          />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default PriorityBarChart;
