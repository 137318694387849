import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../css/viewuser.css";
import "../../../css/createentity.css";
import "../../../css/global.css";
import SingleOptionSelector from "../../SingleOptionSelector";
import { auditRoutes } from "../../subroutes";
import LongMultiOptionSelector from "../../LongMultiOptionSelector";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";
import { postDataV2 } from "../../apiUtils";
const CreateEntity = ({ tableSize }) => {
  const [errors, setErrors] = useState("");
  const [firstName, setFirstName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [avatarColor, setAvatarColor] = useState("");
  const [other_stakeholders_id, setOther_stakeholders_id] = useState([]);
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);
  const [selectedKeyError, setSelectedKeyError] = useState(false);
  const [key_stakeholder_id, setKey_stakeholder_id] = useState("");
  const [business_units_id, setBusinessUnit_id] = useState([]);
  const [editMode, setEditMode] = useState(true);
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const getRandomColor = (letter) => {
    const colorSeed = letter.charCodeAt(0) * 137;
    const color = `hsl(${colorSeed % 360}, 70%, 50%)`;
    return color;
  };
  const handleFirstNameChange = (event) => {
    const newName = event.target.value;
    setFirstName(newName);
    setFirstNameError(false);
    setName(newName);
    if (newName.length > 0) {
      const initialLetter = newName[0].toUpperCase();
      setAvatarColor(getRandomColor(initialLetter));
    }
  };
  const postApi = (e) => {
    e.preventDefault();
    if (!firstName || !description || !key_stakeholder_id) {
      setFirstNameError(!firstName ? true : false);
      setDescriptionError(!description ? true : false);
      setSelectedKeyError(!key_stakeholder_id ? true : false);
      setErrors("Please fill in all required fields.");
      setTimeout(() => {
        setErrors(null);
      }, 5000);
      return;
    }

    const parameter = {
      url: `/api/audit_universe/audit-entities/`,
      payload: {
        name,
        description,
        business_units_id,
        key_stakeholder_id,
        other_stakeholders_id,
      },
      navigate: navigate,
      setErrors: setErrors,
      navUrl: `/AuditUniverse/auditEntities`,
    };

    postDataV2(parameter);
  };
  const handleChange = (event) => {
    setDescription(event.target.value);
    setDescriptionError(false);
  };

  return (
    <>
      {errors && <p className="errormessage">{errors}</p>}

      <section className="navroutes">
        <RenderIcons homeRoutes={auditRoutes} />

        <NavRow name={"Create Audit Entity"} idtrue={true} />

        <div
          className="auditContainer"
          style={{
            width: tableSize ? "83.5vw" : "90.5vw",
            overflowY: "scroll",
          }}
        >
          <form>
            <div className="lbl_inpt">
              <label>Name</label>
              <input
                style={{
                  border: firstNameError ? "1px solid #FF4646" : "",
                }}
                type="text"
                placeholder="John"
                value={name}
                id="firstName"
                onChange={handleFirstNameChange}
              />
            </div>

            <SingleOptionSelector
              apiUrl="/api/audit_universe/admin-business-stakeholders/"
              setSelectorId={setKey_stakeholder_id}
              setSelectorError={setSelectedKeyError}
              selectorError={selectedKeyError}
              Name="name"
              title="Key Stakeholder"
              editMode={editMode}
            />

            <div className="lbl_txa">
              <label>Description</label>

              <textarea
                style={{
                  border: descriptionError ? "1px solid #FF4646" : "",
                }}
                placeholder="Description"
                value={description}
                onChange={handleChange}
              />
            </div>

            <LongMultiOptionSelector
              apiUrl="/api/system/business-units/"
              setSelectorId={setBusinessUnit_id}
              Name="name"
              title="Business Unit "
              editMode={editMode}
            />

            <LongMultiOptionSelector
              apiUrl="/api/audit_universe/business-stakeholders/"
              setSelectorId={setOther_stakeholders_id}
              Name="name"
              title="Other Key Stakeholders"
              editMode={editMode}
            />
          </form>
          <div className="button_container">
            <button
              className="cmn_btn"
              style={{ backgroundColor: "#65CC5C" }}
              onClick={postApi}
            >
              <svg
                width="12"
                height="10"
                viewBox="0 0 10 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 4.72621L3.18182 6.90803L9 1.08984"
                  stroke="white"
                  stroke-width="1.45455"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Create Audit Entity
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreateEntity;
