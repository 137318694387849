import React, { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import SidebarMenu from "../../SidebarMenu";
import "../../../css/viewuser.css";
import "../../../css/global.css";
import "../riskAssesment/riskassessment.css";
import DeleteIcon from "../../../images/deleteBtn.svg";
import {
  deleteDataV2,
  fetchDataV2,
  postDataV2,
  fetchDataObjectV2,
} from "../../apiUtils";
import { auditRoutes,auditRoutesBu } from "../../subroutes";
import BounceLoader from "react-spinners/BounceLoader";
import RenderIcons from "../../RenderIcons";
import NavRow from "../../NavRow";

const Riskassessment = ({ tableSize }) => {
  const [APIData, setAPIData] = useState({});
  const [APIAllData, setAPIAllData] = useState([]);
  const [APIAllDataPlan, setAPIAllDataPlan] = useState([]);
  const [removeId, setRemoveId] = useState(false);
  const [isAnyOpen, setIsAnyOpen] = useState(false);
  const [isAnyOpenPlan, setIsAnyOpenPlan] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [expandSubTable, setSubExpandTable] = useState({});
  const [toggleAll, setToggleAll] = useState(false);
  const [Errors, setErrors] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  const BusinessRole = Object.keys(userRoles).some(key => key.includes("Business"));
  const AuditTeamRole = Object.keys(userRoles).some(key => key.includes("Audit Team"));

  const is_Admin = localStorage.getItem("is_Admin");
  const { raid } = useParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [activeView, setActiveView] = useState("List View");
  const location = useLocation();
  const successMessage = location.state?.successMessage;
  


  useEffect(() => {
    const parameter = {
      url: `/api/audit_universe/audit-plan/`,
      setterFunction: (data) => {
        setAPIAllDataPlan(data);
        setLoading(false);
      },
      setErrors: setErrors,
    };
    fetchDataV2(parameter);
  }, []);

  useEffect(() => {
    setLoading(true);

    const parameter = {
      url: `/api/audit_universe/risk-assessments/${raid}/`,
      setterFunction: (data) => {
        setAPIData(data);
        setLoading(false);
      },
      setErrors: setErrors,
      setLoading: setLoading,
    };
    fetchDataObjectV2(parameter);

    const anyOpenPlan = APIAllDataPlan.some((item) => item.status === "Open");
    setIsAnyOpenPlan(anyOpenPlan);
    const anyOpen = APIData.status === "Open";

    setIsAnyOpen(anyOpen);
  }, [raid]);

  const toggleAllTables = () => {
    setToggleAll(!toggleAll);
    const expandedAll = {};
    if (APIData.risk_analysis) {
      APIData.risk_analysis.forEach(
        (analysis) => (expandedAll[analysis.audit_entity.name] = !toggleAll)
      );
    }
    setSubExpandTable(expandedAll);
  };

  const navigateGrapp = () => {
    navigate(`/AuditUniverse/riskAssesment/${raid}/graphview`);
  };

  const goToAnswer = (analysis) => {
    if (!analysis || !analysis.questionnaire) {
      return;
    }
    return function () {
      navigate(`/AuditUniverse/riskAssesment/${raid}/answerQuestionnaire`, {
        state: { questionnaire_id: analysis.questionnaire.id },
      });
    };
  };

  const createRisk = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    const parameter = {
      url: `/api/audit_universe/risk-assessments/`,
      payload: {
        title: `Risk Assessment For The Year Of ${year}`,
        assessment_date: `${year}-${month}-${day}`,
        details: "start risk assessment now",
      },
      setErrors: setErrors,
    };

    postDataV2(parameter);
  };

  const filteredRiskAnalyses = APIData.risk_analysis
    ? APIData.risk_analysis.filter((analysis) =>
      analysis.audit_entity.name
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
    )
    : [];

  const renderStatus = (status) => {
    switch (status) {
      case "Open":
        return <span className="status_todo">Planned</span>;
      case "Closed":
        return <span className="status_complete">Done</span>;

      default:
        return <span className="status_inprogress">In Progress</span>;
    }
  };

  const createPlan = () => {
    const papameter = {
      url: `/api/audit_universe/audit-plan/`,
      payload: {
        risk_assessment_id: APIData.id,
        title: APIData.title,
      },
      navigate: navigate,
      setErrors: setErrors,
      navUrl: `/AuditUniverse/auditPlanning`,
      state: {
        successMessage: "Audit Plan Created Successfully",
      }
    };

    postDataV2(papameter);
  };

  const handleSendClick = () => {
    setShowPopup(true);
    setTimeout(() => setShowPopup(false), 2000);
  };

  const toggleListView = () => {
    setActiveView("List View");
  };

  const toggleGraphView = () => {
    setActiveView("Graph View");
    navigateGrapp();
  };

  const deleterisksassessmentdata = () => {
    const parameter = {
      url: `/api/audit_universe/risk-assessments/${raid}`,
      setErrors: setErrors,
      navigate: navigate,
      navUrl: `/AuditUniverse/riskAssesment`,
    };

    deleteDataV2(parameter);
  };
  const handlePopupClose = () => {
    setShowDeletePopup(true);
  };

  const handlePopupCloseNo = () => {
    setShowDeletePopup(false);
  };

  const trueRisk = {
    toggleListView: toggleListView,
    activeView: activeView,
    toggleGraphView: toggleGraphView,
    activeView: activeView,
    deleterisksassessmentdata: deleterisksassessmentdata,
    DeleteIcon: DeleteIcon,
    APIDataStatus: APIData.status,
    isAnyOpenPlan: isAnyOpenPlan,
    createRisk: createRisk,
    createPlan: createPlan,
    showDeletePopup: showDeletePopup,
    handlePopupClose: handlePopupClose,
    handlePopupCloseNo: handlePopupCloseNo,
    APIData: APIData,
    setAPIData: setAPIData,
  };

  return (
    <>
      <section className="navroutes">
        <RenderIcons homeRoutes={BusinessRole && !is_Admin ?auditRoutesBu:auditRoutes} />
        <NavRow name={"Risk Assessment"} trueRisk={trueRisk} />

        <div className="aetablecontainer"
          style={{ width: tableSize ? "80vw" : "90.5vw" }}
        >

          <div className="riskassesstable1">

            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th style={{ textAlign: "left" }} >Audit Entity</th>
                  <th>Status</th>
                  <th>Action</th>
                  <th>Reminder</th>
                </tr>
              </thead>

              <tbody>
              {filteredRiskAnalyses.every(
    (analysis) => analysis.questionnaire.status === "Closed"
  ) ? (
    // If all questionnaires are closed, show the message
    <tr>
      <td colSpan={5}>
        <h2 style={{ textAlign: "center" }}>All questionnaire responses have been submitted and approved</h2>
      </td>
    </tr>
  ) : (
    // Otherwise, render the analyses
    filteredRiskAnalyses
                  .filter(
                    (analysis) => analysis.questionnaire.status === "Open" || analysis.questionnaire.status === "Submitted"
                  ) // Filter analyses where status is "open"
                  .sort((a, b) =>
                    a.audit_entity.name.localeCompare(b.audit_entity.name)
                  ) // Sort the filtered analyses
                  .map((analysis, index) => (
                    <React.Fragment key={index}>

                      <tr className="riskBU" style={{ height: "70px" }} >
                        <td onClick={goToAnswer(analysis)} >
                          <h6 className="h6_intersemibold">
                            {analysis.audit_entity.name}
                          </h6>
                        </td>

                        <td>
                          {renderStatus(analysis.questionnaire.status)}
                        </td>

                        <td className="score_tag">
                          <button
                            className="cmn_btn"
                            style={{
                              backgroundColor: "#5771DF",
                              display: "inline-flex",
                            }}
                            onClick={goToAnswer(analysis)}
                          >

                            <svg width="16" height="16" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8 5H12C14 5 14 4 14 3C14 1 13 1 12 1H8C7 1 6 1 6 3C6 5 7 5 8 5Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M12 20.9995H7C2 20.9995 1 18.9995 1 14.9995V8.99953C1 4.43953 2.67 3.19953 6 3.01953" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M14 3.01953C17.33 3.19953 19 4.42953 19 8.99953V13.9995" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M13 18V15H16" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M18.9991 20.9991L13.0391 15.0391" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>


                            Fill Questionaire

                          </button>
                        </td>

                        <td>
                          <button
                            className="cmn_btn"
                            style={{
                              backgroundColor: "#D7AE27",
                              display: "inline-flex",
                            }}
                            onClick={handleSendClick}
                          >

                            {/* <svg width="20" height="20" viewBox="0 0 41 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M34.9037 16.3571C36.1864 16.3729 37.4216 15.8821 38.3718 15.0269C41.5074 12.2872 39.8288 6.79199 35.7113 6.2694C34.2385 -2.66227 21.332 0.726689 24.3884 9.23077" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M5.07614 11.7926C-3.13493 15.6033 3.58646 27.131 10.9615 21.9089" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M24.0541 5.04856C21.2308 -1.53312 11.9588 3.51507 15.9176 9.46831" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M23.1407 18.3431C24.501 25.4592 38.6008 23.2524 35.5101 16.4163" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M9.94478 18.2803C9.89901 25.5252 24.1591 26.095 22.453 18.7893" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M5.62783 11.4546C3.28282 6.40317 10.0672 3.5982 14.2166 5.52752" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            </svg> */}

                            Remind
                          </button>
                        </td>

                      </tr>

                    </React.Fragment>
                  )))}
              </tbody>
            </table>

          </div>



          <div className="riskassesstable2" style={{ width: "100%" }}>

          {(!BusinessRole || AuditTeamRole) &&(
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>
                    <span onClick={toggleAllTables}>
                      <div className="tableToggle">
                        {toggleAll ? (
                          <span className="show_details">Hide Details</span>
                        ) : (
                          <span className="show_details">Show Details</span>
                        )}{" "}
                      </div>
                    </span>
                  </th>
                  <th>Impact</th>
                  <th>Likelihood</th>
                  <th>Risk Score</th>
                  <th>Status</th>
                </tr>
              </thead>

              <tbody>
              {filteredRiskAnalyses.every(
    (analysis) => analysis.questionnaire.status === "Open"
  ) ? (
    // If all questionnaires are closed, show the message
    <tr>
      <td colSpan={5}>
        <h2 style={{ textAlign: "center" }}>No Questionnaire is filled</h2>
      </td>
    </tr>
              ):(
                filteredRiskAnalyses
                  .filter(
                    (analysis) => analysis.questionnaire.status === "Closed"
                  ) // Filter analyses where status is "open"
                  .sort((a, b) =>
                    a.audit_entity.name.localeCompare(b.audit_entity.name)
                  ) // Sort the filtered analyses
                  .map((analysis, index) => (
                    <React.Fragment key={index}>

                      <tr className="riskBU" style={{ height: "70px" }} onClick={goToAnswer(analysis)}>
                        <td>
                          <div className="vsfvf">
                            <div className="toggle_btn_heading">
                              <h6 className="h6_intersemibold">
                                {analysis.audit_entity.name}
                              </h6>
                            </div>
                          </div>
                        </td>

                        <td className="score_tag">
                          <span className="high">
                            {analysis["impact"] ?? "--"}
                          </span>
                        </td>

                        <td className="score_tag">
                          <span className="moderate">
                            {analysis["likelihood"] ?? "--"}
                          </span>
                        </td>

                        <td className="score_tag">
                          <span className="low">
                            {analysis["total score"] ?? "--"}
                          </span>
                        </td>

                        <td className="score_tag">
                          {renderStatus(analysis.questionnaire.status)}
                        </td>
                      </tr>

                      <tr>
                        <td colSpan={7} style={{ padding: "0px", height: "0px" }}>
                          <table
                            className="innertable"
                            style={{
                              display: toggleAll
                                ? ""
                                : expandSubTable[analysis.audit_entity.name]
                                  ? ""
                                  : "none",
                              width: "100%",
                            }}
                          >
                            <thead>
                              <tr>
                                <th></th>
                                <th>
                                  Financial <br /> Statement <br /> / Materiality
                                </th>
                                <th>
                                  Legal/ <br />
                                  Compliance
                                </th>
                                <th>Operational</th>
                                <th>IT</th>
                                <th>Total Impact</th>
                                <th>
                                  Complexity <br /> of <br /> Process
                                </th>
                                <th>Volume</th>
                                <th>
                                  Known <br /> Issues
                                </th>
                                <th>
                                  Changes in <br /> Personnel or <br /> Processes
                                </th>
                                <th>Monitoring</th>
                                <th>Total Likelihood</th>
                                <th>
                                  Fraud Risk <br /> (F)
                                </th>
                                <th>
                                  Management <br /> Initiative <br /> (A)
                                </th>
                                <th>Total Score</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr>
                                <td>{analysis.audit_entity.name}</td>
                                <td>
                                  {analysis["financial and materiality"] ?? "--"}
                                </td>
                                <td>{analysis["legal & compliance"] ?? "--"}</td>
                                <td>{analysis["operational"] ?? "--"}</td>
                                <td>{analysis["it"] ?? "--"}</td>
                                <td>{analysis["impact"] ?? "--"}</td>
                                <td>
                                  {analysis["complexity of process"] ?? "--"}
                                </td>
                                <td>{analysis["volume"] ?? "--"}</td>
                                <td>{analysis["known issues"] ?? "--"}</td>
                                <td>
                                  {analysis[
                                    "changes in personnel or processes"
                                  ] ?? "--"}
                                </td>
                                <td>{analysis["monitoring"] ?? "--"}</td>
                                <td>{analysis["likelihood"] ?? "--"}</td>
                                <td>{analysis["fraud risk"] ?? "--"}</td>
                                <td>
                                  {analysis["management initiatives"] ?? "--"}
                                </td>
                                <td>{analysis["total score"] ?? "--"}</td>
                              </tr>
                              {analysis.business_units.map((BU, bIndex) => (
                                <React.Fragment key={bIndex}>
                                  <tr>
                                    <td>{BU.audit_unit.name}</td>
                                    <td>
                                      {BU["financial and materiality"] ?? "--"}
                                    </td>
                                    <td>{BU["legal & compliance"] ?? "--"}</td>
                                    <td>{BU["operational"] ?? "--"}</td>
                                    <td>{BU["it"] ?? "--"}</td>
                                    <td>{BU["impact"] ?? "--"}</td>
                                    <td>{BU["complexity of process"] ?? "--"}</td>
                                    <td>{BU["volume"] ?? "--"}</td>
                                    <td>{BU["known issues"] ?? "--"}</td>
                                    <td>
                                      {BU["changes in personnel or processes"] ??
                                        "--"}
                                    </td>
                                    <td>{BU["monitoring"] ?? "--"}</td>
                                    <td>{BU["likelihood"] ?? "--"}</td>
                                    <td>{BU["fraud risk"] ?? "--"}</td>
                                    <td>
                                      {BU["management initiatives"] ?? "--"}
                                    </td>
                                    <td>{BU["total score"] ?? "--"}</td>
                                  </tr>
                                </React.Fragment>
                              ))}
                            </tbody>
                          </table>
                        </td>
                      </tr>

                    </React.Fragment>
                  )))}
              </tbody>
            </table>)}

          </div>

        </div>

        

        {showPopup && (
          <div className="popup">
            <p>Notification Sent</p>
          </div>
        )}

      </section>
    </>
  );
};

export default Riskassessment;
