import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { justUpdateDataV2, fetchDataV2 } from '../../../apiUtils';

const UserDropdown = ({ otherKeyData, task, title, assigned_toName, taskId }) => {
  const [errors, setErrors] = useState("");
  const [APIData, setAPIData] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const dropdownRef = useRef(null);
  const taskID = localStorage.getItem("user_id");
  const taskAssinged = localStorage.getItem("taskAssinged");
    const userRoles = JSON.parse(localStorage.getItem("userRoles"));
  const is_Admin = localStorage.getItem("is_Admin");
  const isAdminRole = is_Admin || Object.keys(userRoles).some(key => key.includes("Audit Team"));

  const navigate = useNavigate();

  useEffect(() => {

    if (taskId) {
      const parameter={
        url: `/api/system/task/${taskId}/`,
        setterFunction: setAPIData,
        setErrors:setErrors,
      }
      fetchDataV2(parameter);
    }
  }, [taskId]);

  useEffect(() => {
    const user = otherKeyData.find(u => u.id === parseInt(taskAssinged));
    if (user) {
      setSelectedUser(user);
    }
  }, [otherKeyData, taskAssinged]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
        setSearchTerm('');
        // setSelectedUser(null);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleToggleDropdown = () => {
    if (isAdminRole) {
      setIsOpen(!isOpen);
      if (!isOpen) {
        setSearchTerm('');
        setSelectedUser(null);
      }
    }
  };

  const handleSearchChange = event => {
    setSearchTerm(event.target.value);
  };

  const handleSelectChange = user => {
    if (title) {
      const parameter={
        url:`/api/system/task/${taskId}/`,
        payload:{
          title: title,
          assigned_to_id: user.user_id,
        },
        setErrors:setErrors,
      }
      justUpdateDataV2(parameter);
    }
    setSelectedUser(user);
    setIsOpen(false);
  };

  const filteredUsers = otherKeyData.filter(user => user.name ? 
    user.name.toLowerCase().includes(searchTerm.toLowerCase())  : ''
  );

  return (
    <div ref={dropdownRef} className="ref_div" style={{backgroundColor:"#fff"}}>
      <div className="sub_ref_div"  onClick={handleToggleDropdown}>
       <label style={{fontSize:"12px"}}> {selectedUser ? selectedUser.name : assigned_toName || 'Other Key Stakeholders'}</label>
      </div>
      {isOpen && (
        <div className="opt_drp_dwn" style={{width:"82%",left:"18.5%"}}>
          <input
            type="text"
            placeholder="Search by first name..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <div style={{textAlign:"center"}}>
            {filteredUsers.map(user => (
              <div
                key={user.id}
                className="opt_drp_dwn_optns"
                onClick={() => handleSelectChange(user)}
              >
                <label>{user.name}</label>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDropdown;
